<template>
    <ui-modal name="settings-credibility-new-override" title="New Override" width="100%" height="auto" :max-width="480" @closed="cancel">
        <ui-form :store="settingsCredibilityNewOverrideEditStore" v-slot="{ inputs, submitting, errors }">
            <div>
                <label for="name" class="block text-sm text-gray-200 font-medium mb-1.5">
                    Source
                </label>

                <div class="flex items-center" v-if="inputs.source">
                    <div class="mr-4 shrink-0 relative">
                        <img class="rounded-full w-8 h-8 shadow" :src="inputs.source.avatarUrl" alt="">
                        <ui-icon :name="`badges.target-${inputs.source.type}`" v-tooltip="targetTypeToHuman(inputs.source.type)" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-gray-700 rounded-full"></ui-icon>
                    </div>
                    <div>
                        <div class="flex items-center">
                            <p class="font-medium">{{ inputs.source.title }}</p>
                            <div v-if="inputs.source.verified" class="h-4 w-4 rounded-full bg-blue-600 text-white flex items-center justify-center text-xs inline-flex ml-2 -mb-1">
                                <ui-icon name="check" class="text-2xs"></ui-icon>
                            </div>
                        </div>
                        <p class="text-gray-300">{{ targetTypeToHuman(inputs.source.type) }}</p>
                    </div>
                </div>
            </div>

            <div class="mt-6">
                <label for="name" class="block text-sm text-gray-200 font-medium mb-1.5">
                    Credibility
                </label>

                <div>
                    <ui-input type="number" name="credibility" id="credibility" placeholder="50" v-model="inputs.credibility" :errors="errors.credibility"></ui-input>
                </div>
                
                <div class="mt-2 text-sm text-gray-300">
                    Credibility value specified as a number from 0 to 100. Values below 20 are considered "low credibility" and values over 80 "high credibility".
                </div>
            </div>
            
            <div class="flex justify-end space-x-4 mt-8">
                <ui-button type="submit" color="green" :disabled="submitting">
                    Save
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useNewOverrideModalStore from '@/stores/settings/credibility/modals/new-override-modal'
import useSourcesSourceTypesStore from '@/stores/sources/source-types'

import { mapActions, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useNewOverrideModalStore)
    },

    methods: {
        ...mapActions(useNewOverrideModalStore, [ 'cancel', 'submit' ]),
        ...mapActions(useSourcesSourceTypesStore, { 'targetTypeToHuman': 'typeToHuman' })
    }
}
</script>
