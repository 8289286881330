<template>
    <ui-modal name="settings-new-user" title="New User" width="100%" height="auto" :max-width="480" @closed="cancel">
        <ui-form :store="settingsUsersModalsNewUserStore" v-slot="{ inputs, submitting, errors }" v-if="! isInvited">
            <div>
                <label for="email" class="block text-sm text-gray-200 font-medium mb-1.5">
                    Email
                </label>

                <div>
                    <ui-input type="email" name="email" id="email" v-model="inputs.email" :errors="errors.email"></ui-input>
                </div>

                <div class="mt-2 text-sm text-gray-300">
                    The email address, that will receive the invitation email and be used to log into the application.
                </div>
            </div>
            
            <div class="mt-6">
                <label for="name" class="block text-sm text-gray-200 font-medium mb-1.5">
                    Name
                </label>

                <div>
                    <ui-input name="name" id="name" v-model="inputs.name" :errors="errors.name"></ui-input>
                </div>

                <div class="mt-2 text-sm text-gray-300">
                    The user's name, only for display purposes.
                </div>
            </div>

            <div class="text-center mt-6">
                <ui-button size="lg" color="green" type="submit" :disabled="submitting">Invite</ui-button>
            </div>
        </ui-form>
        
        <div class="flex flex-col items-center text-center py-6" v-else>
            <ui-icon name="check-circle" class="text-4xl text-green-400"></ui-icon>

            <h1 class="font-semibold text-lg mt-4">
                An invitation has been sent to the user.
            </h1>

            <div class="mt-4">
                The user will receive an invitation email, which contains a link to activate their account.
            </div>

            <div class="mt-8">
                <a href="#" class="text-gerulata-green-100 hover:text-gerulata-green-300" size="lg" @click.prevent="reset">
                    <ui-icon name="plus-circle"></ui-icon>
                    Add another user
                </a>
            </div>

            <div class="mt-4">
                <ui-button color="green" size="lg" :action="cancel">Done</ui-button>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import useNewUserModalStore from '@/stores/settings/users/modals/new-user'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useNewUserModalStore),
        ...mapState(useNewUserModalStore, [ 'isInvited' ])
    },

    methods: {
        ...mapActions(useNewUserModalStore, [ 'cancel', 'reset', 'submit' ])
    }
}
</script>
