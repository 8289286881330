import useMyStore from '@/stores/me/my'

import { format as formatDate, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import {formatRelative, formatDistance, parseISO} from 'date-fns'
import { enUS } from 'date-fns/locale'

export function UTCToZoned(value) {
    return utcToZonedTime(value instanceof Date ? value : new Date(value), useMyStore().timezone)
}

export function zonedToUTC(value) {
    return zonedTimeToUtc(value instanceof Date ? value : new Date(value), useMyStore().timezone)
}

export function date(value, format = 'yyyy-MM-dd') {
    return formatDate(UTCToZoned(value), format, { timeZone: useMyStore().timezone })
}

export function dateTime(value, format = 'd MMM yyyy H:mm') {
    return date(value, format)
}

export function relativeTime(date, baseDate, format = null) {
    if (! (date instanceof Date)) date = parseISO(date)
    if (! baseDate) baseDate = new Date

    const formatRelativeLocale = format || {
        lastWeek: "'last' eeee 'at' k:mm",
        yesterday: "'yesterday at' k:mm",
        today: "'today at' k:mm",
        tomorrow: "'tomorrow at' k:mm",
        nextWeek: "eeee 'at' k:mm",
        other: 'P'
    };

    const locale = {
        ...enUS,
        formatRelative: token => formatRelativeLocale[token],
    };

    return formatRelative(date, baseDate, { locale })
}

export function distanceTime(date, baseDate, format) {
    if (! (date instanceof Date)) date = parseISO(date)
    if (! baseDate) baseDate = new Date

    const formatDistanceLocale = format || {
        lessThanXSeconds: '{{count}} s',
        xSeconds: '{{count}} s',
        halfAMinute: '30 s',
        lessThanXMinutes: '{{count}} m',
        xMinutes: '{{count}} m',
        aboutXHours: '{{count}} h',
        xHours: '{{count}} h',
        xDays: '{{count}} d',
        aboutXWeeks: '{{count}} w',
        xWeeks: '{{count}} w',
        aboutXMonths: '{{count}} mo',
        xMonths: '{{count}} mo',
        aboutXYears: '{{count}} y',
        xYears: '{{count}} y',
        overXYears: '{{count}} y',
        almostXYears: '{{count}} y'
    }

    const locale = {
        ...enUS,
        formatDistance: (token, count, options) => {
            const result = formatDistanceLocale[token].replace('{{count}}', count)
            return options.comparison > 0 ?  `in ${result}` : `${result} ago`
        }
    }

    return formatDistance(date, baseDate, { locale })
}

export function utcTimestamp(value) {
    let d = value instanceof Date ? value : new Date(value)
    return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds())
}
