import {useModal} from '@/helpers'

import {defineStore} from 'pinia'

export const useModeratedEventsModalsEventAlertEditFormStore = defineStore({
    id: 'moderatedEventsModalsEventAlertEditForm',

    moderatedEvent: null,

    actions: {
        open(moderatedEvent) {
            this.$reset()
            this.moderatedEvent = moderatedEvent

            useModal().show('moderated-events-event-alert-edit')
        }
    }
})

export default useModeratedEventsModalsEventAlertEditFormStore
