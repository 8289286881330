<template>
    <ui-sidebar title="Perspectives">
        <template v-slot:actions>
            <a href="#" @click.prevent="newPerspective" class="text-gerulata-green-100 hover:text-gerulata-green-300">
                <ui-icon name="plus"></ui-icon> New Perspective
            </a>
        </template>

        <div class="flex flex-col h-full">
            <div class="px-5 py-3">
                <div class="relative">
                    <div class="absolute top-0 left-3 h-9 flex items-center pointer-events-none z-10">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>
                    <ui-input placeholder="Search..." v-model="search" class="px-8"></ui-input>
                    <div class="absolute top-0 right-3 h-9 flex items-center z-10">
                        <ui-dropdown z-index="50">
                            <template v-slot:trigger>
                                <a href="#" class="flex items-center text-gray-200 hover:text-gray-300 text-sm">
                                    <ui-icon name="list" class="mr-1"></ui-icon>
                                </a>
                            </template>
                            <template v-slot:content>
                                <div class="text-gray-400 text-xs font-medium px-4 pt-2 pb-1">Sort By</div>
                                <ui-dropdown-link @click="sorting = {id: 'name', name: 'Name'}">Name</ui-dropdown-link>
                                <ui-dropdown-separator></ui-dropdown-separator>
                                <ui-dropdown-link @click="sorting = {id: 'latest', name: 'Latest'}">Latest</ui-dropdown-link>
                                <ui-dropdown-link @click="sorting = {id: 'oldest', name: 'Oldest'}">Oldest</ui-dropdown-link>
                            </template>
                        </ui-dropdown>
                    </div>
                </div>
            </div>

            <div class="divide-y divide-gray-600 px-2">
                <router-link :to="{ name: 'overview.perspective', params: { perspectiveId: perspective.id } }" v-for="perspective in perspectives" :key="perspective.id" class="flex items-center space-x-3 leading-tight hover:bg-gray-600 rounded cursor-pointer py-3 px-3">
                    <div>
                        <ui-avatar :item="perspective" class="w-8 h-8"></ui-avatar>
                    </div>
                    <div class="flex-1">
                        <div class="text-lg font-medium">
                            {{perspective.name}}
                        </div>
                    </div>
                    <div class="text-gray-300">
                        <ui-contextual-menu size="sm" plain @click.stop>
                            <ui-dropdown-link icon="bell" @click="openAlertsModal(perspective.subscriptions[0], perspective, true)">Alerts</ui-dropdown-link>
                            <ui-dropdown-link icon="globe" @click="openPublishModal(perspective)">Publish</ui-dropdown-link>
                            <ui-dropdown-separator></ui-dropdown-separator>
                            <ui-dropdown-link icon="settings" @click="openEditModal(perspective)">Settings</ui-dropdown-link>
                            <ui-dropdown-separator></ui-dropdown-separator>
                            <ui-dropdown-link icon="trash" @click="deletePerspective(perspective)">Delete</ui-dropdown-link>
                        </ui-contextual-menu>
                    </div>
                </router-link>
            </div>

            <div class="flex flex-col flex-1 items-center justify-center text-gray-300" v-if="! perspectives.length">
                <ui-icon name="hexagon" class="text-3xl"></ui-icon>
                <p class="mt-2">You will find your perspectives here.</p>
            </div>
        </div>
    </ui-sidebar>
</template>

<script>
import UiSidebar from '@/components/ui/sidebar'

import useManagementModalsAlertEditFormStore from '@/stores/management/modals/alert-edit'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useOverviewStore from '@/stores/overview/overview' 
import usePerspectivesStore from '@/stores/me/perspectives'
import usePerspectiveEditModalStore from '@/stores/overview/modals/perspective-edit'
import usePerspectivePublishModalStore from '@/stores/overview/modals/perspective-publish'

import { sortAlphabetically, sortByDate, textSearch } from '@/helpers'

import { mapActions, mapState } from 'pinia'

export default {
    components: { UiSidebar },
    
    data: () => ({
        search: '',
        sorting: { id: 'name', name: 'Name' }
    }),

    computed: {
        ...mapState(usePerspectivesStore, {
            perspectives(store) {
                let perspectives = this.search ? textSearch(this.search, store.items, p => p.name) : [ ...store.items ]

                if (this.sorting.id == 'name') {
                    return sortAlphabetically(perspectives)
                } else if (this.sorting.id == 'latest') {
                    return sortByDate(perspectives).reverse()
                } else if (this.sorting.id == 'oldest') {
                    return sortByDate(perspectives)
                } else {
                    return perspectives
                }
            }
        }),
        ...mapState(useOverviewStore, [ 'leftSidebar' ])
    },

    methods: {
        ...mapActions(useManagementModalsAlertEditFormStore, { openAlertsModal: 'open' }),
        ...mapActions(useMyPerspectivesStore, { deletePerspective: 'delete' }),
        ...mapActions(useOverviewStore, [ 'applyPerspective', 'newPerspective' ]),
        ...mapActions(usePerspectiveEditModalStore, { openEditModal: 'open' }),
        ...mapActions(usePerspectivePublishModalStore, { openPublishModal: 'open' })
    }
}
</script>
