export default [
    // Root redirect to default section (overview)
    { path: '', redirect: { name: 'overview' } },

    // Public routes
    {
        component: require('./Main.vue').default,
        meta: { guestOnly: true },
        children: [
            {
                path: '/login',
                name: 'login',
                meta: { page: require('@/pages/auth-login').default },
                component: require('./components/auth/login').default
            },
            {
                path: '/login/activate/:token',
                name: 'login.activate',
                meta: { page: require('@/pages/auth-activate').default },
                component: require('./components/auth/activate').default
            },
            {
                path: '/password-recovery/request',
                name: 'password-recovery.request',
                meta: { page: require('@/pages/auth-password-recovery-request').default },
                component: require('./components/auth/password-recovery-request').default
            },
            {
                path: '/password-recovery/reset/:token',
                name: 'password-recovery.reset',
                meta: { page: require('@/pages/auth-password-recovery-reset').default },
                component: require('./components/auth/password-recovery-reset').default
            }
        ]
    },

    // Application routes (requires authentication)
    {
        component: require('./App.vue').default,
        meta: { authenticatedOnly: true },
        children: [
            {
                path: '/overview',
                components: {
                    default: require('./components/overview/screen').default,
                    navigation: require('./components/overview/navigation').default
                },
                children: [
                    {
                        path: '',
                        name: 'overview',
                        component: require('./components/overview/index').default,
                        meta: { page: require('@/pages/overview').default }
                    },
                    {
                        path: 'perspective/:perspectiveId',
                        name: 'overview.perspective',
                        component: require('./components/overview/index').default,
                        meta: { page: require('@/pages/overview').default }
                    },
                    {
                        path: 'event/:eventId',
                        name: 'overview.event',
                        component: require('./components/overview/index').default,
                        meta: { page: require('@/pages/overview').default }
                    }
                ]
            },

            {
                path: '/settings',
                components: {
                    default: require('./components/settings/screen').default,
                    sidebar: require('./components/settings/sidebar.vue').default
                },
                children: [
                    {
                        path: 'account',
                        name: 'settings.account',
                        component: require('./components/settings/account').default,
                        meta: { page: require('@/pages/settings-account').default }
                    },
                    {
                        path: 'subscriptions',
                        name: 'settings.subscriptions',
                        component: require('./components/settings/subscriptions').default,
                        meta: { page: require('@/pages/settings-subscriptions').default }
                    },
                    {
                        path: 'management/overview',
                        name: 'management.overview',
                        component: require('./components/management/index').default,
                        meta: { page: require('@/pages/management-index').default }
                    },
                    {
                        path: 'settings/users',
                        name: 'settings.users',
                        component: require('./components/settings/users/users').default,
                        meta: { page: require('@/pages/settings-users').default }
                    },
                    {
                        path: 'management/billing',
                        name: 'management.billing',
                        component: require('./components/management/billing').default,
                        meta: { page: require('@/pages/management-index').default }
                    },
                    {
                        path: 'management/audit-log',
                        name: 'management.audit-log',
                        component: require('./components/settings/organization/audit-log').default,
                        meta: { page: require('@/pages/settings-audit-log').default }
                    },
                    {
                        path: 'management/gerulata-monitor',
                        name: 'management.gerulata-monitor-provider',
                        component: require('./components/settings/data-providers/gerulata-monitor').default,
                        meta: { page: require('@/pages/settings-gerulata-monitor-provider').default }
                    },
                    {
                        path: 'management/custom-data',
                        name: 'management.custom-data-provider',
                        component: require('./components/settings/data-providers/custom-data').default,
                        meta: { page: require('@/pages/settings-custom-data-provider').default }
                    },
                    {
                        path: 'management/api-tokens',
                        name: 'management.api-tokens',
                        component: require('./components/management/api-tokens').default,
                        meta: { page: require('@/pages/management-index').default }
                    },
                    {
                        path: 'management/source-credibility',
                        name: 'management.source-credibility',
                        component: require('./components/settings/credibility/source-credibility').default,
                        meta: { page: require('@/pages/settings-credibility').default }
                    },
                    {
                        path: 'management/manual-import',
                        name: 'management.manual-import',
                        component: require('./components/management/manual-import').default,
                        meta: { page: require('@/pages/management-index').default }
                    },
                    {
                        path: 'management/sharing',
                        name: 'management.sharing',
                        component: require('./components/management/sharing').default,
                        meta: { page: require('@/pages/management-sharing').default }
                    },
                    {
                        path: 'management/alerts',
                        name: 'management.alerts',
                        component: require('./components/management/alerts').default,
                        meta: { page: require('@/pages/management-alerts').default }
                    },
                    {
                        path: 'management/moderated-events',
                        name: 'management.moderated-events',
                        component: require('./components/management/moderated-events').default,
                        meta: { page: require('@/pages/management-moderated-events').default }
                    }
                ]
            },

            {
                path: '/help',
                component: require('./components/support/help/screen').default,
                children: [
                    {
                        path: ':page/:section?',
                        name: 'help.page',
                        component: require('./components/support/help/page').default,
                        meta: { page: require('./pages/support-help-page').default }
                    },
                ]
            },

            {
                path: '/release-notes',
                component: require('./components/support/release-notes/screen').default,
                children: [
                    {
                        path: ':page/:section?',
                        name: 'release-notes.page',
                        component: require('./components/support/release-notes/page').default,
                        meta: { page: require('./pages/support-release-notes').default }
                    }
                ]
            }
        ]
    },

    {
        component: require('./App.vue').default,
        children: [
            {
                path: '/overview/published/:token',
                components: {
                    default: require('./components/overview/screen').default,
                    navigation: require('./components/overview/navigation').default
                },
                children: [
                    {
                        path: '',
                        name: 'overview.published',
                        component: require('./components/overview/index').default,
                        meta: { page: require('@/pages/overview-published').default }
                    }
                ]
            }
        ]
    },

    // Catch-all route for "not found" pages
    { path: '/:pathMatch(.*)*', redirect: { name: 'overview' } }
]
