import useAppStore from '@/stores/app'
import useDetailsStore from '@/stores/overview/details'
import useManagementModeratedEventsStore from '@/stores/management/moderated-events/moderated-events'
import useManagementUsersStore from '@/stores/management/users'
import useManagementWorkspacesStore from '@/stores/management/workspaces'
import useOverviewStore from '@/stores/overview/overview'
import useStreamStore from '@/stores/overview/stream'

import { defineStore } from 'pinia'

export const useManagementModeratedEventsOverlayStore = defineStore({
    id: 'managementModeratedEventsOverlay',

    state: () => ({
        expandedModeratedEvent: null
    }),

    actions: {
        open() {
            useManagementWorkspacesStore().initialize()
            useManagementUsersStore().initialize()
            useManagementModeratedEventsStore().initialize()
        },

        close() {
            this.expandedModeratedEvent = null

            useStreamStore().toolbarIsShown = true
            useAppStore().setSidebarOptions({ compact: false, backdrop: true })
            useDetailsStore().setOptions({ compact: false })
        },

        showDetails(moderatedEvent) {
            this.expandedModeratedEvent = moderatedEvent

            useStreamStore().toolbarIsShown = false
            useAppStore().setSidebarOptions({ compact: true, backdrop: false })
            useDetailsStore().setOptions({ compact: true })

            useOverviewStore().inspectEvents({ event: moderatedEvent.data.event.id, center: [ 0, 0 ] })
        },

        showList() {
            this.expandedModeratedEvent = null
        }
    }
})

export default useManagementModeratedEventsOverlayStore
