import useEventCategoriesStore from '@/stores/me/event-categories'
import useMapStore from '@/stores/overview/map'
import useMyGeoFeaturesStore from '@/stores/me/geo-features'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMyStore from '@/stores/me/my'
import useOverviewStore from '@/stores/overview/overview'
import useTaggingStore from '@/stores/me/tagging'

import api from '@/api'

import { defineStore } from 'pinia'

export const useOverviewPublishedPage = defineStore({
    id: 'overviewPublishedPage',

    actions: {
        async afterEnter(to) {
            useMyStore().initializeAsGuest(to.params.token)

            if (to.query.presentation) useMapStore().togglePresentationMode()

            let res = await api.route('published perspective', [ to.params.token ]).get().json()

            useMyStore().currentWorkspace = res.workspace

            await Promise.all([
                useEventCategoriesStore().initialize(),
                useMyGeoFeaturesStore().initializeWith(res.geoLayers),
                useMyPerspectivesStore().initializeWith([ res.prespective ]),
                useTaggingStore().initializeWith(res.tags)
            ])

            useOverviewStore().initializeAsGuest(to.params.token, res.perspective)
        }
    }
})

export default useOverviewPublishedPage
