import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'

import useMyStore from '@/stores/me/my'
import useSettingsUsersStore from '@/stores/settings/users/users'

export const useUserPermissionsModalStore = defineForm({
    id: 'settingsUsersModalsPermissions',

    inputs: () => ({
        permissions: [],
        user: null
    }),

    submitRequest() {
        return api.route('admin accounts organizations members update', [ useMyStore().user.organization.id, this.inputs.user.id ])
            .formData({ _method: 'put', 'permissions[]': this.inputs.permissions }, true)
            .post()
    },

    async onResponse(res) {
        await useSettingsUsersStore().reloadUsers()

        useModal().hide('settings-user-permissions')

        this.fulfillPromise()
    },

    actions: {
        open(user) {
            this.reset()
            
            this.inputs.user = user
            this.inputs.permissions = [ ...user.permissions ]

            useModal().show('settings-user-permissions')

            return this.makePromise()
        },

        cancel() {
            useModal().hide('settings-user-permissions')

            this.fulfillPromise()
        },
        
        togglePermission(permission) {
            this.inputs.permissions.includes(permission)
                ? this.inputs.permissions = this.inputs.permissions.filter(p => p != permission)
                : this.inputs.permissions.push(permission) 
        }
    }
})

export default useUserPermissionsModalStore
