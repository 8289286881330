<template>
    <div class="mx-auto w-full max-w-4xl bg-gray-600/80 backdrop-blur rounded-lg py-4">
        <h1 class="text-gray-300 font-medium flex items-center space-x-2 px-6">
            <ui-icon name="subscription"></ui-icon>
            <span>Subscriptions</span>
        </h1>

        <ui-async :guards="! isLoading" class="mt-6">
            <div v-for="item, i in items.filter(i => i.type === 'event-alert')" :key="i"
                 class="px-6 py-3 flex items-center space-x-3 even:bg-white/5">
                <div class="flex flex-grow items-center justify-between">
                    <div class="text-base">
                        <p>{{ item.name }}</p>
                        <p>{{ {'event-alert': 'Event Alert'}[item.type] }}</p>
                    </div>
                    <div class="flex items-center space-x-3 text-sm text-gray-300">
                        <div>
                            <ui-switch
                                @click="! item.configuration?.inApp?.unsubscribers?.includes(user.id) ? unsubscribe(item, 'inApp') : resubscribe(item, 'inApp')"
                                :value="! item.configuration?.inApp?.unsubscribers?.includes(user.id)"
                                size="sm">
                            </ui-switch>
                            <span class="ml-1.5">In-App</span>
                        </div>
                        <div>
                            <ui-switch
                                @click="! item.configuration?.email?.unsubscribers?.includes(user.id) ? unsubscribe(item, 'email') : resubscribe(item, 'email')"
                                :value="! item.configuration?.email?.unsubscribers?.includes(user.id)"
                                size="sm">
                            </ui-switch>
                            <span class="ml-1.5">Email</span>
                        </div>
                    </div>
                </div>
            </div>
        </ui-async>
    </div>
</template>

<script>
import useMyStore from '@/stores/me/my'
import useSettingsSubscriptionsStore from '@/stores/settings/subscriptions'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useMyStore, [ 'user' ]),
        ...mapState(useSettingsSubscriptionsStore, [ 'isLoading', 'items', 'itemBeingUpdated' ])
    },

    methods: {
        ...mapActions(useSettingsSubscriptionsStore, [ 'unsubscribe', 'resubscribe' ])
    }
}
</script>
