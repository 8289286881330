import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'

export const useDataProvidersModalsEditCustomReport = defineForm({
    id: 'dataProvidersModalsEditCustomReport',

    state: {
        //
    },

    inputs: () => ({

    }),

    actions: {
        open() {
            this.reset()

            useModal().show('data-providers-edit-custom-report-modal')
        },

        close() {
            useModal().hide('data-providers-edit-custom-report-modal')
        }
    }
})

export default useDataProvidersModalsEditCustomReport
