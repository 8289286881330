<template>
    <ui-modal name="settings-delete-user" title="Delete User" width="100%" height="auto" :max-width="480" @closed="cancel">
        <ui-form :store="settingsUsersModalsDeleteUserStore" v-slot="{ inputs, submitting, errors }">
            <div class="font-medium text-lg text-center">
                Remove the user <span class="font-bold">"{{inputs.user?.name}}"</span>.
            </div>

            <div class="text-gray-200 text-center mt-4">
                The user and all content associated with their personal workspace will be removed.
                Content created by this user in other workspaces won't be affected. 
            </div>
            
            <div class="font-medium text-gray-200 text-center mt-4">
                This action can not be undone.
            </div>

            <div class="text-center mt-6">
                <ui-button size="lg" color="red" type="submit" :disabled="submitting">Remove</ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useDeleteUserModalStore from '@/stores/settings/users/modals/delete-user'

import { mapActions, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useDeleteUserModalStore)
    },

    methods: {
        ...mapActions(useDeleteUserModalStore, [ 'cancel', 'reset', 'submit' ])
    }
}
</script>
