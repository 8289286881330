import api from '@/api'

import {defineStore} from 'pinia'

export const useManagementPerspectivesStore = defineStore({
    id: 'managementPerspectives',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize() {
            return this.load()
        },

        async load(force = false) {
            await new Promise(r => setTimeout(r, 5000))

            if (this.isInitialized && !force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('sentinel management perspectives').get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        find(id) {
            return this.items.find(i => i.id == id)
        }
    }
})

export default useManagementPerspectivesStore
