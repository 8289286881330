<template>
    <loading-bar></loading-bar>
    <div class="relative">
        <div class="flex h-screen w-full">
            <div class="h-screen w-full bg-neutral-900" id="map"></div>
        </div>

        <div class="absolute inset-0 z-40 pointer-events-none flex py-6 pl-16">
            <div class="flex-1 min-w-0 flex flex-col justify-between items-center px-6">
                <div v-if="!presentationMode" class="flex flex-col items-center space-y-4 w-full">
                    <events-toolbar></events-toolbar>
                    <shape-toolbar></shape-toolbar>
                </div>
                <map-controls></map-controls>
            </div>
            <details-sidebar v-if="!presentationMode"></details-sidebar>
        </div>
    </div>

    <div v-if="presentationMode" class="marquee-panel text-gray-100 z-50 fixed w-full bottom-0 left-0">
        <marquee-text :key="marqueeId" :repeat="10" :duration="30">{{ marqueeText }}</marquee-text>
    </div>
</template>

<script>
import DetailsSidebar from './details/sidebar'
import EventsToolbar from './toolbar/toolbar'
import MapControls from './map/controls'
import MarqueeText from 'vue-marquee-text-component'
import ShapeToolbar from './toolbar/shape'

import LoadingBar from '@/components/overview/map/loading-bar.vue'

import { useMapStore } from '@/stores/overview/map'
import usePresentationStore from "@/stores/overview/presentation"

import { mapState } from 'pinia'
import 'mapbox-gl/dist/mapbox-gl.css'

export default {
    components: {
        DetailsSidebar,
        EventsToolbar,
        LoadingBar,
        MapControls,
        MarqueeText,
        ShapeToolbar
    },

    computed: {
        ...mapState(usePresentationStore, [ 'presentationMode', 'marqueeText', 'marqueeId' ])
    },

    mounted() {
        useMapStore().createMap()
    }
}
</script>
