import useSourceStatsStore from '@/stores/sources/source-stats'
import useMyMonitoredSourcesStore from '@/stores/me/monitored-sources'
import useMyMonitoringRequestsStore from '@/stores/me/monitoring-requests'

import { defineStore } from 'pinia'

export const useSettingsGerulataMonitorProviderPage = defineStore({
    id: 'settingsGerulataMonitorDataProviderPage',

    actions: {
        async afterEnter() {
            useSourceStatsStore().reload()
            useMyMonitoredSourcesStore().reload()
            useMyMonitoringRequestsStore().reload()
        }
    }
})

export default useSettingsGerulataMonitorProviderPage
