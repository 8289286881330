<template>
    <div class="space-y-4">
        <ui-async :guards="reports">
            <div v-for="report in reports" :key="report.id" class="leading-tight mb-2 pt-5 rounded-md bg-gray-600 block">

                <div class="flex items-start space-x-4 px-4">
                    <div>
<!--                        <img v-if="report.content?.source?.avatarUrl" :src="report.content.source.avatarUrl" class="block w-10 h-10 rounded-full bg-gray-400 object-cover" />-->
                        <ui-avatar :item="report.content?.source" class="block w-10 h-10 p-0 rounded-full bg-gray-400 object-cover"></ui-avatar>
                    </div>

                    <div class="flex-1">
                        <div class="font-semibold text-gray-100">{{ report.content?.source?.title || 'Unknown Source' }}</div>
                        <div class="flex items-center space-x-1 text-sm text-gray-300">
                            {{ $dateTime(report.content?.publishedAt || report.createdAt) }}
                        </div>
                    </div>

                    <div class="text-right pt-0.5 text-gray-300">
                        <ui-contextual-menu size="sm" icon="more-vertical" :plain="true">
                            <ui-dropdown-link @click="junoLink(report)">Open in Juno</ui-dropdown-link>
                        </ui-contextual-menu>
                    </div>
                </div>

                <a :href="report.content?.link || '#'" target="_blank" class="block text-gray-200 w-full p-4">
                    {{ strLimit(report.content?.body || report.description, 240) }}
                </a>

                <div class="text-lg px-4 py-2 border-t border-gray-400" v-if="report.content?.source?.type != 'web-feed'">
                    <span class="mr-4">
                        <ui-icon name="interactions" class="text-xl"></ui-icon>
                        <span class="ml-2">{{ report.content?.metrics?.interactions }} interactions</span>
                    </span>

                    <span>
                        <ui-icon name="views" class="text-xl"></ui-icon>
                        <span class="ml-2">{{ report.content?.metrics?.views }} views</span>
                    </span>
                </div>
            </div>
        </ui-async>
    </div>
</template>

<script>
import { strLimit } from '@/helpers'

export default {
    props: [ 'reports' ],

    methods: {
        strLimit: strLimit,
        junoLink: function(item) {
            const juno = process.env.VUE_APP_JUNO_URL
            const type = item.content.type
            const sourceId = item.content.source.id
            const contentId = item.content.id
            const url = `${juno}/targets/sources/${type}/${sourceId}/content/${contentId}`

            window.open(url, '_blank').focus()
        }
    }
}
</script>
