<template>
    <ui-modal name="overview-geo-feature-edit" title="Geography" width="100%" height="auto" :max-width="600" :max-height="480" @opened="opened" @closed="cancel">
        <ui-form :store="overviewModalsGeoFeatureEditStore" v-slot="{ inputs, submitting, errors }">
            <div>
                <label for="name" class="block text-sm text-gray-400 font-medium mb-1">
                    Name
                </label>

                <div>
                    <ui-input type="text" name="name" id="name" placeholder="e.g. Paramilitary Groups" ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                </div>
            </div>

            <div v-if="! feature.layerId" class="mt-4">
                <label for="layer" class="block text-sm text-gray-400 font-medium mb-1">
                    Group
                </label>

                <div>
                    <select name="layer" id="layer" v-model="inputs.layer" class="bg-gray-800 border-gray-500 text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-0 focus:placeholder-gray-400 shadow-sm focus:ring-gerulata-green-100 block w-full sm:text-sm rounded">
                        <option v-for="layer in layers" :key="layer.id" :value="layer">{{layer.name}}</option>
                    </select>
                </div>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button color="red" class="mr-auto" :action="deleteGeoFeature" :disabled="submitting" v-if="feature && feature.id">
                    Delete
                </ui-button>

                <ui-button type="submit" :color="feature && feature.id ? 'blue' : 'green'" :disabled="submitting">
                    {{ feature && feature.id ? 'Save' : 'Create' }}
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useGeoFeatureEditModalStore from '@/stores/overview/modals/geo-feature-edit'
import useMyGeoFeaturesStore from '@/stores/me/geo-features'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useGeoFeatureEditModalStore),
        ...mapState(useMyGeoFeaturesStore, [ 'layers' ]),
        
        feature() { return this.overviewModalsGeoFeatureEditStore.inputs?.feature }
    },

    methods: {
        ...mapActions(useGeoFeatureEditModalStore, {
            'cancel': 'cancel', 'deleteGeoFeature': 'delete', 'submit': 'submit'
        }),

        opened() { this.$refs.nameInput.focus() }
    }
}
</script>
