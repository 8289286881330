<template>
    <div class="max-h-screen absolute top-0 right-0 w-full max-w-sm flex flex-col p-4 z-50" v-show="toasts.length" ref="toaster">
        <div class="h-10 w-full flex items-start justify-center absolute top-4" v-if="toasts.length > 2">
            <a href="#" @click.prevent="dismissAllToasts" class="flex items-center justify-center bg-gray-900 text-gray-400 rounded-xl py-1 px-2.5 leading-0 font-medium text-sm">
                <ui-icon name="x" class="mr-1"></ui-icon>
                <span>Dismiss all notifications</span>
            </a>
        </div>

        <transition-group
            tag="div"
            class="flex flex-col items-end overflow-y-auto" :class="{ 'pt-10': toasts.length > 2, 'pb-10': ! isScrolledToTheBottom }"
            enter-active-class="ease-out duration-300 transition"
            enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            leave-active-class="transition ease-in duration-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
            appear
            @scroll="handleScroll">
            <div class="w-full shrink-0 bg-gray-700 shadow-lg rounded pointer-events-auto border border-gray-600 flex items-center overflow-hidden mb-4" :key="toast.id" v-for="toast in toasts">
                <div class="shrink-0 flex items-center justify-center text-xl py-3 ml-3">
                    <ui-icon name="check-full-circle" class="text-green-400" v-if="toast.type == 'success'"></ui-icon>
                    <ui-icon name="error-full-circle" class="text-red-400" v-if="toast.type == 'error'"></ui-icon>
                    <ui-icon name="info" class="text-blue-400" v-if="toast.type == 'info'"></ui-icon>
                </div>
                <div class="w-0 flex-1 py-2 ml-3 mr-1">
                    <p class="text-sm font-medium" :class="{'text-green-300': toast.type == 'success', 'text-red-300': toast.type == 'error', 'text-blue-300': toast.type == 'info'}" v-html="toast.title"></p>
                    <p class="mt-0.5 text-xs text-gray-200" v-if="toast.text" v-html="toast.text"></p>
                </div>
                <div class="flex flex-col divide-y divide-gray-600 border-l border-gray-600 self-stretch">
                    <div class="h-0 flex-1 flex">
                        <button class="w-full border border-transparent rounded-none rounded-tr-lg px-3 py-2 flex items-center justify-center text-xs font-medium text-gray-300 hover:text-gray-200" @click="dismissToast(toast)">
                            Dismiss
                        </button>
                    </div>
                    <div class="h-0 flex-1 flex" v-if="toast.action">
                        <button class="w-full border border-transparent rounded-none rounded-tr-lg px-3 py-2 flex items-center justify-center text-sm font-medium text-gray-300 hover:text-gray-200" @click="toast.onAction(toast)">
                            {{toast.action}}
                        </button>
                    </div>
                </div>
            </div>
        </transition-group>

        <div class="absolute bottom-4 flex items-end justify-center h-10 w-full" v-if="! isScrolledToTheBottom">
            <div class="flex items-center justify-center bg-gray-900 rounded-full h-8 w-8">
                <ui-icon name="arrow-down" class="text-2xl text-gray-400"></ui-icon>
            </div>
        </div>
    </div>
</template>

<script>
import useMyNotifications from '@/stores/me/notifications'

import { mapActions, mapState } from 'pinia'

export default {
    data: () => ({
        isScrolledToTheBottom: true
    }),

    mounted() {
        this.isScrolledToTheBottom = this.$refs.toaster.scrollHeight < window.innerHeight
    },

    computed: {
        ...mapState(useMyNotifications, [ 'toasts' ])
    },

    methods: {
        ...mapActions(useMyNotifications, [ 'dismissToast', 'dismissAllToasts' ]),

        handleScroll(el) {
            this.isScrolledToTheBottom = (el.target.offsetHeight + el.target.scrollTop + 40) >= el.target.scrollHeight
        }
    }
}
</script>
