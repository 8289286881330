<template>
    <a href="#" class="block px-6 py-3 hover:bg-gray-600" :class="{ 'bg-gray-600': focused }" @click.prevent>
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <ui-icon name="map-pin" class="text-xl"></ui-icon>
            </div>
            
            <div class="mx-4 flex-1 min-w-0">
                <div class="flex items-center leading-5 text-gray-200">
                    <div class="truncate">Show coordinates <span class="font-medium">{{result.coordinates.lat}}, {{result.coordinates.lon}}</span></div>
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-400"></ui-icon>
            </div>
        </div>
    </a>
</template>

<script>
import useMapStore from '@/stores/overview/map'

export default {
    props: [ 'focused', 'result' ],

    computed: {
    },

    methods: {
        open() {
            useMapStore().focusPoint(this.result.coordinates)
        }
    }
}
</script>
