<template>
    <div class="flex flex-grow items-center justify-between">
        <a href="#" @click.prevent="openEditEventAlertModal(item)" class="text-base space-y-1.5">
            <div class="font-medium text-gray-100">{{ item.data.event.title }}</div>
            <div class="flex items-center text-sm text-gray-300">
                <span class="flex h-4 w-4 items-center justify-center rounded-full mr-1.5" :style="{ background: category.color }" v-tooltip="category.fullName">
                    <img :src="category.icon" :alt="category.name" class="w-3">
                </span>
                <span>{{ category.name }} event from event alert {{ item.causer.name }}</span>
            </div>
        </a>
        <div class="flex items-center space-x-3">
            <div v-if="item.status === 'processing'"
                 class="flex items-center rounded border text-sm font-medium text-gray-100 border-gray-500 px-1.5 py-0.5">
                <ui-spinner type="clip" class="mr-1.5"></ui-spinner>
                <span>Processing</span>
            </div>

            <div v-if="item.status === 'approved'"
                 class="rounded border text-sm font-medium border-gray-500 text-gerulata-green-100 px-1.5 py-0.5">
                Approved
            </div>

            <div v-if="item.status === 'ignored'"
                 class="rounded border text-sm font-medium text-red-300 border-gray-500 px-1.5 py-0.5">
                Ignored
            </div>

            <div v-if="item.status === 'pending'"
                 class="rounded border text-sm font-medium text-amber-300 border-gray-500 px-1.5 py-0.5">
                Pending
            </div>

            <div class="mx-3 flex items-center space-x-1.5">
                <a href="#" v-if="item.status === 'pending'" @click.prevent="process(item, 'approved')"
                   class="rounded border text-sm font-medium border-gray-500 text-gerulata-green-100 px-1.5 py-0.5"
                   v-tooltip="'Approve'">
                    <ui-icon name="check"></ui-icon>
                </a>

                <a href="#" v-if="item.status === 'pending'" @click.prevent="process(item, 'ignored')"
                   class="rounded border text-sm font-medium text-red-300 border-gray-500 px-1.5 py-0.5"
                   v-tooltip="'Ignore'">
                    <ui-icon name="x"></ui-icon>
                </a>
            </div>

            <a href="#" @click.prevent="openEditEventAlertModal(item)" class="text-xl text-gray-400">
                <ui-icon name="chevron-right"></ui-icon>
            </a>
        </div>
    </div>
</template>

<script>
import useEventCategoriesStore from "@/stores/me/event-categories"
import useModeratedEventsModalsEventAlertEditFormStore from '@/stores/management/moderated-events/modals/event-alert-edit'
import useManagementModeratedEventsStore from '@/stores/management/moderated-events/moderated-events'

import {mapActions} from 'pinia'

export default {
    props: ['item'],

    computed: {
        category() { return useEventCategoriesStore().find(this.item.data.event.category) }
    },

    methods: {
        ...mapActions(useModeratedEventsModalsEventAlertEditFormStore, {openEditEventAlertModal: 'open'}),
        ...mapActions(useManagementModeratedEventsStore, ['process'])
    }
}
</script>
