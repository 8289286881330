<template>
    <div class="mx-auto w-full max-w-4xl bg-gray-700 border border-gray-600 rounded-lg">
        <div class="px-6 py-5">
            <h1 class="font-semibold"><ui-icon name="database-connect" class="mr-1"></ui-icon> Custom Providers</h1>
            <p class="mt-2 text-sm text-gray-300">Bring your own data into Sentinel with custom sources and reports.</p>
        </div>

        <div class="mt-4">
            <div class="flex items-center px-6">
                <div class="flex-1">
                    <h2 class="font-semibold">Custom Sources</h2>
                    <p class="mt-2 text-sm text-gray-300">List of custom sources in your organization.</p>
                </div>
                <div class="flex items-center space-x-5">
                    <a href="#" class="text-gerulata-green-100 hover:text-gerulata-green-300" @click.prevent="openAllCustomSourcesModal">Show more</a>
                    <ui-button icon="plus" color="green" @click="openEditCustomSourceModal">
                        New Source
                    </ui-button>
                </div>
            </div>

            <div class="h-12 px-6 py-1.5 flex items-center space-x-3 even:bg-white/5">
                <div class="flex flex-grow items-center justify-between">
                    <div class="flex items-center">
                        <p class="w-28 text-xs text-gray-300 mr-3">2022-12-14 13:30:00</p>
                        <img class="rounded-full w-4 h-4 shadow mr-1.5" src="https://avatars.staging.gerulata.com/api/?name=Metro+News&color=ffffff&background=db2777" alt="">
                        <p>Metro News #9</p>
                    </div>

                    <a href="#" class="text-xl text-cool-400">
                        <ui-icon name="chevron-right"></ui-icon>
                    </a>
                </div>
            </div>

            <div class="h-12 px-6 py-1.5 flex items-center space-x-3 even:bg-white/5">
                <div class="flex flex-grow items-center justify-between">
                    <div class="flex items-center">
                        <p class="w-28 text-xs text-gray-300 mr-3">2022-12-14 13:30:00</p>
                        <img class="rounded-full w-4 h-4 shadow mr-1.5" src="https://avatars.staging.gerulata.com/api/?name=Spanish+National+TV&color=000000&background=A9EE8A" alt="">
                        <p>Spanish National TV</p>
                    </div>

                    <a href="#" class="text-xl text-cool-400">
                        <ui-icon name="chevron-right"></ui-icon>
                    </a>
                </div>
            </div>

            <div class="h-12 px-6 py-1.5 flex items-center space-x-3 even:bg-white/5">
                <div class="flex flex-grow items-center justify-between">
                    <div class="flex items-center">
                        <p class="w-28 text-xs text-gray-300 mr-3">2022-12-14 13:30:00</p>
                        <img class="rounded-full w-4 h-4 shadow mr-1.5" src="https://avatars.staging.gerulata.com/api/?name=Metro+News&color=000000&background=FBEA58" alt="">
                        <p>RTVS 1</p>
                    </div>

                    <a href="#" class="text-xl text-cool-400">
                        <ui-icon name="chevron-right"></ui-icon>
                    </a>
                </div>
            </div>
        </div>

        <div class="mt-8">
            <div class="flex items-center px-6">
                <div class="flex-1">
                    <h2 class="font-semibold">Custom Reports</h2>
                    <p class="mt-2 text-sm text-gray-300">List of custom reports in your organization.</p>
                </div>
                <div class="flex items-center space-x-5">
                    <a href="#" class="text-gerulata-green-100 hover:text-gerulata-green-300" @click.prevent="openAllCustomReportsModal">Show more</a>
                    <ui-button icon="plus" color="green" @click="openEditCustomReportModal">
                        New Report
                    </ui-button>
                </div>
            </div>

            <div class="h-12 px-6 py-1.5 flex items-center space-x-3 even:bg-white/5">
                <div class="flex flex-grow items-center justify-between">
                    <div class="flex items-center">
                        <p class="w-28 text-xs text-gray-300 mr-3">2022-12-14 13:30:00</p>
                        <div class="p-0.5 w-4 h-4 bg-gray-300 rounded-full justify-center items-center mr-1.5 shadow" :style="{ backgroundColor: findCategory('crime-and-law').color }">
                            <img :src="findCategory('crime-and-law.general').icon" class="mx-auto opacity-80" />
                        </div>
                        <p>Prieskum Ipsosu: PS je opäť druhé za Smerom, veľké problémy má SaS, od Kollára...</p>
                    </div>

                    <a href="#" class="text-xl text-cool-400">
                        <ui-icon name="chevron-right"></ui-icon>
                    </a>
                </div>
            </div>

            <div class="h-12 px-6 py-1.5 flex items-center space-x-3 even:bg-white/5">
                <div class="flex flex-grow items-center justify-between">
                    <div class="flex items-center">
                        <p class="w-28 text-xs text-gray-300 mr-3">2022-12-14 13:30:00</p>
                        <div class="p-0.5 w-4 h-4 bg-gray-300 rounded-full justify-center items-center mr-1.5 shadow" :style="{ backgroundColor: findCategory('economy-and-business').color }">
                            <img :src="findCategory('economy-and-business.general').icon" class="mx-auto opacity-80" />
                        </div>
                        <p>Politickí turisti: Za Smer ide aj bývalý kandidát Harabinovej Vlasti...</p>
                    </div>

                    <a href="#" class="text-xl text-cool-400">
                        <ui-icon name="chevron-right"></ui-icon>
                    </a>
                </div>
            </div>

            <div class="h-12 px-6 py-1.5 flex items-center space-x-3 even:bg-white/5">
                <div class="flex flex-grow items-center justify-between">
                    <div class="flex items-center">
                        <p class="w-28 text-xs text-gray-300 mr-3">2022-12-14 13:30:00</p>
                        <div class="p-0.5 w-4 h-4 bg-gray-300 rounded-full justify-center items-center mr-1.5 shadow" :style="{ backgroundColor: findCategory('politics').color }">
                            <img :src="findCategory('politics.general').icon" class="mx-auto opacity-80" />
                        </div>
                        <p>Bottom of MacBook Separating or Bulging: Is It a Swollen Battery?...</p>
                    </div>

                    <a href="#" class="text-xl text-cool-400">
                        <ui-icon name="chevron-right"></ui-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <Teleport to="#root">
        <data-providers-custom-sources-modal></data-providers-custom-sources-modal>
        <data-providers-custom-reports-modal></data-providers-custom-reports-modal>
        <data-providers-edit-custom-source-modal></data-providers-edit-custom-source-modal>
        <data-providers-edit-custom-report-modal></data-providers-edit-custom-report-modal>
    </Teleport>
</template>

<script>
import useEventCategoriesStore from '@/stores/me/event-categories'
import useDataProvidersModalsEditCustomSource from '@/stores/management/data-providers/modals/edit-custom-source'
import useDataProvidersModalsEditCustomReport from '@/stores/management/data-providers/modals/edit-custom-report'
import useDataProvidersModalsCustomSources from '@/stores/management/data-providers/modals/custom-sources'
import useDataProvidersModalsCustomReports from '@/stores/management/data-providers/modals/custom-reports'

import DataProvidersCustomSourcesModal from '@/components/settings/data-providers/modals/custom-sources'
import DataProvidersCustomReportsModal from '@/components/settings/data-providers/modals/custom-reports'
import DataProvidersEditCustomSourceModal from '@/components/settings/data-providers/modals/edit-custom-source'
import DataProvidersEditCustomReportModal from '@/components/settings/data-providers/modals/edit-custom-report'

import { mapActions } from 'pinia'

export default {
    components: {
        DataProvidersCustomSourcesModal,
        DataProvidersCustomReportsModal,
        DataProvidersEditCustomSourceModal,
        DataProvidersEditCustomReportModal
    },

    methods: {
        ...mapActions(useDataProvidersModalsCustomSources, { openAllCustomSourcesModal: 'open' }),
        ...mapActions(useDataProvidersModalsCustomReports, { openAllCustomReportsModal: 'open' }),
        ...mapActions(useDataProvidersModalsEditCustomSource, { openEditCustomSourceModal: 'open' }),
        ...mapActions(useDataProvidersModalsEditCustomReport, { openEditCustomReportModal: 'open' }),
        ...mapActions(useEventCategoriesStore, { findCategory: 'find' })
    }
}
</script>