import { useModal  } from '@/helpers'
import { defineStore } from "pinia";


export const useImagePreviewModalStore = defineStore({
    id: 'imagePreviewModal',

    state: () => ({
        src: null
    }),

    actions: {
        open(src) {
            this.src = src
            useModal().show('image-preview')
        },

        close() {
            this.src = null
            useModal().hide('image-preview')
        }
    }
})

export default useImagePreviewModalStore
