import api from '@/api'

import useMyStore from '@/stores/me/my'

import { defineStore } from 'pinia'

export const useSettingsSubscriptionsStore = defineStore({
    id: 'settingsSubscriptions',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null,

        itemBeingUpdated: null
    }),

    actions: {
        async initialize() {
            return this.load()
        },

        async load(force = false) {
            if (this.isInitialized && !force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me subscriptions').get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        async all() {
            await this.load()
            return this.items
        },

        find(id) {
            return this.items.find(i => i.id == id)
        },

        async unsubscribe(item, channel) {
            this.isUpdating = true

            if (! (channel in item.configuration) || ! ('unsubscribers' in item.configuration[channel])) {
                item.configuration[channel] = { 'unsubscribers': [] }
            }

            item.configuration[channel].unsubscribers.push(useMyStore().user.id)

            await api.route('me subscriptions unsubscribe', { id: item.id, channel })
                .post()
                .res(() => this.isUpdating = false)
        },

        async resubscribe(item, channel) {
            this.isUpdating = true

            item.configuration[channel].unsubscribers = item.configuration[channel].unsubscribers.filter(id => id != useMyStore().user.id)

            await api.route('me subscriptions resubscribe', { id: item.id, channel })
                .post()
                .res(() => this.isUpdating = false)
        }
    }
})

export default useSettingsSubscriptionsStore
