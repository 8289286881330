<template>
    <div :class="wrapperClass" :style="wrapperStyle">
        <editor-content :class="inputClass" :editor="editor" @keyup.esc="blur" />
    </div>
</template>

<script>
import { Editor, EditorContent, Extension } from '@tiptap/vue-3'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import { Decoration, DecorationSet } from '@tiptap/pm/view'
import Placeholder from '@tiptap/extension-placeholder'
import Paragraph from '@tiptap/extension-paragraph'
import Hardbreak from '@tiptap/extension-hard-break'
import Document from '@tiptap/extension-document'
import History from '@tiptap/extension-history'
import Text from '@tiptap/extension-text'

import debounce from 'just-debounce-it'

export default {
    props: {
        'modelValue': { type: String, default: '' },
        'allowMultiLine': { type: Boolean, default: false },
        'allowHighlighting': { type: Boolean, default: false },
        'wrapperClasses': { type: String, default: null },
        'focusedWrapperClasses': { type: String, default: null },
        'blurredWrapperClasses': { type: String, default: null },
        'classes': { type: String, default: null },
        'focusedClasses': { type: String, default: null },
        'blurredClasses': { type: String, default: null }
    },

    data: () => ({
        isFocused: false,

        editor: null,
        editorValue: null
    }),

    components: {
        EditorContent
    },

    mounted() {
        const allowMultiLine = this.allowMultiLine
        const allowHighlighting = this.allowHighlighting

        const highlight = Extension.create({
            name: 'highlight',

            addProseMirrorPlugins() {
                const highlight = (doc) => {
                    let results = []

                    doc.descendants((node, position) => {
                        if (! node.isText) { return }

                        const regex = /\b(AND|OR|NOT)\b(?=([^"]*"[^"]*")*[^"]*$)|\|(?!\w)|\+(?!\w)|([*~\-])/gm
                        let match = null

                        while ((match = regex.exec(node.text)) !== null) {
                            if (match.index === regex.lastIndex) { regex.lastIndex++ }

                            results.push({
                                type: ['~', '*'].includes(match[0]) ? 'operator' : 'keyword',
                                from: position + match.index,
                                to: position + match.index + match[0].length
                            })
                        }
                    })

                    const decorations = []

                    results.forEach(result => {
                        decorations.push(Decoration.inline(result.from, result.to, {
                            class: result.type === 'operator' ? 'text-rose-500 font-medium' : 'text-gerulata-green-300 font-medium'
                        }))
                    })

                    return DecorationSet.create(doc, decorations)
                }

                return [
                    new Plugin({
                        key: new PluginKey('highlight'),
                        state: {
                            init(_, { doc }) {
                                if (allowHighlighting) { highlight(doc) }
                            },

                            apply(transaction, oldState) {
                                return transaction.docChanged && allowHighlighting ? highlight(transaction.doc) : oldState
                            }
                        },
                        props: {
                            decorations(state) {
                                return this.getState(state)
                            },
                        }
                    })
                ]
            }
        })

        const shortcuts = Extension.create({
            name: 'shortcuts',

            addKeyboardShortcuts: () => ({
                'Enter': () => {
                    this.$emit('update:modelValue', this.editorValue = this.editor.getText('\n'))
                    this.$emit('submit')
                    return true
                },
                'Shift-Enter': () => {
                    if (allowMultiLine) {
                        this.editor.commands.setHardBreak()
                    }
                    return true
                }
            })
        })

        this.editor = new Editor({
            autocomplete: false,
            spellcheck: false,
            content: '',
            extensions: [
                Placeholder.configure({ placeholder: this.placeholder }),
                Hardbreak,
                Paragraph,
                Document,
                Text,
                History,
                highlight,
                shortcuts
            ],
            onFocus: () => {
                this.focus()
            },
            onBlur: () => {
                this.blur()
            },
            onTransaction: ({ editor, transaction }) => {
                this.transaction({ editor, transaction })
            }
        })
    },

    beforeUnmount() {
        this.editor.destroy()
    },

    methods: {
        focus() {
            this.editor.commands.focus()

            this.isFocused = true

            this.$emit('focus')
        },

        blur() {
            if (! this.editor.getText()) {
                this.editor.commands.clearContent()
            }

            this.editor.commands.blur()

            this.isFocused = false

            this.$emit('blur')
        },

        transaction({ editor, transaction }) {
            this.editorValue = this.editor.getText('\n')

            this.$emit('transaction', { editor, transaction })

            this.model = this.editorValue
        },

        processValue(value) {
            return value ? value.replaceAll(/\n/g, '<br>') : value
        }
    },

    computed: {
        model: {
            get() { return this.modelValue },
            set: debounce(function (val) { this.$emit('update:modelValue', val) }, 50)
        },

        wrapperClass() {
            let classes = this.wrapperClasses ?? 'relative bg-gray-800 rounded'
            let focusedClasses = this.focusedWrapperClasses ?? 'h-auto'
            let blurredClasses = this.blurredWrapperClasses ?? 'h-9'

            return this.isFocused ? `${classes} ${focusedClasses}` : `${classes} ${blurredClasses}`
        },

        wrapperStyle() {
            return {
                zIndex: 29
            }
        },

        inputClass() {
            let classes = this.classes ?? 'h-full w-full overflow-y-hidden pl-10 pr-26 rounded align-middle z-30 max-h-48 '
            let focusedClasses = this.focusedClasses ?? 'bg-gray-800 ring-2 ring-gerulata-green-300'
            let blurredClasses = this.blurredClasses ?? 'resize-none text-gray-100'

            return this.isFocused ? `${classes} ${focusedClasses}` : `${classes} ${blurredClasses}`
        },

        placeholder() {
            if (this.model) return

            return 'Search in Gerulata Sentinel...'
        },
    },

    watch: {
        modelValue(value) {
            if (value == this.editorValue) return

            this.editor.commands.setContent(this.processValue(value), false)
        }
    }
}
</script>

<style>
.ProseMirror {
    outline: none !important;
    overflow-y: hidden !important;
    min-height: 0 !important;
}

.ProseMirror:not(.ProseMirror-focused) {
    overflow: hidden !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.ProseMirror .is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #77889E;
    pointer-events: none;
    height: 0;
}

.ProseMirror p {
    @apply relative font-sans bg-gray-800 pt-2 pb-0 pr-32;
    z-index: 9999;
    min-height: 2.25rem;
}

.ProseMirror:not(.ProseMirror-focused) p {
    @apply pr-0;
    min-height: 0;
    height: 1.60rem;
}
</style>
