<template>
    <ui-sidebar title="Moderation">
        <div class="flex flex-col h-full">
            <div v-if="isLoading" class="flex h-full flex-col items-center justify-center">
                <ui-spinner></ui-spinner>
            </div>

            <template v-else-if="expandedModeratedEvent">
                <event-alert-detail v-if="expandedModeratedEvent.type === 'event-alert'"></event-alert-detail>
            </template>

            <div v-else-if="! items.length" class="flex flex-col h-full items-center justify-center text-gray-300">
                <ui-icon name="navigation.moderated-events" class="text-3xl"></ui-icon>
                <p class="mt-2">You will find your moderation queue here.</p>
            </div>

            <div v-else class="min-h-0 flex-1 overflow-auto divide-y divide-gray-600 py-1 px-2">
                <template v-for="item in items.filter(i => i.status === 'pending')" :key="item.id">
                    <event-alert v-if="item.type === 'event-alert'" :item="item"></event-alert>
                </template>

                <div class="py-4">
                    <ui-infinite-loading @infinite="scroll" v-if="items.length"></ui-infinite-loading>
                </div>
            </div>
        </div>
    </ui-sidebar>
</template>

<script>
import UiSidebar from '@/components/ui/sidebar'

import {mapActions, mapState} from 'pinia'

import useManagementModeratedEventsStore from '@/stores/management/moderated-events/moderated-events'
import useManagementModeratedEventsOverlayStore from '@/stores/management/moderated-events/overlay'
import useOverviewStore from '@/stores/overview/overview'

import EventAlert from '@/components/management/moderated-events/overlay/event-alert'
import EventAlertDetail from '@/components/management/moderated-events/overlay/details/event-alert'

export default {
    components: {
        EventAlert,
        EventAlertDetail,
        UiSidebar
    },

    computed: {
        ...mapState(useManagementModeratedEventsOverlayStore, [ 'isShown', 'expandedModeratedEvent' ]),
        ...mapState(useManagementModeratedEventsStore, [ 'items', 'isLoading' ]),
        ...mapState(useOverviewStore, [ 'leftSidebar', 'leftSidebarOptions' ])
    },

    methods: {
        ...mapActions(useManagementModeratedEventsOverlayStore, [ 'toggle', 'shrink' ]),
        ...mapActions(useManagementModeratedEventsStore, [ 'scroll' ]),
        ...mapActions(useOverviewStore, [ 'hideLeftSidebar' ])
    },
}
</script>