<template>
    <button type="button" class="inline-flex justify-center items-center rounded-full font-bold" :class="effectiveClass" @click.prevent="() => show(page, section)">
        ?
    </button>
</template>

<script>
import useSupportHelpStore from '@/stores/support/help'

import { mapActions } from 'pinia'

export default {
    props: [ 'background', 'page', 'section', 'size' ],

    computed: {
        effectiveClass() {
            let size = {
                'smaller': 'w-4 h-4',
                'small': 'w-5 h-5',
                'normal': 'w-6 h-6'
            }[this.size || 'normal']

            let background = {
                'darker': 'bg-gray-400 hover:bg-gray-500 text-gray-700 hover:text-gray-900',
                'dark': 'bg-gray-600 hover:bg-gray-500 text-gray-300 hover:text-gray-200',
                'normal': 'bg-gray-200 hover:bg-gray-300 text-gray-700 hover:text-gray-900'
            }[this.background || 'normal']

            return `${size} ${background}`
        }
    },

    methods: {
        ...mapActions(useSupportHelpStore, [ 'show' ])
    }
}
</script>