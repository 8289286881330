<template>
    <ui-modal name="data-providers-add-source-modal" title="Add Source" max-width="480">
        <ui-form :store="dataProvidersModalsAddSourceStore" v-slot="{ inputs, submitting, errors }">
            <template v-if="inputs.step == 'type'">
                <div class="space-y-1 text-gray-800 w-full">
                    <a href="#" class="flex items-center w-full px-6 py-3 cursor-pointer text-gerulata-green-100 hover:text-gerulata-green-300 bg-gray-600 hover:bg-gray-600/80 rounded-lg text-lg" @click.prevent="selectType(inputs, type)" :key="type.id" v-for="type in availableTypes">
                        <ui-icon :name="type.icon" class="shrink-0 text-2xl"></ui-icon>
                        <div class="px-3">
                            <div class="font-medium">{{type.name}}</div>
                        </div>
                        <div class="ml-auto">
                            <ui-icon name="arrow-right-circle" class="text-2xl"></ui-icon>
                        </div>
                    </a>
                </div>
            </template>

            <template v-if="inputs.step == 'url'">
                <div>
                    <label for="name" class="block text-sm text-gray-200 font-medium mb-1">
                        {{inputs.type.name}}
                    </label>

                    <ui-input id="value" :placeholder="inputs.type.placeholder" type="text" autocomplete="off" ref="valueField" v-model="inputs.value">
                    </ui-input>
                </div>

                <div class="mt-3">
                    <div class="flex items-center space-x-2">
                        <ui-switch size="sm" id="deleteMergedList" v-model="showListsInput"></ui-switch>
                        <label>Add the new Target into one or more Lists</label>
                    </div>

                    <div class="mt-2" v-if="showListsInput">
                        <ui-dropdown width="w-80">
                            <template v-slot:trigger>
                                <div class="inline-flex items-center px-3 pr-2 h-9 text-sm bg-gray-800 border border-gray-500 text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-0 focus:placeholder-gray-400 focus:ring-gerulata-green-100 rounded-sm cursor-pointer rounded shadow-sm w-full">
                                    <span class="flex-1">
                                        <template v-if="selectedList">
                                            <div class="flex items-center">
                                                <ui-avatar type="target-list" :item="selectedList" class="w-4 h-4 drop-shadow-sm shrink-0 mr-2"></ui-avatar>
                                                {{selectedList.name}}
                                            </div>
                                        </template>
                                        <template v-else-if="inputs.lists.length">
                                            {{inputs.lists.length}} Lists
                                        </template>
                                        <template v-else>
                                            Select a List
                                        </template>
                                    </span>
                                    <ui-icon name="select" class="ml-2"></ui-icon>
                                </div>
                            </template>
                            <template v-slot:content>
                                <div class="px-2 py-1" @click.stop>
                                    <div class="relative w-full h-7">
                                        <div class="absolute inset-y-0 left-2 text-gray-700 flex items-center pointer-events-none">
                                            <ui-icon name="search" class="text-sm"></ui-icon>
                                        </div>
                                        <input placeholder="Search..." type="search" autocomplete="off" class="block h-full w-full pl-7 rounded-sm bg-gray-800 border border-gray-500 text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-0 focus:placeholder-gray-400 focus:ring-gerulata-green-100 text-sm" ref="searchInput" v-model="inputs.listsSearch">
                                    </div>
                                </div>

                                <ui-dropdown-separator></ui-dropdown-separator>

                                <ui-async :guards="areListsInitialized">
                                    <div class="max-h-80 my-2 overflow-y-auto">
                                        <ui-dropdown-link @click="toggleList(inputs, list.id)" :icon="true" :selected="inputs.lists.includes(list.id)" :checkbox="true" keep-open :key="list.id" v-for="list in lists">
                                            <template v-slot:icon>
                                                <div class="w-4 flex justify-center items-center text-center">
                                                    <ui-avatar type="target-list" :item="list" class="w-4 h-4 drop-shadow-sm shrink-0"></ui-avatar>
                                                </div>
                                            </template>
                                            <template v-slot:default>
                                                {{list.name}}
                                            </template>
                                        </ui-dropdown-link>

                                        <div class="text-xs text-gray-300 text-center py-1" v-if="! lists.length">
                                            No lists have been found.
                                        </div>
                                    </div>
                                </ui-async>
                            </template>
                        </ui-dropdown>
                    </div>
                </div>

                <div class="rounded-md bg-blue-900 p-3 mt-6">
                    <div class="flex items-center">
                        <div class="shrink-0">
                            <ui-icon name="info" class="text-blue-200 text-lg"></ui-icon>
                        </div>
                        <div class="ml-3 flex-1 text-sm text-blue-100 leading-snug">
                            <span>You can use one of the following formats:</span>

                            <div class="font-medium">
                                <template v-if="inputs.type.id == 'facebook'">
                                    <div>Facebook Page URL <span class="italic">(eg. https://facebook.com/projektn.sk)</span></div>
                                    <div>Facebook Username <span class="italic">(eg. @projektn.sk)</span></div>
                                </template>
                                <template v-if="inputs.type.id == 'facebook-group'">
                                    <div>Facebook Group URL <span class="italic">(eg. https://facebook.com/groups/vyvojari)</span></div>
                                    <div>Facebook Username <span class="italic">(eg. @vyvojari)</span></div>
                                </template>
                                <template v-if="inputs.type.id == 'instagram-profile'">
                                    <div>Instagram Profile URL <span class="italic">(eg. https://instagram.com/delniq)</span></div>
                                    <div>Instagram Username <span class="italic">(eg. @delniq)</span></div>
                                </template>
                                <template v-if="inputs.type.id == 'telegram'">
                                    <div>Telegram Channel URL <span class="italic">(eg. https://t.me/michalsharksvobodnykanal)</span></div>
                                    <div>Telegram Channel Username <span class="italic">(eg. @michalsharksvobodnykanal)</span></div>
                                </template>
                                <template v-if="inputs.type.id == 'tiktok'">
                                    <div>TikTok User URL <span class="italic">(eg. https://www.tiktok.com/@tvmarkiza)</span></div>
                                    <div>TikTok Username <span class="italic">(eg. @tvmarkiza)</span></div>
                                </template>
                                <template v-if="inputs.type.id == 'twitter'">
                                    <div>Twitter User URL <span class="italic">(eg. https://twitter.com/nasa)</span></div>
                                    <div>Twitter Username <span class="italic">(eg. @nasa)</span></div>
                                </template>
                                <template v-if="inputs.type.id == 'vkontakte'">
                                    <div>VKontakte User, Page or Community URL <span class="italic">(eg. https://vk.com/necenzurovane)</span></div>
                                    <div>VKontakte User, Page or Community Username <span class="italic">(eg. @necenzurovane)</span></div>
                                </template>
                                <template v-if="inputs.type.id == 'web-feeds'">
                                    <div>Web Feed URL (RSS/Atom) <span class="italic">(eg. https://dennikn.sk/feed)</span></div>
                                    <div>Web Site URL <span class="italic">(eg. https://dennikn.sk)</span></div>
                                </template>
                                <template v-if="inputs.type.id == 'youtube'">
                                    <div>Youtube Channel URL <span class="italic">(eg. https://youtube.com/channel/wellstuffedbeaver)</span></div>
                                    <div>Youtube Username <span class="italic">(eg. @wellstuffedbeaver)</span></div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="rounded-md bg-blue-900 p-3 mt-3">
                    <div class="flex items-center">
                        <div class="shrink-0">
                            <ui-icon name="info" class="text-blue-200 text-lg"></ui-icon>
                        </div>
                        <div class="ml-3 flex-1 text-sm text-blue-100 leading-snug">
                            <div class="mt-0">
                                You can enter multiple comma-separated values.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="rounded-md bg-yellow-900 p-3 mt-3" v-if="inputs.type.id == 'facebook'">
                    <div class="flex items-center">
                        <div class="shrink-0">
                            <ui-icon name="warning" class="text-yellow-200 text-lg"></ui-icon>
                        </div>
                        <div class="ml-3 flex-1 text-sm text-yellow-100 leading-snug">
                            Private Facebook profiles and pages migrated from profiles are not supported at this time.
                        </div>
                    </div>
                </div>

                <div class="rounded-md bg-yellow-900 p-3 mt-3" v-if="inputs.type.id == 'facebook-group'">
                    <div class="flex items-center">
                        <div class="shrink-0">
                            <ui-icon name="warning" class="text-yellow-200 text-lg"></ui-icon>
                        </div>
                        <div class="ml-3 flex-1 text-sm text-yellow-100 leading-snug">
                            Private Facebook groups are not supported at this time.
                        </div>
                    </div>
                </div>

                <div class="rounded-md bg-yellow-900 p-3 mt-3" v-if="inputs.type.id == 'telegram'">
                    <div class="flex items-center">
                        <div class="shrink-0">
                            <ui-icon name="warning" class="text-yellow-200 text-lg"></ui-icon>
                        </div>
                        <div class="ml-3 flex-1 text-sm text-yellow-100 leading-snug">
                            Private Telegram groups requiring an invite link are not supported at this time.
                        </div>
                    </div>
                </div>

                <div class="flex justify-center space-x-4 mt-8">
                    <ui-button color="green" size="lg" type="submit" :disabled="submitting || ! inputs.value">
                        Next
                    </ui-button>
                </div>
            </template>

            <template v-if="inputs.step == 'error' && errors.value == 'resource-limit-reached'">
                <div class="flex flex-col items-center text-center py-6">
                    <ui-icon name="error" class="text-3xl text-red-400"></ui-icon>

                    <h1 class="font-semibold text-lg mt-4">
                        You have reached the maximum limit of monitored Targets.
                    </h1>

                    <div class="mt-4">
                        Please disable monitoring on some of your targets or contact support for more options.
                    </div>

                    <div class="mt-4">
                        <ui-button color="green" size="lg" :action="close">Done</ui-button>
                    </div>
                </div>
            </template>

            <template v-if="inputs.step == 'done'">
                <div class="flex flex-col items-center text-center py-6">
                    <ui-icon name="check-circle" class="text-4xl text-green-400"></ui-icon>

                    <h1 class="font-semibold text-lg mt-4">
                        Your target is now being added.
                    </h1>

                    <div class="mt-4">
                        This might take a few minutes. You can continue using the application.<br>You will receive a notification when the Target is added.
                    </div>

                    <div class="mt-8">
                        <a href="#" class="text-gerulata-green-100 hover:text-gerulata-green-300" size="lg" @click.prevent="reset">
                            <ui-icon name="plus-circle"></ui-icon>
                            Add another Target
                        </a>
                    </div>

                    <div class="mt-4">
                        <ui-button color="green" size="lg" :action="close">Done</ui-button>
                    </div>
                </div>
            </template>
        </ui-form>
    </ui-modal>
</template>

<script>
import useMyTargetListsStore from '@/stores/me/target-lists'
import useDataProvidersModalsAddSource from '@/stores/management/data-providers/modals/add-source'

import { textSearch } from '@/helpers'

import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

export default {
    computed: {
        ...mapStores(useDataProvidersModalsAddSource),
        ...mapState(useDataProvidersModalsAddSource, [ 'availableTypes', 'inputs', 'step' ]),
        ...mapWritableState(useDataProvidersModalsAddSource, [ 'showListsInput' ]),
        ...mapState(useMyTargetListsStore, {
            areListsInitialized: 'initialized',
            lists(store) {
                return useDataProvidersModalsAddSource().inputs.listsSearch
                    ? textSearch(useDataProvidersModalsAddSource().inputs.listsSearch, store.items, t => t.name)
                    : store.items
            }
        }),

        selectedList() {
            return this.inputs.lists.length == 1 ? useMyTargetListsStore().find(this.inputs.lists[0]) : null
        }
    },

    methods: {
        ...mapActions(useDataProvidersModalsAddSource, [ 'reset', 'close' ]),

        selectType(inputs, type) {
            inputs.type = type
            inputs.step = 'url'

            this.$nextTick(() => this.$refs.valueField.focus())
        },

        toggleList(inputs, listId) {
            if (inputs.lists.includes(listId)) {
                inputs.lists = inputs.lists.filter(v => v != listId)
            } else {
                inputs.lists.push(listId)
            }
        }
    }
}
</script>
