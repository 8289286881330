<template>
    <div v-for="item, i in requests" :key="i" class="p-3">
        <a href="#" @click.prevent="toggleActiveMonitoringRequest(item)" class="flex flex-grow items-center justify-between">
            <div class="flex items-center">
                <p class="w-28 text-xs text-gray-400">{{ $dateTime(item.createdAt) }}</p>
                <ui-icon class="mr-1 text-xl" :name="getPlatformInfo(item.platform).icon" v-tooltip="getPlatformInfo(item.platform).name"></ui-icon>
                <p class="font-medium">{{ item.url }}</p>
            </div>
            <div class="flex items-center space-x-3">
                <div v-if="item.state === 'failed'" class="rounded border text-sm font-medium text-red-300 border-gray-500 px-1.5 py-0.5">
                    Failed
                </div>
                <div v-if="item.state === 'added'" class="rounded border text-sm font-medium text-green-300 border-gray-500 px-1.5 py-0.5">
                    Added
                </div>
                <div v-if="item.state === 'requested'" class="rounded border text-sm font-medium text-yellow-300 border-gray-500 px-1.5 py-0.5">
                    Requested
                </div>
            </div>
        </a>
        <div class="p-3" v-if="activeMonitoringRequest && activeMonitoringRequest.id === item.id">
            <div v-for="target in item.sources" :key="target.id" class="flex items-center mt-1.5">
                <div class="shrink-0 relative mr-3">
                    <img class="rounded-full w-8 h-8 shadow" :src="target.avatarUrl" alt="">
                    <ui-icon :name="`badges.target-${target.type}`" v-tooltip="targetTypeToHuman(target.type)" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-gray-600 rounded-full" :class="{ 'grayscale': ! target.monitored }"></ui-icon>
                </div>

                <div class="flex-1 min-w-0 px-3">
                    <p v-tooltip="target.title" class="font-semibold truncate leading-tight mb-1.5">{{ target.title }}</p>

                    <div class="flex items-center text-xs leading-tight truncate">
                        <p v-if="target.verified" class="h-4 w-4 rounded-full bg-blue-600 text-white flex items-center justify-center mr-1.5">
                            <ui-icon name="check" class="text-[9px]"></ui-icon>
                        </p>
                        <div>
                            <span>{{ targetTypeToHuman(target.type) }}</span>
                            <span v-if="target.username" v-tooltip="target.username"> · @{{target.username}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-for="target in item.groups" :key="target.id" class="flex items-center mt-1.5">
                <div class="shrink-0 relative mr-3">
                    <img class="rounded-full w-8 h-8 shadow" :src="target.avatarUrl" alt="">
                    <ui-icon :name="`badges.target-${target.type}`" v-tooltip="targetTypeToHuman(target.type)" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-white rounded-full" :class="{ 'grayscale': ! target.monitored }"></ui-icon>
                </div>

                <div class="flex-1 min-w-0 px-3">
                    <p v-tooltip="target.title" class="font-semibold truncate leading-tight mb-1.5">{{ target.title }}</p>

                    <div class="flex items-center text-xs leading-tight truncate">
                        <p v-if="target.verified" class="h-4 w-4 rounded-full bg-blue-400 text-white flex items-center justify-center mr-1.5">
                            <ui-icon name="check" class="text-[9px]"></ui-icon>
                        </p>
                        <div>
                            <span>{{ targetTypeToHuman(target.type) }}</span>
                            <span v-if="target.username" v-tooltip="target.username"> · @{{target.username}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useMyMonitoringRequestsStore from '@/stores/me/monitoring-requests'
import useSourcesSourceTypesStore from '@/stores/sources/source-types'

import { mapActions, mapState } from 'pinia'

export default {
    props: [ 'requests' ],
    
    data: () => ({
        platforms: [
            { name: 'Facebook Page', id: 'facebook', icon: 'badges.target-facebook-page' },
            { name: 'Facebook Group', id: 'facebook-group', icon: 'badges.target-facebook-group' },
            { name: 'Instagram Profile', id: 'instagram-profile', icon: 'badges.target-instagram-profile' },
            { name: 'Telegram Channel or Group', id: 'telegram', icon: 'badges.target-telegram-channel' },
            { name: 'Twitter User', id: 'twitter', icon: 'badges.target-twitter-user' },
            { name: 'VKontakte User, Page or Community', id: 'vkontakte', icon: 'badges.target-vkontakte-community' },
            { name: 'Web Feed', id: 'web-feeds', icon: 'badges.target-web-feed' },
            { name: 'Youtube Channel', id: 'youtube', icon: 'badges.target-youtube-channel' }
        ]
    }),
    
    computed: {
        ...mapState(useMyMonitoringRequestsStore, { activeMonitoringRequest: 'activeItem' }),
    },
    
    methods: {
        getPlatformInfo(platform) {
            return this.platforms.find(p => p.id === platform) || {}
        },

        ...mapActions(useSourcesSourceTypesStore, { 'targetTypeToHuman': 'typeToHuman' }),
        ...mapActions(useMyMonitoringRequestsStore, { toggleActiveMonitoringRequest: 'toggleActiveItem' }),
    }
}
</script>