import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'

import api from '@/api'

import { defineStore } from 'pinia'
//import useTargetsModalsListDetachConfirmationStore from '@/stores/targets/modals/list-detach-confirmation'

export const useMyTargetListsStore = defineStore({
    id: 'target-lists',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me lists').get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        async all() {
            await this.load()
            return this.items
        },

        find(id) {
            return this.items.find(sl => sl.id == id)
        },

        async update(list, data) {
            await api.route('me lists update', list.id)
                .formData({ _method: 'put', ...data })
                .post()
                .res()

            return this.reload()
        },

        async delete(list, force = false) {
            await api.route('me lists delete', list.id)
                .formData({ _method: 'delete', force: force ? 1 : 0 })
                .post()
                .forbidden(async (res) => {
                    if (res.json.error == 'has-dependents') {
                        return useDeleteConfirmationModal().open('Target List', res.json.dependents)
                            .then(() => this.delete(list, true))
                    }
                })
                .res()

            return this.reload()
        },

        async safeDelete(list) {
            try {
                return await this.delete(list)
            } catch (e) {}
        },

        including(target) {
            let lists = this.items.filter(list => ! list.curated)

            return target.lists ? lists.filter(l => this.isIncludedIn(l, target)) : []
        },

        notIncluding(target) {
            let lists = this.items.filter(list => ! list.curated)

            return target.lists ? lists.filter(l => ! this.isIncludedIn(l, target)) : []
        },

        isIncludedIn(list, target) {
            return target.lists.find(l => l.id == list.id)
        },

        async addToList(list, target) {
            return api.route(`me lists ${target.masterType}s store`, { id: list.id, targetId: target.id }).post().res(() => {
                return api.route(`monitor targets details`, { family: `${target.masterType}s`, id: target.id }).get().json(res => {
                    target.lists = res.data.lists
                })
            })
        },

        async removeFromList(list, target, force = false) {
//            if (! force && ! target.lists.some(l => l.id === list.id)) {
//                await useTargetsModalsListDetachConfirmationStore().open()
//                    .then(async () => {
//                        await this.removeFromList(list, target, true)
//                    })
//            }

            return api.route(`me lists ${target.masterType}s delete`, { id: list.id, targetId: target.id }).delete().res(() => {
                return api.route(`monitor targets details`, { family: `${target.masterType}s`, id: target.id }).get().json(res => {
                    target.lists = res.data.lists
                })
            })
        },

        subscribeToList(list) {
            if (! list.curated) return

            return api.route('me lists subscription store', { id: list.id }).post().res(() => {
                this.reload()
            })
        },

        unsubscribeFromList(list) {
            if (! list.curated) return

            return api.route('me lists subscription store', { id: list.id }).delete().res(() => {
                this.reload()
            })
        },

        isCurated(list) {
            return list.curated
        },

        isSubscribedByParent(list) {
            return this.items.find(l => l.parentList?.id === list.id)
        },

        isSubscribedByChild(list) {
            return !! list.parentList
        }
    }
})

export default useMyTargetListsStore
