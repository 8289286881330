<template>
    <div class="w-full max-w-2xl pointer-events-auto" v-if="geoFeatureBeingCreated || selectedMapFeature">
        <div class="rounded-md bg-gradient-to-b from-gray-600/70 to-gray-700/70 backdrop-blur border border-gray-500/70 px-4 py-3 shadow-xl">
            <div v-if="selectedMapFeature" class="flex items-center justify-between">
                <template v-if="selectedMapFeature.properties.id">
                    <span>{{selectedMapFeature.properties.name}}</span>
                    <div class="space-x-2">
                        <a href="#" class="text-gray-300 px-2" @click="hideSelectedMapFeature()">Remove</a>
                    </div>
                </template>
                <template v-else>
                    <span>Untitled Feature</span>
                    <div class="space-x-2">
                        <ui-button size="xs" color="green" @click="saveSelectedMapFeature">Save</ui-button>
                        <a href="#" class="text-gray-300 px-2" @click="hideSelectedMapFeature()">Remove</a>
                    </div>
                </template>
            </div>
            <div v-else-if="geoFeatureBeingCreated == 'polygon'" class="flex items-center justify-between text-gray-200">
                <span>Click on the map to create the shape, click on the first point to finish the shape.</span>
                <div class="space-x-2">
                    <a href="#" class="text-gray-300 px-2" @click="stopCreatingGeoFeature">Cancel</a>
                </div>
            </div>
            <div v-else-if="geoFeatureBeingCreated == 'circle'" class="flex items-center justify-between text-gray-200">
                <span>Click and drag on the map to select the radius.</span>
                <div class="space-x-2">
                    <a href="#" class="text-gray-300 px-2" @click="stopCreatingGeoFeature">Cancel</a>
                </div>
            </div>
            <div v-else-if="geoFeatureBeingCreated == 'point'" class="flex items-center justify-between text-gray-200">
                <span>Click on the map to place the point.</span>
                <div class="space-x-2">
                    <a href="#" class="text-gray-300 px-2" @click="stopCreatingGeoFeature">Cancel</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useOverviewStore from '@/stores/overview/overview'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useOverviewStore, [  'geoFeatureBeingCreated', 'selectedMapFeature' ]),
    },

    methods: {
        ...mapActions(useOverviewStore, [ 'hideSelectedMapFeature', 'saveSelectedMapFeature', 'stopCreatingGeoFeature' ])
    }
}
</script>
