<template>
    <ui-modal name="data-providers-custom-reports-modal" title="Monitoring Requests" height="80%" max-width="1024px">
        <div>
            <div v-for="i in 25" :key="i" class="h-12 px-6 py-1.5 flex items-center space-x-3 even:bg-white/5">
                <div class="flex flex-grow items-center justify-between">
                    <div class="flex items-center">
                        <p class="w-28 text-xs text-gray-300 mr-3">2022-12-14 13:30:00</p>
                        <div class="p-0.5 w-4 h-4 bg-gray-300 rounded-full justify-center items-center mr-1.5 shadow" :style="{ backgroundColor: category.color }">
                            <img :src="category.icon" class="mx-auto opacity-80">
                        </div>
                        <p>Bottom of MacBook Separating or Bulging: Is It a Swollen Battery?...</p>
                    </div>

                    <a href="#" class="text-xl text-cool-400">
                        <ui-icon name="chevron-right"></ui-icon>
                    </a>
                </div>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import useEventCategoriesStore from '@/stores/me/event-categories'

export default {
    computed: {
        category() { return useEventCategoriesStore().find('politics.general') }
    }
}
</script>
