<template>
    <nav class="space-y-2 py-4">
        <a @click.prevent="toggleSidebar('perspectives')" href="#" class="flex items-center justify-center w-full h-9 mx-auto text-2xl transition ease-in-out duration-150 hover:text-white" :class="{'text-gerulata-green-100': sidebar == 'perspectives'}" v-tooltip.right="'Perspectives'">
            <ui-icon name="hexagon"></ui-icon>
        </a>

        <a @click.prevent="toggleSidebar('geo-features')" href="#" class="flex items-center justify-center w-full h-9 mx-auto text-2xl transition ease-in-out duration-150 hover:text-white" :class="{'text-gerulata-green-100': sidebar == 'geo-features'}" v-tooltip.right="'Geography'">
            <ui-icon name="map"></ui-icon>
        </a>

        <a @click.prevent="toggleSidebar('notifications')" href="#" class="flex items-center justify-center w-full h-9 mx-auto text-2xl transition ease-in-out duration-150 hover:text-white relative" :class="{'text-gerulata-green-100': sidebar == 'notifications'}" v-tooltip.right="'Notifications'">
            <ui-icon name="bell"></ui-icon>
            <span class="absolute -right-1.5 block w-2 h-2 rounded-full bg-red-500" aria-hidden="true" v-if="unreadNotificationsCount"></span>
        </a>

        <a @click.prevent="toggleSidebar('moderation')" href="#" class="flex items-center justify-center w-full h-9 mx-auto text-2xl transition ease-in-out duration-150 hover:text-white relative" :class="{'text-gerulata-green-100': sidebar == 'moderation'}" v-tooltip.right="'Moderation'">
            <ui-icon name="navigation.moderated-events"></ui-icon>
            <span class="absolute -right-1.5 block w-2 h-2 rounded-full bg-red-500" aria-hidden="true" v-if="pendingModeratedEventsCount"></span>
        </a>
    </nav>
</template>

<script>
import useAppStore from '@/stores/app'
import useManagementModeratedEventsStore from '@/stores/management/moderated-events/moderated-events'
import useMyNotificationsStore from '@/stores/me/notifications'
import useStreamStore from '@/stores/overview/stream'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useAppStore, [ 'sidebar' ]),
        ...mapState(useManagementModeratedEventsStore, [ 'pendingModeratedEventsCount' ]),
        ...mapState(useMyNotificationsStore, [ 'unreadNotificationsCount' ]),
        ...mapState(useStreamStore, [ 'perspective' ])
    },

    methods: {
        ...mapActions(useAppStore, [ 'toggleSidebar' ])
    }
}
</script>
