import useSettingsSubscriptionsStore from "@/stores/settings/subscriptions"

import { defineStore } from 'pinia'

export const useSettingsSubscriptionsPage = defineStore({
    id: 'settingsSubscriptionsPage',

    getters: {
        title: () => 'Account | Subscriptions'
    },

    actions: {
        beforeEnter() {
            useSettingsSubscriptionsStore().load()
        }
    }
})

export default useSettingsSubscriptionsPage
