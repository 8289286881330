import useTaggingStore from '@/stores/me/tagging'

import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useMyModalsTagsManageStore = defineStore({
    id: 'myModalsTagsManage',

    state: () => ({
        selectedGroup: null,
        mode: 'index'
    }),

    actions: {
        open() {
            this.reset()

            useModal().show('tags-manage')
        },

        reset(groupId) {
            this.$reset()

            this.selectedGroup = groupId
                ? useTaggingStore().findGroup(groupId)
                : [ ...useTaggingStore().tagGroups ].sort((a, b) => a.name.localeCompare(b.name))[0]
        }
    }
})

export default useMyModalsTagsManageStore
