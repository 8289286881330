<template>
    <div class="mx-auto w-full max-w-4xl bg-gray-700 border border-gray-600 rounded-lg">
        <div class="px-6 py-5">
            <h1 class="font-semibold"><ui-icon name="bank-note" class="mr-1"></ui-icon> Billing</h1>
            <p class="mt-2 text-sm text-gray-300">Here you can manage and view your invoices.</p>
        </div>

        <div class="h-72 flex flex-col items-center justify-center text-gray-300 space-y-3">
            <ui-icon name="frown" class="text-4xl text-gray-400"></ui-icon>
            <p>Not available in the Beta.</p>
        </div>
    </div>
</template>

<script>
export default {
}
</script>