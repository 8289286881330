<template>
    <a :href="$router.resolve(to)" class="block px-6 py-3 hover:bg-gray-600" :class="{ 'bg-gray-600': focused }" @click.prevent>
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <ui-avatar type="target-list" :item="result" class="w-8 h-8 drop-shadow-sm shrink-0"></ui-avatar>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="flex items-center leading-5 font-medium text-gray-200">
                    <div class="truncate">{{result.title}}</div>
                </div>

                <div class="text-xs leading-4 text-gray-300 truncate">
                    Target List
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-400"></ui-icon>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: [ 'focused', 'result' ],

    computed: {
        to() {
            return { name: 'events' }
            //            return { name: 'targets.my-lists.list', params: { listId: this.result.id, type: 'sources' } }
        }
    },

    methods: {
        open() {
//            this.$router.push(this.to)
        }
    }
}
</script>
