<template>
    <ui-modal name="settings-workspace-members" title="Workspace Members" width="100%" height="auto" :max-width="480" @closed="cancel">
        <div class="min-h-0 p-4 overflow-y-auto space-y-3" v-if="! isAddingUser">    
            <div class="rounded-md bg-gray-800 shadow hover:shadow-lg" :key="user.id" v-for="user in inputs.workspace?.users">
                <div class="flex items-center relative p-4 pl-5 pb-3">
                    <div class="shrink-0 relative">
                        <img class="rounded-full w-8 h-8 shadow" :src="user.avatarUrl">
                    </div>
        
                    <div class="flex-1 min-w-0 px-4">
                        <h1 class="text-lg text-gray-200 font-semibold truncate leading-tight">
                            <span v-tooltip="user.name">{{user.name}}</span>
                        </h1>
                        <div class="text-gray-300 text-xs leading-tight flex items-center">
                            {{user.email}}
                        </div>
                    </div>
        
                    <div class="flex items-center space-x-0.5 shrink-0">
                        <span class="bg-blue-500 text-white rounded py-0.5 px-1 text-2xs uppercase" v-if="user.role == 'owner'">
                            Owner
                        </span>
        
                        <button class="inline-flex items-center justify-center text-gray-300 hover:text-gray-100 focus:outline-none focus:text-gray-100 rounded-full transition w-7 h-7 text-sm" v-tooltip="'Remove'" @click="removeUser(user)" v-if="user.role != 'owner'">
                            <ui-icon name="x"></ui-icon>
                        </button>
                    </div>
                </div>
            </div>        
            
    
            <div class="text-center text-sm pt-2">
                <a href="#" class="text-gerulata-green-100 hover:text-gerulata-green-300" @click.prevent="addUser">
                    <ui-icon name="plus-circle"></ui-icon>
                    Add a User
                </a>
            </div>
        </div>
        <ui-form :store="settingsUsersModalsWorkspaceMembersStore" v-slot="{ inputs, submitting, errors }" v-else>
            <div>
                <label class="block text-sm text-gray-200 font-medium mb-1.5">
                    User
                </label>
                <div>
                    <ui-select :get-option-label="option => option.name" :options="users" v-model="inputs.user">
                        <template v-slot:option="option">
                            <div class="flex items-center py-1">
                                <div class="w-6 h-6 rounded-full shadow-sm mr-3">
                                    <img :src="option.avatarUrl" class="w-full h-full object-cover rounded-full">
                                </div>
                                {{ option.name }}
                            </div>
                        </template>
                    </ui-select>
                </div>
            </div>

            <div class="text-center mt-6">
                <ui-button size="lg" color="green" type="submit" :disabled="submitting || ! inputs.user.id">Add</ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useSettingsUsersStore from '@/stores/settings/users/users'
import useWorkspaceMembersModalStore from '@/stores/settings/users/modals/workspace-members'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapState(useSettingsUsersStore, [ 'users' ]),
        ...mapStores(useWorkspaceMembersModalStore),
        ...mapState(useWorkspaceMembersModalStore, [ 'inputs', 'isAddingUser' ])
    },

    methods: {
        ...mapActions(useWorkspaceMembersModalStore, [ 'addUser', 'cancel', 'removeUser' ])
    }
}
</script>
