import useMyModalsTagsManageStore from '@/stores/me/modals/tags-manage'
import useTaggingStore from '@/stores/me/tagging'
import { defineForm } from '@/stores/reusable/form'

import api from '@/api'

export const useMyModalsTagsManageGroupEditStore = defineForm({
    id: 'myModalsTagsManageGroupEdit',

    inputs: () => ({
        group: {},
        name: null
    }),

    submitRequest() {
        return api.route(this.inputs.group?.id ? 'me tags groups update' : 'me tags groups store', { id: this.inputs.group.id })
            .formData({
                _method: this.inputs.group.id ? 'put' : 'post',
                name: this.inputs.name
            })
            .post()
    },

    onResponse(res) {
        useTaggingStore().reload().then(() => useMyModalsTagsManageStore().reset(res.data.id))
    },

    actions: {
        show(group) {
            this.reset()

            this.inputs.group = group || {}
            this.inputs.name = group?.name

            useMyModalsTagsManageStore().mode = 'edit-group'
        },

        cancel() {
            useMyModalsTagsManageStore().reset()
        },

        delete() {
            return api.route('me tags groups delete', { id: this.inputs.group.id })
                .delete()
                .res(res => {
                    useTaggingStore().reload().then(() => useMyModalsTagsManageStore().reset())
                })
        }
    }
})

export default useMyModalsTagsManageGroupEditStore
