import { defineStore } from 'pinia'
import { hasFeatureTo } from '@/helpers/licenses'

export const useSourceTypesStore = defineStore({
    id: 'sourceTypes',

    getters: {
        types: store => ([
            {
                type: 'facebook-page',
                name: 'Facebook Pages',
                family: 'sources',
                icon: 'badges.target-facebook-page'
            },
            {
                type: 'facebook-user',
                name: 'Facebook Users',
                family: 'sources',
                icon: 'badges.target-facebook-user'
            },
            {
                type: 'facebook-group',
                name: 'Facebook Groups',
                family: 'groups',
                icon: 'badges.target-facebook-group'
            },
            {
                type: 'instagram-profile',
                name: 'Instagram Profile',
                family: 'sources',
                icon: 'badges.target-instagram-profile'
            },
            {
                type: 'telegram-channel',
                name: 'Telegram Channels',
                family: 'sources',
                icon: 'badges.target-telegram-channel'
            },
            {
                type: 'telegram-group',
                name: 'Telegram Groups',
                family: 'groups',
                icon: 'badges.target-telegram-group'
            },
            {
                type: 'telegram-user',
                name: 'Telegram Users',
                family: 'sources',
                icon: 'badges.target-telegram-user'
            },
            {
                type: 'tiktok-user',
                name: 'TikTok Users',
                family: 'sources',
                icon: 'badges.target-tiktok-user'
            },
            {
                type: 'twitter-user',
                name: 'Twitter Users',
                family: 'sources',
                icon: 'badges.target-twitter-user'
            },
            {
                type: 'vkontakte-community',
                name: 'VKontakte Communities',
                family: 'sources',
                icon: 'badges.target-vkontakte-community'
            },
            {
                type: 'vkontakte-user',
                name: 'VKontakte Users',
                family: 'sources',
                icon: 'badges.target-vkontakte-user'
            },
            {
                type: 'web-feed',
                name: 'Web Feeds',
                family: 'sources',
                icon: 'badges.target-web-feed'
            },
            {
                type: 'web-site',
                name: 'Web Sites',
                family: 'sources',
                icon: 'badges.target-web-site'
            },
            {
                type: 'youtube-channel',
                name: 'Youtube Channels',
                family: 'sources',
                icon: 'badges.target-youtube-channel'
            }
        ]),

        availableTypes: store => store.types.filter(t => hasFeatureTo([`access-targets-${t.type}`]))
    },

    actions: {
        typeToHuman(type) {
            return this.types.find(t => t.type == type)?.name ?? 'Unknown Type'
        }
    }
})

export default useSourceTypesStore
