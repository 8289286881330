<template>
    <ui-dropdown>
        <template v-slot:trigger>
            <div class="relative cursor-pointer">
                <ui-avatar :family="family" :name="inputs.name" :color="inputs.avatar?.color" :icon="inputs.avatar?.icon" :image="inputs.avatar?.imageUrl" class="w-16 h-16"></ui-avatar>
                <div class="absolute flex items-center justify-center -bottom-1 -right-1 rounded-full bg-gray-300 bg-opacity-80 h-7 w-7 drop-shadow-md">
                    <ui-icon name="edit" class="font-medium"></ui-icon>
                </div>
            </div>
        </template>
        
        <template v-slot:content>
            <div class="py-2">
                <div class="flex justify-center" v-for="colors, i in colors" :key="i">
                    <div class="hover:bg-gerulata-green-500 flex rounded p-1.5" @click="selectColor(color.color)" v-tooltip="color.name" v-for="color in colors" :key="color.color">
                        <div class="inline-block w-3 h-3 rounded-full" :style="{ backgroundColor: color.color }"></div>
                    </div>
                </div>
            </div>

            <ui-dropdown-separator></ui-dropdown-separator>

            <div class="max-h-44 overflow-y-auto px-1 divide-y divide-gray-200">
                <div v-for="emojis, category in emojis" :key="category" class="py-1">
                    <div class="text-2xs font-medium text-gray-600 text-center mb-0.5 cursor-pointer">
                        {{category}}
                    </div>
                    <div class="flex justify-center flex-wrap">
                        <div v-for="emoji, index in emojis" :key="index" @click="selectIcon(emoji)" class="hover:bg-gerulata-green-500 flex rounded px-1 text-3xl cursor-default">
                            {{emoji}}
                        </div>
                    </div>
                </div>
            </div>

            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="upload" @click="() => $refs.file.click()" :spinner="isUploadingImage" keep-open>Upload image...</ui-dropdown-link>
            <input type="file" ref="file" accept="image/*" hidden @change="fileSelected">

            <ui-dropdown-link icon="x" @click="() => clear()">Clear</ui-dropdown-link>
        </template>
    </ui-dropdown>
</template>

<script>
import api from '@/api'
import emojis from '@/support/emojis.json'

/* eslint-disable vue/no-mutating-props */
export default {
    props: [ 'family', 'inputs' ],
    
    data: () => ({
        colors: [
            [
                { color: '#dc2626', name: 'Red' },
                { color: '#db2777', name: 'Pink' },
                { color: '#ea580c', name: 'Orange' },
                { color: '#d97706', name: 'Amber' },
                { color: '#65a30d', name: 'Lime' },
                { color: '#16a34a', name: 'Green' },
                { color: '#059669', name: 'Emerald' }
            ],
            [
                { color: '#0d9488', name: 'Teal' },
                { color: '#0891b2', name: 'Cyan' },
                { color: '#0284c7', name: 'Sky' },
                { color: '#2563eb', name: 'Blue' },
                { color: '#4f46e5', name: 'Indigo' },
                { color: '#7c3aed', name: 'Violet' },
                { color: '#c026d3', name: 'Fuschia' }
            ]
        ],

        emojis,

        isUploadingImage: false
    }),
    
    methods: {
        selectColor(color) {
            this.inputs.avatar.color = color

            this.inputs.avatar.imageId = false
            this.inputs.avatar.imageUrl = null
        },

        selectIcon(icon) {
            this.inputs.avatar.icon = icon

            this.inputs.avatar.imageId = false
            this.inputs.avatar.imageUrl = null
        },

        fileSelected($event) {
            this.isUploadingImage = true

            return api.route('me files store')
                .formData({ file: $event.target.files[0] })
                .post()
                .json(res => {
                    this.inputs.avatar.imageId = res.data.id
                    this.inputs.avatar.imageUrl = res.data.imageUrl

                    this.inputs.avatar.icon = false

                    this.isUploadingImage = false
                })
        },

        clear() {
            this.inputs.avatar.color = false
            this.inputs.avatar.icon = false
            this.inputs.avatar.imageId = false
            this.inputs.avatar.imageUrl = null
        }
    }
}
</script>
