<template>
    <search-filter name="Veracity" :ids="['veracity']" :filters="filters" align="right" @opened="reset">
        <template v-slot:applied>
            <ui-icon name="shield" class="mr-1"></ui-icon>

            <span class="font-medium" v-if="value >= 80">
                High
            </span>
            <span class="font-medium" v-else-if="value >= 50">
                Medium
            </span>
        </template>

        <template v-slot:options>
            <div class="w-64 max-h-80 overflow-y-auto">
                <p class="text-xs px-4 pb-2 text-gray-400">
                    Exclude events with lower than selected veracity.
                </p>
    
                <ui-dropdown-link :selected="localValue == 80" @click="localValue = 80" keep-open>High</ui-dropdown-link>
                <ui-dropdown-link :selected="localValue == 50" @click="localValue = 50" keep-open>Medium</ui-dropdown-link>
                <ui-dropdown-link :selected="! localValue" @click="localValue = 0" keep-open>Low</ui-dropdown-link>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center text-gray-300" @click="filters.remove(['veracity']); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="green" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

export default {
    components: { SearchFilter },

    props: [ 'filters' ],

    data: () => ({
        localValue: 0
    }),

    computed: {
        value() {
            return this.filters.value('veracity') || 0
        }
    },

    methods: {
        reset() {
            this.localValue = this.filters.value('veracity')
        },

        apply() {
            if (this.localValue) {
                this.filters.set('veracity', this.localValue)
            } else {
                this.filters.remove('veracity')
            }
        }
    }
}
</script>
