<template>
	<div class="flex flex-col items-center justify-center h-screen bg-gray-900" v-if="! isInitialized">
		<img src="@/assets/gerulata.svg" class="w-32">
	</div>

	<div class="flex flex-col items-center justify-center h-screen text-lg bg-gray-900" v-else-if="isInMaintenance">
		<img src="@/assets/gerulata.svg" class="w-32">

        <h2 class="mt-10 text-2xl font-bold">
            We'll be back soon!
        </h2>

        <p class="mt-3">
            Looks like we are performing some maintenance at the moment, sorry for the inconvenience!
        </p>

        <p class="mt-3">
            Please try again later, with any questions, please <a class="underline" href="mailto:support@gerulata.com">contact us</a> for further assistance.
        </p>

        <p class="mt-3 italic">
            — Gerulata Team
        </p>
    </div>

	<router-view v-else></router-view>

    <div id="root"></div>
</template>

<script>
import useMyStore from '@/stores/me/my'

import useRouter from '@/router'

import { mapState } from 'pinia'

export default {
	data: () => ({
		isInitialized: false
	}),

	computed: {
		...mapState(useMyStore, [ 'isInMaintenance' ])
	},

	mounted() {
		useRouter().isReady().then(() => this.isInitialized = true)
	}
}
</script>
