<template>
    <ui-modal name="settings-delete-workspace" title="Delete Workspace" width="100%" height="auto" :max-width="480" @closed="cancel">
        <ui-form :store="settingsUsersModalsDeleteWorkspaceStore" v-slot="{ inputs, submitting, errors }">
            <div class="font-medium text-lg text-center">
                Remove the workspace <span class="font-bold">"{{inputs.workspace?.name}}"</span>.
            </div>

            <div class="text-gray-200 text-center mt-4">
                The workspace and all associated content will be removed.
            </div>

            <div class="font-medium text-gray-200 text-center mt-4">
                This action can not be undone.
            </div>

            <div class="text-center mt-6">
                <ui-button size="lg" color="red" type="submit" :disabled="submitting">Remove</ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useDeleteWorkspaceModalStore from '@/stores/settings/users/modals/delete-workspace'

import { mapActions, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useDeleteWorkspaceModalStore)
    },

    methods: {
        ...mapActions(useDeleteWorkspaceModalStore, [ 'cancel', 'reset', 'submit' ])
    }
}
</script>
