<template>
    <div class="mx-auto w-full max-w-4xl bg-gray-700 border border-gray-600 rounded-lg">
        <div class="px-6 py-5">
            <h1 class="font-semibold"><ui-icon name="bell" class="mr-1"></ui-icon> Alerts</h1>
            <p class="mt-2 text-sm text-gray-300">Manage alerts for all perspectives created through your organization.</p>
        </div>

        <div class="flex items-center justify-between mx-4 mb-6">
            <div class="flex items-center space-x-3">
                <div class="w-72">
                    <ui-input name="search" placeholder="Search by name..."></ui-input>
                </div>
                <div class="w-72">
                    <ui-select placeholder="All workspaces..."></ui-select>
                </div>
                <div class="flex items-center">
                    <input type="checkbox" id="moderated" name="moderated" class="mr-1.5" checked>
                    <label for="moderated">Moderated</label>
                </div>
            </div>

            <div class="flex items-center">
                <a href="#" @click.prevent="openEditAlertModal()"
                   class="flex items-center text-gray-200 hover:text-gray-300">
                    <ui-icon name="plus" class="mr-1"></ui-icon>
                    Create
                </a>
            </div>
        </div>

        <ui-async :guards="! isAlertsLoading && ! isPerspectivesLoading && ! isWorkspacesLoading">
            <div v-for="item, i in items" :key="i"
                 class="px-6 py-3 flex items-center space-x-3 even:bg-white/5">
                <div class="flex flex-grow items-center justify-between">
                    <a href="#" @click.prevent="openEditAlertModal(item)" class="text-base">
                        Alert
                        <span class="text-gray-100">{{ item.name }}</span>
                        for perspective
                        <span class="text-gray-100">{{ item.perspective.name }}</span>
                        in workspace
                        <span class="text-gray-100">{{ item.perspective.workspace.name }}</span>
                    </a>
                    <div class="flex items-center space-x-3">
                        <div v-if="item.channels.inApp"
                             class="rounded border text-sm font-medium text-gray-100 border-gray-500 px-1.5 py-0.5">
                            In-App
                        </div>

                        <div v-if="item.channels.email"
                             class="rounded border text-sm font-medium text-gray-100 border-gray-500 px-1.5 py-0.5">
                            Email
                        </div>

                        <div v-if="item.configuration.moderated"
                             class="rounded border text-sm font-medium text-amber-300 border-gray-500 px-1.5 py-0.5">
                            Moderated
                        </div>

                        <a href="#" @click.prevent="openEditAlertModal(item)"
                           class="ml-4 text-xl text-gray-400">
                            <ui-icon name="chevron-right"></ui-icon>
                        </a>
                    </div>
                </div>
            </div>
        </ui-async>

        <Teleport to="#root">
            <alert-edit></alert-edit>
        </Teleport>
    </div>
</template>

<script>
import useManagementAlertsStore from '@/stores/management/alerts/alerts'
import useManagementModalsAlertEditFormStore from '@/stores/management/modals/alert-edit'
import useManagementPerspectivesStore from '@/stores/management/perspectives'
import useManagementWorkspacesStore from '@/stores/management/workspaces'

import AlertEdit from '@/components/management/modals/alert-edit'

import {mapActions, mapState} from 'pinia'

export default {
    components: {AlertEdit},

    computed: {
        ...mapState(useManagementAlertsStore, {items: 'items', isAlertsLoading: 'isLoading'}),
        ...mapState(useManagementPerspectivesStore, {isPerspectivesLoading: 'isLoading'}),
        ...mapState(useManagementWorkspacesStore, {isWorkspacesLoading: 'isLoading'})
    },

    methods: {
        ...mapActions(useManagementModalsAlertEditFormStore, {openEditAlertModal: 'open'})
    }
}
</script>
