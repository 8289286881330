import { createApp } from 'vue'
import Main from './Main.vue'

let app = createApp(Main)

import { createPinia } from 'pinia'
import { vfmPlugin } from 'vue-final-modal'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import FloatingVue from 'floating-vue'
import VueClickAway from 'vue3-click-away'

app.use(createPinia())
app.use(vfmPlugin({ key: '$modal' }))
app.use(FloatingVue, { defaultDelay: 200, themes: { 'link': { $extend: 'menu', $resetCss: true } } })
app.use(VueClickAway)

import { setupRouter } from '@/router'

let router = setupRouter(app)

import JsonTree from 'vue-json-tree'
import Modal from './Modal'

app.component('json-tree', JsonTree)
app.component('modal', Modal)

import useMyStore from '@/stores/me/my'
import useMyQuickSearchStore from '@/stores/me/quick-search'
import useSupportChatStore from '@/stores/support/chat'
import useSupportDebugStore from '@/stores/support/debug'
import { mapActions, mapState } from 'pinia'

import { date, dateTime, zonedToUTC, UTCToZoned, relativeTime, distanceTime } from '@/helpers/datetime'

app.mixin({
    props: [ 'store' ],
    computed: {
        $my() { return useMyStore() },
        $page() { return router.currentPage },

        ...mapState(useSupportChatStore, { unreadSupportMessagesCount: 'unreadMessagesCount' }),
    },
    methods: {
        $UTCToZoned: UTCToZoned,
        $zonedToUTC: zonedToUTC,
        $date: date,
        $dateTime: dateTime,
        $relativeTime: relativeTime,
        $distanceTime: distanceTime,
        $hasFeatureTo(features) { return this.hasFeatureTo(...features) },

        ...mapActions(useMyStore, [ 'can', 'canAny', 'hasFeatureTo' ]),
        ...mapActions(useMyQuickSearchStore, { showQuickSearch: 'show' }),
        ...mapActions(useSupportChatStore, { toggleSupportChatOverlay: 'toggle' }),
        ...mapActions(useSupportDebugStore, { showDebug: 'show' })
    }
})

import registerComponents from '@/components'
import registerHelpers from '@/helpers'
import setupAnalytics from '@/analytics'
import setupShortcuts from '@/shortcuts'

registerComponents(app)
registerHelpers(app)

setupAnalytics(app)
setupShortcuts()

if (process.env.VUE_APP_BUGSNAG_API_KEY) {
    Bugsnag.start({
        appVersion: process.env.VUE_APP_RELEASE_CODE,
        apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
        releaseStage: process.env.VUE_APP_BUGSNAG_ENV,
        plugins: [ new BugsnagPluginVue() ],
        onError: event => {
            let ignoredErrors = [ 'SessionLostError' ]
            if (ignoredErrors.includes(event.originalError)) return false

            event.setUser(useMyStore().user?.id, useMyStore().user?.email, useMyStore().user?.name)
        }
    })
    app.use(Bugsnag.getPlugin('vue'))
}

import './assets/styles/tailwind.css'
import './assets/styles/fonts.css'
import './assets/styles/app.css'
import './assets/styles/map.css'

import 'floating-vue/dist/style.css'
import './assets/styles/tooltips.css'
import 'vue-select/dist/vue-select.css'
import './assets/styles/vue-select.css'

app.mount('#app')

useMyStore().initialize()
