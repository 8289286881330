<template>
    <clip-loader name="clip" :color="color" size="1em" class="flex justify-center" v-if="type == 'clip'"></clip-loader>
    <pulse-loader name="spiral" :color="color" size="0.65em" class="flex justify-center" v-else></pulse-loader>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
    components: { ClipLoader, PulseLoader },

    props: { 'color': { default: '#A9EE8A' }, 'type': { default: 'spiral' } }
}
</script>
