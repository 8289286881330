<template>
    <ui-modal name="auth-session-lost" unstyled>
        <div class="bg-gray-700 border border-gray-600 p-12 flex flex-col items-center">
            <ui-icon name="lock" class="text-4xl text-gray-200"></ui-icon>
            <div class="text-center mt-4">
                <h1 class="text-lg font-semibold">You've been logged out.</h1>
                <p>Please log in again to continue using the app.</p>
            </div>
            <ui-button color="green" @click="submit" class="mt-5">Continue</ui-button>
        </div>
    </ui-modal>
</template>

<script>
export default {
    methods: {
        submit() { window.location.reload() }
    }
}
</script>
