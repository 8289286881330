<template>
    <a :href="source.link" target="_blank" rel="noreferrer" v-for="source in sources" :key="source.id" class="flex items-start space-x-5 leading-tight cursor-pointer mb-2">
        <div>
<!--            <img v-if="source.images.length" :src="source.images[0].src" class="block w-14 h-14 rounded-full bg-gray-400 object-contain" />-->
<!--            <img v-else :src="source.avatarUrl" class="block w-14 h-14 rounded-full bg-gray-400 object-contain" />-->

            <ui-avatar :item="source" class="block w-14 h-14 rounded-full bg-gray-400 object-contain"></ui-avatar>
        </div>

        <div class="flex-1 mt-2.5">
            <div class="float-right text-sm text-gray-300 ml-1">
                High Credibility
            </div>

            <div class="font-semibold text-gray-100">{{ source.title }}</div>

            <div v-if="source.type" class="flex items-center space-x-1 text-sm text-gray-300 float-left">
                <span class="capitalize">{{ platforms[source.type]?.label || source.type }}</span>
            </div>
        </div>
    </a>
</template>

<script>
import useDetailsStore from '@/stores/overview/details'

import { mapState } from 'pinia'

export default {
    props: [ 'sources' ],

    computed: {
        ...mapState(useDetailsStore, [ 'platforms' ])
    }
}
</script>
