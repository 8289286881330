<template>
    <div class="py-4 px-5 space-y-4">
        <ui-async :guards="reports.data">
            <stream-list :reports="reports?.data"></stream-list>

            <ui-infinite-loading @infinite="loadMoreReports($event)"></ui-infinite-loading>
        </ui-async>
    </div>
</template>

<script>
import StreamList from '@/components/overview/details/partials/stream-list'

import useDetailsStore from '@/stores/overview/details'

import { mapState } from 'pinia'

export default {
    components: {
        StreamList
    },

    computed: {
        ...mapState(useDetailsStore, [ 'reports', 'loadMoreReports' ])
    }
}
</script>