import useMyStore from '@/stores/me/my'

import api from '@/api'

import debounce from 'just-debounce-it'
import { defineStore } from 'pinia'

export const useMyQuickSearchStore = defineStore({
    id: 'myQuickSearch',

    state: () => ({
        isShown: false,
        inMaintenance: false,

        search: '',

        results: [],
        resultsPending: false,
        resultsPromise: null,

        hasFilterableFamilies: true,
        hasFilterableWorkspaces: true,
        filteredFamilies: [],
        workspace: null,
        families: [],
        limit: 10,
        perspectiveStarters: true,
        onSelect: null,
        onHide: null,
        initialResults: []
    }),

    getters: {
        filteredResults(state) {
            return state.filteredFamilies.length ? this.results.filter(r => state.filteredFamilies.includes(r.family)) : this.results
        }
    },

    actions: {
        initialize() {
        },

        show(options) {
            this.$reset()

            this.isShown = true

            this.hasFilterableFamilies = options?.hasFilterableFamilies || false
            this.hasFilterableWorkspaces = options?.hasFilterableWorkspaces || false
            this.workspace = options?.workspace ?? useMyStore().currentWorkspace
            this.families = options?.families || [ 'app', 'geoLayers', 'geoFeatures', 'perspectives' ]
            this.limit = options?.limit || 10
            this.perspectiveStarters = options?.perspectiveStarters ?? true
            this.onSelect = options?.onSelect
            this.onHide = options?.onHide
            this.results = this.initialResults = options?.initialResults || []
        },

        hide() {
            if (this.onHide) this.onHide()

            this.isShown = false
        },

        loadDebounced: debounce(function () { this.load() }, 100),

        async load() {
            if (this.search.length == 0) this.results = this.initialResults
            if (this.search.length < 3) return

            if (this.resultsPromise) {
                this.abortGeneralLoad?.abort()
                this.abortWorkspaceLoad?.abort()
            }

            this.resultsPending = true
            this.inMaintenance = false

            let generalSearch = Promise.resolve()
            let userSearch = Promise.resolve()

            if (this.shouldSearch([ 'app' ])) {
                generalSearch = api.url(`${process.env.VUE_APP_QUICK_SEARCH_URL}/multi_search`, true)
                    .signal(this.abortGeneralLoad = new AbortController())
                    .headers({ 'X-TYPESENSE-API-KEY': useMyStore().currentWorkspace.keys.quickSearchGeneral })
                    .options({ credentials: 'same-origin' })
                    .json({
                        searches: [
                            this.shouldSearch('app') ? {
                                collection: 'app',
                                q: this.search,
                                query_by: 'title',
                                per_page: this.limit
                            } : null
                        ].filter(v => v)
                    })
                    .post()
                    .error(503, () => this.inMaintenance = true)
                    .json()
            }

            if (this.shouldSearch([ 'geoFeatures', 'geoLayers', 'perspectives' ])) {
                let query = { 'query_by': 'title' }

//                if (this.workspace) {
//                    query.filter_by = `workspaceId:[${this.workspace.id}]`
//                }

                userSearch = api.url(`${process.env.VUE_APP_QUICK_SEARCH_URL}/multi_search`, true)
                    .signal(this.abortWorkspaceLoad = new AbortController())
                    .headers({ 'X-TYPESENSE-API-KEY': useMyStore().user.keys.quickSearchUser })
                    .options({ credentials: 'same-origin' })
                    .query(query)
                    .json({
                        searches: [
                            this.shouldSearch('geoFeatures') ? { collection: 'geoFeatures', q: this.search, per_page: this.limit, sort_by: 'weight:desc' } : null,
                            this.shouldSearch('geoLayers') ? { collection: 'geoLayers', q: this.search, per_page: this.limit } : null,
                            this.shouldSearch('perspectives') ? { collection: 'perspectives', q: this.search, per_page: this.limit, filter_by: 'product:sentinel' } : null
                        ].filter(v => v)
                    })
                    .post()
                    .error(503, () => this.inMaintenance = true)
                    .json()
            }

            return this.resultsPromise = Promise.all([ generalSearch, userSearch ])
                .then(([ generalData, workspaceData ]) => {
                    if (this.inMaintenance) return

                    this.results = [
                        ...this.geoCoordinatesResults(this.search),
                        ...(this.perspectiveStarters ? [{ resultType: 'perspective-new' }] : []),
                        ...this.familyResults(generalData, 'app').hits.map(result => ({ ...result.document, resultType: 'app', family: 'app' })),
                        ...this.familyResults(workspaceData, 'perspectives').hits.map(result => ({ ...result.document, resultType: 'perspective', family: 'perspectives' })),
                        ...this.familyResults(workspaceData, 'geoLayers').hits.map(result => ({ ...result.document, resultType: 'geo-layer', family: 'geoLayers' })),
                        ...this.familyResults(workspaceData, 'geoFeatures').hits.map(result => ({ ...result.document, resultType: 'geo-feature', family: 'geoFeatures' }))
                    ]

                    this.resultsPending = false
                    this.resultsPromise = null
                    this.abortGeneralLoad = this.abortWorkspaceLoad = null
                })
        },

        clear() {
            this.$reset()
        },

        resetFilteredFamilies() {
            this.filteredFamilies = []
            this.limit = 10

            this.load()
        },

        setFilteredFamilies(families) {
            this.filteredFamilies = families.filter(family => this.families.includes(family))

            this.limit = 50
            this.load()
        },

        isFamilyIncludedInFilter(family) {
            return this.filteredFamilies.includes(family)
        },

        resetWorkspace() {
            this.workspace = false

            this.loadDebounced()
        },

        setWorkspace(workspace) {
            this.workspace = workspace

            this.loadDebounced()
        },

        shouldSearch(family) {
            if (family instanceof Array) return family.some(f => this.shouldSearch(f))
            return this.families.includes(family) && (! this.filteredFamilies.length || this.filteredFamilies.includes(family))
        },

        familyResults(data, family) {
            if (! data) return { hits: [], grouped_hits: [] }
            return data.results.find(r => r.request_params.collection_name == family) || { hits: [], grouped_hits: [] }
        },

        geoCoordinatesResults(input) {
            let matches = input.match(/(\d+(?:\.\d+)?),\s*(\d+(?:\.\d+)?)/)

            return matches ? [ { resultType: 'geo-coordinates', coordinates: { lat: matches[1], lon: matches[2] } } ] : []
        }
    }
})

export default useMyQuickSearchStore
