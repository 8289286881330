import useNewOverrideModalStore from '@/stores/settings/credibility/modals/new-override-modal'
import useQuickSearchStore from '@/stores/me/quick-search'

import api, { asyncResource, targets } from '@/api'

import { defineStore } from 'pinia'

export const useSourceCredibilityStore = defineStore({
    id: 'settingsCredibility',

    state: () => ({
        overrides: asyncResource({
            request: (api, store, payload) => targets()
                .family('sources')
                .filters({ 'credibility-override': true })
                .include([ 'credibility' ])
                .sorting('name-asc')
                .query(payload)
                .limit(50)
                .toRequest(),
            paginated: true
        })
    }),

    actions: {
        reload() {
            this.overrides.reset()
            this.overrides.fetchFirst(this)
        },
        
        async loadMore(infiniteScroll) {
            let items = await this.overrides.fetchNext()
            
            if (items.length) {
                infiniteScroll.loaded()
            } else {
                infiniteScroll.complete()
            }
        },
        
        newOverride() {
            useQuickSearchStore().show({
                families: [ 'sources' ],
                limit: 10,
                onSelect: async result => {
                    useNewOverrideModalStore().open(await targets().family('sources').find(result.id.substring(1)))
                    useQuickSearchStore().hide()
                }
            })
        },
        
        async deleteOverride(sourceId) {
            await api.route('monitor targets credibility', { id: sourceId }).delete()
            
            this.reload()
        }
    }
})

export default useSourceCredibilityStore
