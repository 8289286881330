<template>
    <ui-modal name="data-providers-custom-sources-modal" title="Monitoring Requests" height="80%" max-width="1024px">
        <div>
            <div v-for="i in 25" :key="i" class="h-12 px-6 py-1.5 flex items-center space-x-3 even:bg-white/5">
                <div class="flex flex-grow items-center justify-between">
                    <div class="flex items-center">
                        <p class="w-28 text-xs text-gray-300 mr-3">2022-12-14 13:30:00</p>
                        <img class="rounded-full w-4 h-4 shadow mr-1.5" src="https://avatars.staging.gerulata.com/api/?name=Metro+News&color=ffffff&background=db2777" alt="">
                        <p>Metro News #9</p>
                    </div>

                    <a href="#" class="text-xl text-cool-400">
                        <ui-icon name="chevron-right"></ui-icon>
                    </a>
                </div>
            </div>
        </div>
    </ui-modal>
</template>

<script>
export default {
    //
}
</script>
