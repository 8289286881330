import useMyStore from '@/stores/me/my'
import useMyUsersStore from '@/stores/me/users'
import useMyWorkspacesStore from '@/stores/me/workspaces'

import defineForm from '@/stores/reusable/form'

import api from '@/api'
import { trackEvent } from '@/analytics'
import { useModal } from '@/helpers'

import filter from 'just-filter'

export const useWorkspaceInfoStore = defineForm({
    id: 'myModalsWorkspaceInfo',

    inputs: () => ({
        name: null,
        description: null,
        avatar: {},

        users: {},
        access: 'everyone'
    }),

    state: () => ({
        workspace: null
    }),

    submitRequest() {
        let route = this.workspace
            ? [ 'me workspaces update', { id: this.workspace.id } ]
            : [ 'me workspaces store' ]

        return api.route(...route)
            .json({
                _method: this.workspace ? 'put' : 'post',
                name: this.inputs.name,
                description: this.inputs.description,
                avatar: {
                    color: this.inputs.avatar.color,
                    icon: this.inputs.avatar.icon,
                    imageId: this.inputs.avatar.imageId
                },
                users: this.resolveSubmittedUsers()
            })
            .post()
    },

    onResponse() {
        if (! this.workspace) trackEvent('accounts', 'new-workspace-saved')

        useMyWorkspacesStore().reload()

        useModal().hide('workspace-info')
    },

    actions: {
        open(workspace) {
            this.reset()

            this.workspace = workspace

            this.inputs.name = workspace?.name
            this.inputs.description = workspace?.description
            this.inputs.avatar = workspace?.avatar || {}

            useMyUsersStore().reload().then(() => {
                let selectedUsers = this.workspace?.users || []

                this.inputs.users = useMyUsersStore().items.reduce((users, user) => {
                    return { ...users, [user.id]: !! selectedUsers.find(u => u.id == user.id) }
                }, {})

                this.inputs.users[useMyStore().user.id] = true

                if (this.workspace) this.inputs.access = this.resolveSelectedAccessOption()
            })

            useModal().show('workspace-info')
        },

        async remove() {
            await api.route('me workspaces delete', { id: this.workspace.id }).delete().res()

            useMyWorkspacesStore().reload()

            useModal().hide('workspace-info')
        },

        cancel() {
            useModal().hide('workspace-info')
        },

        toggleUser(user) {
            if (user.id == useMyStore().user.id) return

            this.inputs.users[user.id] = ! this.inputs.users[user.id]
        },

        resolveSubmittedUsers() {
            if (this.inputs.access == 'everyone') {
                return useMyUsersStore().items.map(user => user.id)
            }

            if (this.inputs.access == 'only-me') {
                return [ useMyStore().user.id ]
            }

            return Object.keys(filter(this.inputs.users, (k, v) => v))
        },

        resolveSelectedAccessOption() {
            if (this.workspace?.users.length == useMyUsersStore().items.length) return 'everyone'
            if (this.workspace?.users.length == 1) return 'only-me'

            return 'selected-users'
        }
    }
})

export default useWorkspaceInfoStore
