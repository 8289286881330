import useSettingsUsersStore from '@/stores/settings/users/users'
import defineForm from '@/stores/reusable/form'

import api from '@/api'
import { trackEvent } from '@/analytics'
import { useModal } from '@/helpers'

export const useEditWorkspaceStore = defineForm({
    id: 'settingsUsersModalsEditWorkspace',

    inputs: () => ({
        workspace: {},
        name: null,
        description: null,
        avatar: null
    }),

    submitRequest() {
        if (! this.inputs.workspace.id) {
            trackEvent('accounts', 'new-workspace-saved')
        }

        let route = this.inputs.workspace.id
            ? [ 'me workspaces update', { id: this.inputs.workspace.id } ]
            : [ 'me workspaces store' ]

        return api.route(...route)
            .formData({
                _method: this.inputs.workspace.id ? 'put' : 'post',
                name: this.inputs.name,
                description: this.inputs.description,
                avatar: this.inputs.avatar
            })
            .post()
    },

    async onResponse() {
        useSettingsUsersStore().reloadOrganization()
        await useSettingsUsersStore().reloadWorkspaces()
        
        useModal().hide('settings-edit-workspace')
    },

    actions: {
        open(workspace) {
            this.reset()

            this.inputs.workspace = workspace || {}
            this.inputs.name = workspace?.name
            this.inputs.description = workspace?.description
            this.inputs.avatar = null
            
            useModal().show('settings-edit-workspace')
        },

        cancel() {
            useModal().hide('settings-edit-workspace')
        }
    }
})

export default useEditWorkspaceStore
