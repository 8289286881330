import useManagementModeratedEventsStore from '@/stores/management/moderated-events/moderated-events'
import useManagementWorkspacesStore from '@/stores/management/workspaces'
import useManagementUsersStore from '@/stores/management/users'

import { defineStore } from 'pinia'

export const useManagementModeratedEventsPage = defineStore({
    id: 'managementModeratedEventsPage',

    actions: {
        async afterEnter() {
            useManagementWorkspacesStore().initialize()
            useManagementUsersStore().initialize()
            useManagementModeratedEventsStore().initialize()
        }
    }
})

export default useManagementModeratedEventsPage
