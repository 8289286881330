<template>
    <ui-sidebar title="Notifications">
        <div class="flex flex-col h-full">
            <div v-if="isLoading" class="h-full flex flex-col items-center justify-center">
                <ui-spinner></ui-spinner>
            </div>

            <div v-else-if="! notifications.length" class="flex flex-col h-full items-center justify-center text-gray-300">
                <ui-icon name="bell" class="text-3xl"></ui-icon>
                <p class="mt-2">You will find your notifications here.</p>
            </div>

            <div v-else class="flex-1 min-h-0 overflow-auto divide-y divide-gray-600 py-1 px-2">
                <a href="#" @click.prevent="notification.onAction" v-for="notification in notifications" :key="notification.id" class="flex items-center space-x-3 leading-tight cursor-pointer rounded py-3 px-3" :class="{ 'bg-gray-500 text-white': notification.id == selectedNotification, 'hover:bg-gray-600': notification.id != selectedNotification }">
                    <ui-icon :name="notification.data.icon" class="text-gray-400 text-3xl"></ui-icon>
                    <div class="flex-1">
                        <div class="font-medium mb-0.5">
                            {{ notification.data.title }}
                        </div>
                        <div class="text-gray-200 line-clamp-2">
                            {{ notification.data.description }}
                        </div>
                    </div>
                    <div class="self-start flex items-center space-x-2">
                        <div class="flex items-center justify-center w-2 flex-shrink-0">
                            <div v-if="! notification.readAt" class="rounded-full h-2 w-2 bg-red-600"></div>
                        </div>
                        <div v-if="notification.data.critical" class="flex items-center justify-center flex-shrink-0" v-tooltip="'Critical'">
                            <ui-icon name="warning" class="text-orange-300 text-xl"></ui-icon>
                        </div>
                        <div class="text-sm text-gray-200">
                            {{ $dateTime(notification.createdAt) }}
                        </div>
                    </div>
                </a>

                <div class="py-4">
                    <ui-infinite-loading @infinite="scroll" v-if="notifications.length"></ui-infinite-loading>
                </div>
            </div>
        </div>
    </ui-sidebar>
</template>

<script>
import UiSidebar from '@/components/ui/sidebar'

import useMyNotificationsStore from '@/stores/me/notifications'
import useOverviewStore from '@/stores/overview/overview'

import { mapActions, mapState } from 'pinia'

export default {
    components: { UiSidebar },

    computed: {
        ...mapState(useMyNotificationsStore, [ 'isLoading', 'isOverlayShown', 'notifications', 'selectedNotification' ]),
        ...mapState(useOverviewStore, [ 'leftSidebar', 'leftSidebarOptions' ])
    },

    methods: {
        ...mapActions(useMyNotificationsStore, [ 'toggle', 'scroll' ]),
        ...mapActions(useOverviewStore, [ 'hideLeftSidebar' ])
    },
}
</script>