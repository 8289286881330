import useEventCategoriesStore from '@/stores/me/event-categories'
import useManagementModeratedEventsStore from '@/stores/management/moderated-events/moderated-events'
import useMyGeoFeaturesStore from '@/stores/me/geo-features'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useOverviewStore from '@/stores/overview/overview'
import useTaggingStore from '@/stores/me/tagging'

import { defineStore } from 'pinia'

export const useOverviewPage = defineStore({
    id: 'overviewPage',

    actions: {
        async afterEnter(to) {
            await Promise.all([
                useEventCategoriesStore().initialize(),
                useMyGeoFeaturesStore().loadLayers(),
                useMyPerspectivesStore().initialize(),
                useTaggingStore().initialize()
            ])
            
            useOverviewStore().initialize(to)
            
            useManagementModeratedEventsStore().initialize()
        },

        async afterLeave(to, from) {
            if (to.path != from.path) {
                useOverviewStore().reset()
            }
        }
    }
})

export default useOverviewPage
