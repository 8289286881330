import { defineStore } from "pinia"
import mapboxgl from "mapbox-gl"
import useEventCategoriesStore from "@/stores/me/event-categories"
import useOverviewStore from "@/stores/overview/overview"
import useMapStore from "@/stores/overview/map"
import usePresentationStore from "@/stores/overview/presentation"
import useMeasurementStore from "@/stores/overview/measurements"


export const useMarkerStore = defineStore({
    id: 'marker',

    state: () => ({
        activeMarkerId: null,
        shownClusters: []
    }),

    getters: {
        clusterProperties() {
            const clusterProperties = { 'isNew': ['+', ['case', ['==', ['get', 'isNew'], true], 1, 0]] }

            useEventCategoriesStore().flatCategories.forEach((category) => {
                clusterProperties[category.id] = ['+', ['case', ['==', ['get', 'category'], category.id], 1, 0]]
            })

            return clusterProperties
        },
    },

    actions: {
        loadMarkerIcons(map) {
            useEventCategoriesStore().flatCategories.forEach(category => {
                let img = new Image(100, 100)
                img.onload = () => map.addImage(category.id, img)
                img.src = category.icon
            })
        },

        // Layers
        createPulseAnimationLayers(map) {
            map.addImage('pulsing-dot', this.pulsingDotMarker(map), { pixelRatio: 2 })

            map.addLayer({
                id: 'clustered-point-pulse',
                type: 'symbol',
                source: 'events',
                filter: ['==', 'cluster', true],
                layout: {
                    'icon-image': 'pulsing-dot',
                    'icon-allow-overlap': true,
                    'icon-pitch-alignment': 'viewport',
                    'icon-rotation-alignment': 'viewport',
                },
                paint: {
                    'icon-opacity': [ 'case', ['>=', ['get', 'isNew'], 1], 1, 0 ]
                }
            })

            map.addLayer({
                id: 'unclustered-point-pulse',
                type: 'symbol',
                source: 'events',
                filter: ['!=', 'cluster', true],
                layout: {
                    'icon-image': 'pulsing-dot',
                    'icon-allow-overlap': true,
                    'icon-pitch-alignment': 'viewport',
                    'icon-rotation-alignment': 'viewport',
                },
                paint: {
                    'icon-opacity': [ 'case', ['==', ['get', 'isNew'], true], 1, 0 ]
                }
            })
        },

        setPulsingLayersVisibility(map, visible = false) {
            if (!map.getLayer("clustered-point-pulse") || !map.getLayer("unclustered-point-pulse")) return

            if (visible) {
                map.setLayoutProperty('clustered-point-pulse', 'visibility', 'visible')
                map.setLayoutProperty('unclustered-point-pulse', 'visibility', 'visible')
            } else {
                map.setLayoutProperty('clustered-point-pulse', 'visibility', 'none')
                map.setLayoutProperty('unclustered-point-pulse', 'visibility', 'none')
            }
        },

        createEventLayers(map) {
            let colorOptions = useEventCategoriesStore().flatCategories.reduce((results, category) => [ ...results, category.id, category.color ], [])

            map.addLayer({
                id: 'unclustered-point-circle',
                type: 'circle',
                source: 'events',
                filter: ['!=', 'cluster', true],
                paint: {
                    'circle-stroke-color': "#ffffff",
                    "circle-stroke-opacity": 0.7,
                    'circle-color': [ 'match', ['get', 'category'], ...colorOptions, '#DCE6ED' ],
                    'circle-radius': [ 'case', ['boolean', ['feature-state', 'active'], false], 21, 18 ],
                    "circle-stroke-width": [ 'case', ['boolean', ['feature-state', 'active'], false], 5, 0],
                },
            })

            map.addLayer({
                id: 'unclustered-point-icon',
                type: 'symbol',
                source: 'events',
                filter: ['!=', 'cluster', true],
                layout: {
                    'icon-size': 0.20,
                    'icon-image': [ 'get', 'category' ],
                    'icon-allow-overlap': true,
                },
            })
        },

        createClusterLayers(map) {
            map.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'events',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': '#ffffff',
                    'circle-pitch-alignment': 'viewport',
                    'circle-pitch-scale': 'viewport',
                    'circle-opacity': [ 'case', ['boolean', ['feature-state', 'active'], false], 0.8, 0 ],
                    'circle-radius': [ 'step', ['get', 'point_count'],
                        25, 1,
                        27, 10,
                        32, 100,
                        37
                    ]
                }
            })
        },

        clusterClick(e, map) {
            if (usePresentationStore().presentationMode || useMeasurementStore().measurementMode) return
            let features = map.queryRenderedFeatures(e.point)

            const feature = features.find(feature => feature.properties?.cluster_id)
            if (feature && feature.source === 'events') {
                const cluster_id = feature?.properties?.cluster_id

                if (cluster_id) {
                    if (feature.id) {
                        this.setActiveMarker(feature.id, map)
                    }

                    map.getSource('events').getClusterLeaves(cluster_id, Infinity, 0, (err, aFeatures) => {
                        useOverviewStore().inspectEvents({
                            cluster: useMapStore().featuresToEvents(aFeatures),
                            center: [-250, 0]
                        })
                    })
                }
            }
        },

        markerClick(e, map) {
            if (usePresentationStore().presentationMode || useMeasurementStore().measurementMode) return

            let features = map.queryRenderedFeatures(e.point, { layers: ['unclustered-point-circle'] })

            useOverviewStore().inspectEvents({ event: useMapStore().featuresToEvents(features)[0], center: [-250, 0] })
        },

        setActiveMarker(id, map = null) {
            if (!map) map = useMapStore().map

            if (this.activeMarkerId) {
                map.setFeatureState({source: 'events', id: this.activeMarkerId}, { active: false })
            }

            this.activeMarkerId = id
            if (id || id === 0) {
                map.setFeatureState({source: 'events', id: id}, { active: true })
            }
        },

        updateClusters(map) {
            if (map?.getSource('events') && map.isSourceLoaded('events')) {
                this.renderClusters(map)
            } else {
                setTimeout(() => this.updateClusters(map), 100)
            }
        },

        pulsingDotMarker(map) {
            const size = 200

            return {
                width: size,
                height: size,
                data: new Uint8Array(size * size * 4),

                onAdd: function () {
                    const canvas = document.createElement('canvas')
                    canvas.width = this.width
                    canvas.height = this.height
                    this.context = canvas.getContext('2d')
                },

                // Call once before every frame where the icon will be used.
                render: function () {
                    const duration = 1000
                    const t = (performance.now() % duration) / duration

                    const radius = (size / 2) * 0.3
                    const outerRadius = (size / 2) * 0.7 * t + radius
                    const context = this.context

                    // Draw the circle.
                    context.clearRect(0, 0, this.width, this.height)
                    context.beginPath()
                    context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2)
                    context.fillStyle = `rgba(255, 255, 255, ${1 - t})`
                    context.fill()

                    // Update this image's data with data from the canvas.
                    this.data = context.getImageData(0, 0, this.width, this.height).data
                    map.triggerRepaint()
                    return true
                }
            }
        },

        renderClusters(map) {
            let features = map.querySourceFeatures('events')
                .filter(f => f.properties.cluster_id)
                .map(f => [ f.properties.cluster_id, f ])

            let newMarkers = Object.values(Object.fromEntries(features)).map(feature => {
                return new mapboxgl.Marker({ element: this.createDonutChart(feature.properties) }).setLngLat(feature.geometry.coordinates)
            })

            this.shownClusters.forEach(m => m.remove())
            newMarkers.forEach(m => m.addTo(map))

            this.shownClusters = newMarkers
        },

        createDonutChart(props) {
            let offsets = []
            let counts = useEventCategoriesStore().flatCategories.map(category => props[category.id])
            let colors = useEventCategoriesStore().flatCategories.map(category => category.color)
            let total = 0

            for (const count of counts) {
                offsets.push(total)
                total += count
            }

            if (total === 0) {
                total = props.point_count_abbreviated
            }

            const fontSize = total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16
            const r = total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 28 : 22
            const r0 = Math.round(r * 0.8)
            const w = r * 2
            const color = "#DCE6ED"

            let html = `<div style="cursor: pointer"><svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`

            for (let i = 0; i < counts.length; i++) {
                html += this.donutSegment(offsets[i] / total, (offsets[i] + counts[i]) / total, r, r0, colors[i])
            }

            html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="${color}" /><text dominant-baseline="central" transform="translate(${r}, ${r})">${total.toLocaleString()}</text></svg></div>`

            const el = document.createElement('div')
            el.innerHTML = html

            return el.firstChild
        },

        donutSegment(start, end, r, r0, color) {
            if (end - start === 1) end -= 0.00001
            const a0 = 2 * Math.PI * (start - 0.25)
            const a1 = 2 * Math.PI * (end - 0.25)
            const x0 = Math.cos(a0), y0 = Math.sin(a0)
            const x1 = Math.cos(a1), y1 = Math.sin(a1)
            const largeArc = end - start > 0.5 ? 1 : 0

            return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0} A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1} ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0}" fill="${color}" />`
        }
    }
})

export default useMarkerStore
