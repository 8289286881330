<template>
    <div class="fixed relative w-full rounded w-full z-40 top-0 left-0" v-if="events.pending">
        <div class="absolute w-full top-0 h-1 rounded shim bg-gerulata-green-700 overflow-hidden"></div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import useStreamStore from "@/stores/overview/stream";

export default {
    computed: {
        ...mapState(useStreamStore, [ 'events' ])
    },
}
</script>