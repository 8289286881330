import { defineStore } from 'pinia'

export const useSettingsCustomDataProviderPage = defineStore({
    id: 'settingsCustomDataProviderPage',

    actions: {
        async afterEnter() {

        }
    }
})

export default useSettingsCustomDataProviderPage
