<template>
    <a href="#" @click.prevent="showDetails(item)" class="flex items-center space-x-3 leading-tight cursor-pointer py-3 px-3 hover:bg-gray-600 rounded">
        <ui-icon :name="resolveIcon(item.type)" class="text-gray-400 text-3xl"></ui-icon>
        <div class="flex-1">
            <div class="font-medium mb-0.5">
                {{ item.data.perspective.name }} - Pending Alert
            </div>
            <div class="text-gray-200 line-clamp-2">
                {{ item.data.event.title }}
            </div>
        </div>
        <div class="self-start flex items-center space-x-2">
            <div class="flex items-center justify-center w-2 flex-shrink-0">
                <div v-if="item.status == 'pending'" class="rounded-full h-2 w-2 bg-red-600"></div>
            </div>
            <div class="text-sm text-gray-200">
                {{ $dateTime(item.createdAt) }}
            </div>
        </div>
    </a>
</template>

<script>
import useManagementModeratedEventsOverlayStore from '@/stores/management/moderated-events/overlay'

import { mapActions } from 'pinia'

export default {
    props: ['item'],

    methods: {
        ...mapActions(useManagementModeratedEventsOverlayStore, [ 'showDetails' ]),

        resolveIcon(type) {
            return {
                'event-alert': 'hexagon'
            }[type]
        }
    }
}
</script>
