import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useDataProvidersModalsCustomReports = defineStore({
    id: 'dataProvidersModalsCustomReports',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null,
        lastReloadTime: +new Date(),

        page: 1
    }),

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            // @todo: Load items
        },

        async loadMore(infiniteScroll) {
            // @todo: Load more items
        },

        async reload() {
            this.lastReloadTime = +new Date()
            return this.load(true)
        },

        open() {
            useModal().show('data-providers-custom-reports-modal')
        }
    }
})

export default useDataProvidersModalsCustomReports
