<template>
    <ui-modal name="data-providers-edit-custom-report-modal" title="Custom Report" max-width="1024px">
        <div class="space-y-3">
            <div class="flex items-center space-x-3">
                <div class="w-1/2">
                    <label for="name" class="block text-sm text-gray-300 font-medium mb-1">
                        Name
                    </label>

                    <ui-input id="name" placeholder="Custom Report Name" type="text" autocomplete="off"></ui-input>
                </div>

                <div class="w-1/2">
                    <label for="link" class="block text-sm text-gray-300 font-medium mb-1">
                        Link
                    </label>

                    <ui-input id="link" placeholder="Custom Report Link" type="text" autocomplete="off"></ui-input>
                </div>
            </div>

            <div>
                <label for="source" class="block text-sm text-gray-300 font-medium mb-1">
                    Category
                </label>

                <ui-select id="source" placeholder="Custom Report Category"></ui-select>
            </div>

            <div>
                <label for="name" class="block text-sm text-gray-300 font-medium mb-1">
                    Body
                </label>

                <ui-input id="body" type="textarea" placeholder="Custom Report Body" autocomplete="off"></ui-input>
            </div>

            <div>
                <label for="source" class="block text-sm text-gray-300 font-medium mb-1">
                    Source
                </label>

                <ui-select id="source" placeholder="Custom Report Source"></ui-select>
            </div>

            <div>
                <label for="media" class="block text-sm text-gray-300 font-medium mb-1">
                    Media
                </label>

                <div class="h-36 rounded-lg border-2 border-dashed border-gray-300 flex items-center justify-center">
                    <div class="flex flex-col items-center text-gray-300">
                        <ui-icon class="text-6xl" name="aperture"></ui-icon>
                        <p class="mt-3 font-medium">Upload Media</p>
                    </div>
                </div>
            </div>

            <div class="flex items-center space-x-3">
                <div class="w-1/2">
                    <label for="startedAt" class="block text-sm text-gray-300 font-medium mb-1">
                        Started At
                    </label>

                    <ui-input id="startedAt" placeholder="Custom Start Time" type="text" autocomplete="off"></ui-input>
                </div>

                <div class="w-1/2">
                    <label for="endedAt" class="block text-sm text-gray-300 font-medium mb-1">
                        Ended At
                    </label>

                    <ui-input id="endedAt" placeholder="Custom End Time" type="text" autocomplete="off"></ui-input>
                </div>
            </div>
        </div>

        <div class="mt-6 flex justify-end">
            <ui-button color="green">Submit</ui-button>
        </div>
    </ui-modal>
</template>

<script>
export default {
    data: () => ({
        startedAt: null,
        endedAt: null
    })
}
</script>
