<template>
    <search-filter name="Geo-Fence" :ids="['geo-fence']" :filters="filters">
        <template v-slot:applied>
            <ui-icon name="map-pin" class="mr-1"></ui-icon>

            <span class="font-medium">
                Geo-Fence
            </span>
        </template>

        <template v-slot:options>
            <div class="w-64 max-h-80 overflow-y-auto">
                <!--<ui-dropdown-link @click="hideGeoLayer(geoLayer)" keep-open :key="geoLayer.id" v-for="geoLayer in value">-->
                <!--    <template v-slot:default>-->
                <!--        <div class="flex items-center group">-->
                <!--            {{geoLayer.name}}-->
                <!--            <ui-icon name="trash" class="text-gray-300 hidden group-hover:block ml-auto"></ui-icon>-->
                <!--        </div>-->
                <!--    </template>-->
                <!--</ui-dropdown-link>-->

                <ui-dropdown-link @click="selectGeoFeature" keep-open>
                    {{value.length ? 'Add geography...' : 'Select geography...'}}
                </ui-dropdown-link>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <ui-dropdown-link @click="selectArea">
                    Select area on map...
                </ui-dropdown-link>
                <ui-dropdown-link @click="selectRadius">
                    Select radius on map...
                </ui-dropdown-link>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="-mt-2">
                <ui-dropdown-link @click="clearAll">
                    Clear all geo-fences
                </ui-dropdown-link>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import useMyGeoFeaturesStore from '@/stores/me/geo-features'
import useOverviewStore from '@/stores/overview/overview'
import useQuickSearchStore from '@/stores/me/quick-search'

export default {
    props: [ 'filters' ],

    components: {
        SearchFilter
    },

    computed: {
        value() { return this.filters.value('geo-fence') || [] }
    },

    methods: {
        selectGeoFeature() {
            useQuickSearchStore().show({
                families: [ 'geoFeatures', 'geoLayers' ],
                limit: 50,
                onSelect: async result => {
                    if (result.resultType == 'geo-feature') {
                        useOverviewStore().showGeoFeature({ id: result.id, layerId: result.layerId }, { filter: true })
                    } else if (result.resultType == 'geo-layer') {
                        useOverviewStore().showGeoLayer(result.id, { filter: true })
                    }

                    useQuickSearchStore().hide()
                }
            })
        },

        hideGeoLayer(geoLayer) {
            useOverviewStore().hideGeoLayer(geoLayer.id)
        },

        selectArea() {
            useOverviewStore().createPolygon(null, true)
        },

        selectRadius() {
            useOverviewStore().createCircle(null, true)
        },

        clearAll() {
            useOverviewStore().clearMap()
        }
    }
}
</script>