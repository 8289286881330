<template>
    <div class="mx-auto w-full max-w-4xl bg-gray-700 border border-gray-600 rounded-lg">
        <div class="flex items-center gap-4 px-6 py-5">
            <div class="flex-1">
                <h1 class="font-semibold"><ui-icon name="key" class="mr-1"></ui-icon> API Tokens</h1>
                <p class="mt-2 text-sm text-gray-300">Gerulata Sentinel provides a powerful API for easy integration with third-party and custom applications. Applications authenticate using an API token.</p>
            </div>
            <ui-button color="green" icon="plus">
                New Token
            </ui-button>
        </div>

        <div class="flow-root px-6">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-500 mb-6">
                        <thead>
                        <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-300 sm:pl-0">Application Name</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-300">API Token</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-300">Status</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-300">Last Request At</th>
                            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                <span class="sr-only">Edit</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-600">
                        <tr v-for="person in people" :key="person.email">
                            <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                <div class="flex items-center">
                                    <div class="font-medium text-gray-100">{{ person.name }}</div>
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                <div class="text-gray-300 font-mono">{{ person.token }}</div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                <span class="inline-flex items-center rounded-md bg-gerulata-green-100 px-2 py-1 text-xs font-medium text-gray-800 ring-1 ring-inset ring-green-600/20">Active</span>
                            </td>
                            <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-300">{{ person.lastContent }}</td>
                            <td class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                <a href="#" class="text-gerulata-green-100 hover:text-gerulata-green-300"
                                >Edit<span class="sr-only">, {{ person.name }}</span></a
                                >
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// import UIIcon from "@/components/ui/icon.vue";

export default {
    // components: {UIIcon},
    data() {
        return {
            people: [
                {
                    name: 'Custom Dashboard App',
                    token: 'x31_5js9ff4is5fuh4iisuh8uy3d',
                    status: 'Active',
                    lastContent: '31 seconds ago',
                },
                {
                    name: 'Risk Management Application',
                    token: 'x31_d8sff84ojfijsodj894ff0f',
                    status: 'Active',
                    lastContent: '1 minute ago',
                }
                // More people...
            ]
        }
    }
}
</script>