<template>
    <div class="h-full w-full rounded-md bg-gradient-to-b shadow-xl from-gray-600/80 to-gray-700/80 backdrop-blur border border-gray-500/70 pointer-events-auto flex flex-col">
        <div class="flex items-center justify-between pt-3 px-5 text-gray-200">
            <slot name="title">
                <h1 class="text-sm font-medium">{{title}}</h1>
            </slot>

            <div class="flex space-x-3 items-center">
                <slot name="actions"></slot>
                <a href="#" @click.prevent="$emit('close')">
                    <ui-icon name="x"></ui-icon>
                </a>
            </div>
        </div>

        <div class="flex-1 min-h-0 overflow-auto py-3">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'title' ]
}
</script>
