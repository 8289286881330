<template>
    <div class="bg-gray-950 min-h-screen relative">
        <div class="mx-auto max-w-7xl w-full pt-4 pb-4 text-center lg:text-left max-h-screen overflow-auto">
            <div class="px-4 sm:px-8 my-3">
                <img src="@/assets/gerulata.svg" alt="Gerulata" class="w-32 h-auto mb-4">
            </div>
            <div class="px-4 lg:w-1/2 sm:px-8 lg:py-32 xl:pr-32">
                <h1 class="text-3xl tracking-tight font-bold text-gray-200 sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl">
                    <span class="inline">Sign in</span><br>
                    <span class="inline">to your account</span>
                </h1>

                <div class="mt-3 max-w-md mx-auto text-md md:mt-8 md:max-w-3xl">
                    <ui-form :store="authLoginFormStore" v-slot="{ inputs, submitting, submitted, hasErrors, errors }" class="space-y-6">
                        <div class="border border-red-700 bg-red-900 text-red-100 px-4 py-2 text-sm mb-2 rounded" v-if="errors && errors.other">
                            {{ errors.other }}
                        </div>

                        <div>
                            <label for="email" class="block text-sm text-gray-200 font-medium mb-1.5">
                                Email address
                            </label>

                            <div>
                                <ui-input id="email" type="email" autocomplete="email" required v-model="inputs.email" :errors="errors.email"></ui-input>
                            </div>
                        </div>

                        <div>
                            <label for="password" class="block text-sm text-gray-200 font-medium mb-1.5">
                                Password
                            </label>

                            <div>
                                <ui-input id="password" type="password" autocomplete="current-password" required v-model="inputs.password" :errors="errors.password"></ui-input>
                            </div>
                        </div>

                        <div v-if="isTwoFactorAuthCodeRequired">
                            <label for="twoFactorAuthCode" class="block text-sm text-gray-200 font-medium mb-1.5">
                                Two Factor Auth Code
                            </label>

                            <div>
                                <ui-input id="twoFactorAuthCode" required v-model="inputs.twoFactorAuthCode" :errors="errors.twoFactorAuthCode" ref="twoFactorAuthCode"></ui-input>
                            </div>
                        </div>

                        <div class="flex flex-col space-y-3">
                            <router-link :to="{ name: 'password-recovery.request' }" class="text-gerulata-green-100 hover:text-gerulata-green-300">
                                Forgot your password?
                            </router-link>

                            <a href="#" v-if="isTwoFactorAuthCodeRequired" @click.prevent="toggleIsShowingTwoFactorResetHelp" class="text-gerulata-green-100 hover:text-gerulata-green-300">
                                Lost your two-factor auth?
                            </a>

                            <div class="text-gray-300" v-if="isShowingTwoFactorResetHelp">
                                <p>You can use your recovery phrase from when you were setting up your two-factor authentication. Simply enter the entire 40 character phrase (including dashes) into the regular auth code field.</p>
                                <p class="mt-3">If you're still unable to recover your account, please contact your organisation owner or <a class="underline" href="mailto:support@gerulata.com">Gerulata support</a>.</p>
                            </div>
                        </div>

                        <div>
                            <button type="submit" class="inline-flex items-center justify-center py-3 px-20 rounded-md bg-gerulata-green-100 hover:bg-gerulata-green-300 text-gray-800 hover:text-gray-950 shadow-sm font-medium text-lg" :disabled="submitting">
                                <template v-if="submitting || (submitted && ! hasErrors)">
                                    <ui-spinner type="clip" color="#A9EE8A" class="mr-2"></ui-spinner>
                                    Signing in...
                                </template>
                                <template v-else>
                                    Sign in
                                </template>
                            </button>
                        </div>
                    </ui-form>
                </div>
            </div>
        </div>
        <div class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
            <img class="absolute inset-0 w-full h-full object-cover" src="@/assets/auth-cover.jpg" alt="">
        </div>
    </div>
</template>

<script>
import useAuthLoginFormStore from '@/stores/auth/login-form'
import useAuthSocialLoginStore from '@/stores/auth/social-login'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useAuthLoginFormStore),
        ...mapState(useAuthLoginFormStore, [ 'isShowingTwoFactorResetHelp', 'isTwoFactorAuthCodeRequired' ]),
        ...mapState(useAuthSocialLoginStore, { socialLoginError: 'error' })
    },

    methods: {
        ...mapActions(useAuthLoginFormStore, [ 'toggleIsShowingTwoFactorResetHelp', 'stopRequiringTwoFactorAuthCode' ])
    },

    watch: {
        'authLoginFormStore.inputs.email'() {
            this.stopRequiringTwoFactorAuthCode()
        },

        isTwoFactorAuthCodeRequired(newVal) {
            if (newVal === true) { this.$nextTick(() => { this.$refs.twoFactorAuthCode.focus() }) }
        }
    }
}
</script>

