<template>
    <ui-contextual-menu v-bind="$attrs">
        <!--<ui-dropdown-link @click="openHistoryDownloadModal(source)">-->
        <!--    Download Full History-->
        <!--</ui-dropdown-link>-->

        <!--<ui-dropdown-separator></ui-dropdown-separator>-->

<!--        <ui-dropdown-link icon="info" :href="$router.resolve({ name: 'targets.target.information', params: { type: source.family, modelType: source.type, id: source.id } }).href">-->
<!--            Show details-->
<!--        </ui-dropdown-link>-->

        <ui-dropdown-link icon="external-link" :href="source.link" target="_blank" rel="noreferrer noopener" :disabled="! source.link">
            Open original
        </ui-dropdown-link>

        <template v-if="can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="showDebug(source)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import useSourcesModalsHistoryDownloadStore from '@/stores/management/data-providers/history-download'

import { mapActions } from 'pinia'

export default {
    props: [ 'source' ],

    methods: {
        ...mapActions(useSourcesModalsHistoryDownloadStore, { openHistoryDownloadModal: 'open' }),
    }
}
</script>
