import useMyStore from '@/stores/me/my'
import useSettingsUsersStore from '@/stores/settings/users/users'

import defineForm from '@/stores/reusable/form'

import api from '@/api'
import { useModal } from '@/helpers'

export const useWorkspaceMembersStore = defineForm({
    id: 'settingsUsersModalsWorkspaceMembers',

    inputs: () => ({
        workspace: {},
        user: {}
    }),

    submitRequest() {
        return api.route('admin accounts organizations workspaces members store', [ useMyStore().user.organization.id, this.inputs.workspace.id, this.inputs.user.id ])
            .post()
    },

    async onResponse() {
        await useSettingsUsersStore().reloadWorkspaces()
        this.refreshWorkspace()
        
        this.isAddingUser = false
    },
    
    state: {
        isAddingUser: false
    },

    actions: {
        open(workspace) {
            this.reset()

            this.inputs.workspace = workspace || {}
            
            this.isAddingUser = false
            
            useModal().show('settings-workspace-members')
        },

        cancel() {
            useModal().hide('settings-workspace-members')
        },
        
        addUser() {
            this.isAddingUser = true  
        },
        
        async removeUser(user) {
            await api.route('admin accounts organizations workspaces members delete', [ useMyStore().user.organization.id, this.inputs.workspace.id, user.id ])
                .formData({ _method: 'delete' })
                .post()
                .res()

            await useSettingsUsersStore().reloadWorkspaces()
            this.refreshWorkspace()
        },
        
        refreshWorkspace() {
            this.inputs.workspace = useSettingsUsersStore().workspaces.find(w => w.id == this.inputs.workspace.id)
        }
    }
})

export default useWorkspaceMembersStore
