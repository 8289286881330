import { defineStore } from "pinia"
import { markRaw } from "vue"
import mapboxgl from "mapbox-gl"
import useStreamStore from "@/stores/overview/stream"
import useMyNotificationsStore from "@/stores/me/notifications"
import usePerspectivePublishModalStore from "@/stores/overview/modals/perspective-publish"
import useEventCategoriesStore from "@/stores/me/event-categories"
import { dateTime, strLimit } from "@/helpers"
import useMarkerStore from "@/stores/overview/marker"


export const usePresentationStore = defineStore({
    id: 'presentation',

    state: () => ({
        presentationMode: false,
        popup: null,
        cycleInterval: 5000,
        marqueeText: "",
        marqueeId: null,
        clusterPages: {},
        cachedEvents: {},
    }),

    actions: {
        togglePresentation(map) {
            if (!this.presentationMode) {
                this.presentationMode = true
                useMarkerStore().setActiveMarker(null, map)
                this.showMarkerPopup(map, 0)
            } else {
                if (this.popup) {
                    this.popup.remove()
                    this.popup = null
                }
                this.presentationMode = false
            }
        },

        showMarkerPopup(map, index = 0) {
            if (!this.presentationMode) return

            if (this.popup) {
                this.popup.remove()
                this.popup = null
            }

            const visibleFeatures = map?.queryRenderedFeatures(null, {layers: [ 'clusters', 'unclustered-point-circle']}) || []

            const eventIds = new Set()
            const filteredFeatures = visibleFeatures.filter(feature => {
                if (eventIds.has(feature.id)) return false
                eventIds.add(feature.id)
                return true
            })

            if (filteredFeatures.length && filteredFeatures[index]) {
                let feature = filteredFeatures[index]
                let eventCount = 1
                const coordinates = feature.geometry.coordinates.slice()
                const nextIndex = index >= filteredFeatures.length - 1 ? 0 : index + 1

                useMarkerStore().setActiveMarker(feature.id, map)

                if (feature.layer.id === 'clusters') {
                    map.getSource('events').getClusterLeaves(feature.id, Infinity, 0, (err, features) => {
                        eventCount = features.length
                        const clusterPages = { ...this.clusterPages }

                        if (clusterPages[feature.id] || clusterPages[feature.id] == 0) {
                            clusterPages[feature.id] = (clusterPages[feature.id] >= eventCount - 1) ? 0 : clusterPages[feature.id] + 1
                        } else {
                            clusterPages[feature.id] = 0
                        }

                        eventCount = features.length
                        const clusterFeature = features[clusterPages[feature.id]]
                        this.clusterPages = clusterPages
                        this.renderPopup(map, coordinates, clusterFeature, eventCount, nextIndex, clusterPages[feature.id])
                    })
                } else {
                    this.renderPopup(map, coordinates, feature, eventCount, nextIndex)
                }
            }
        },

        async renderPopup(map, coordinates, feature, eventCount, index, clusterIndex = 0) {
            let event = null

            if (this.cachedEvents[feature.properties.id]) {
                event = this.cachedEvents[feature.properties.id]
            } else {
                event = await useStreamStore().loadEvent(feature.properties, false)
                const eventsCopy = { ...this.cachedEvents }
                eventsCopy[feature.properties.id] = event
                this.cachedEvents = eventsCopy
            }

            this.marqueeText = event.title + " • "
            this.marqueeId = index

            this.popup = markRaw(new mapboxgl.Popup({ closeOnClick: false })
                .setLngLat(coordinates)
                .setHTML(this.markerPreviewCard(event, eventCount, clusterIndex))
                .addTo(map))

            setTimeout(() => {
                if (this.popup) {
                    this.popup.remove()
                    this.popup = null
                    this.showMarkerPopup(map, index)
                }

            }, this.cycleInterval)
        },

        shareMapPresentation() {
            if (this.presentationMode) {
                this.togglePresentation()
            }

            const perspective = useStreamStore().perspective

            if (perspective) {
                const publishedToken = perspective.published

                if (publishedToken) {
                    navigator.clipboard.writeText(window.location.origin + '/share/' + publishedToken + "?presentation=1")
                    useMyNotificationsStore().pushToast({
                        type: 'info',
                        lifetime: 2,
                        title: 'Presentation link copied to clipboard'
                    })
                } else {
                    usePerspectivePublishModalStore().open(perspective)
                }
            } else {
                useStreamStore().savePerspective()
            }
        },

        markerPreviewCard(event, eventCount = 1, clusterIndex = 0) {
            let style = ""
            if (event.image) {
                style = "background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.9) 100%), url(" + event.image + ") no-repeat center center / cover"
            }

            const categoryColor = useEventCategoriesStore().find(event.category).color

            return `<div class="event-card" style="${style}">
                <div class="event-card-content-wrapper">
                    <div class="event-card-count">${eventCount > 1 ? ((clusterIndex + 1) + "/" + eventCount) : ""}</div>
                    <div class="event-card-category">
                        <div class="w-4 h-4 float-left bg-gray-300 rounded-full justify-center items-center mr-1 mt-0.5" style="background-color: ${categoryColor}"></div>
                        ${event.category}
                    </div>
                    
                    <div class="event-card-date">${ dateTime(event.startedAt)}</div>
                    <div class="event-card-loaction">${event.location?.address || ""}</div>
                    <div class="event-card-title">${strLimit(event.title, 150)}</div>
                </div>
            </div>`
        },
    },
})

export default usePresentationStore
