import useAuditLogStore from '@/stores/settings/audit-log/audit-log'

import { defineStore } from 'pinia'

export const useSettingsAuditLogPage = defineStore({
    id: 'settingsAuditLogPage',

    actions: {
        async afterEnter() {
            useAuditLogStore().reload()
        }
    }
})

export default useSettingsAuditLogPage
