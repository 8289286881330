import api from '@/api'
import {useModal} from '@/helpers'
import {defineForm} from '@/stores/reusable/form'

import useManagementAlertsStore from '@/stores/management/alerts/alerts'
import useManagementWorkspacesStore from '@/stores/management/workspaces'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMyStore from '@/stores/me/my'
import useMyWorkspacesStore from '@/stores/me/workspaces'

export const useManagementModalsAlertEditFormStore = defineForm({
    id: 'managementModalsAlertEditForm',

    inputs: () => ({
        forcedPerspective: false,
        perspective: {},
        alert: {},

        type: 'event-alert',
        enabled: true,
        name: 'Untitled Alert',

        configuration: {
            moderated: false,
            email: {
                additionalRecipients: []
            },
        },

        channels: {
            inApp: false,
            email: false
        },

        recipients: {
            email: {'*': false},
            inApp: {'*': false}
        },

        overview: false
    }),

    submitRequest() {
        return api.route(this.inputs.alert?.id ? 'sentinel management alerts update' : 'sentinel management alerts store', {id: this.inputs.alert?.id})
            .json({
                _method: this.inputs.alert?.id ? 'put' : 'post',

                enabled: this.inputs.enabled,
                name: this.inputs.name,
                type: this.inputs.type,

                perspective: this.inputs.perspective,

                configuration: this.inputs.configuration,

                channels: Object.entries(this.inputs.channels).reduce((channels, [channel, enabled]) => {
                    if (enabled) {
                        channels[channel] = Object.entries(this.inputs.recipients[channel])
                            .filter(([channel, value]) => value)
                            .map(([channel, value]) => channel)
                    }
                    return channels
                }, {})
            })
            .post()
    },

    async onResponse(res) {
        await useManagementAlertsStore().reload()

        if (this.inputs.overview) useMyPerspectivesStore().reload()

        useModal().hide('management-alert-edit')
    },

    getters: {
        workspace() {
            if (this.inputs.overview) {
                return useMyStore().currentWorkspace
            }

            return useManagementWorkspacesStore().items.length
                ? useManagementWorkspacesStore().items.find(w => w.id === this.inputs.perspective.workspace.id)
                : useMyWorkspacesStore().items.find(w => w.id === this.inputs.perspective.workspace.id)
        }
    },

    actions: {
        open(alert = null, perspective = null, overview = false) {
            this.$reset()

            if (perspective) {
                this.inputs.forcedPerspective = true
                this.inputs.perspective = perspective
            }

            if (alert) {
                this.inputs.alert = alert

                this.inputs.name = alert.name
                this.inputs.perspective = this.inputs.perspective || alert.perspective
                this.inputs.configuration = alert.configuration

                this.inputs.channels.inApp = !!alert?.channels?.inApp
                this.inputs.channels.email = !!alert?.channels?.email

                this.inputs.recipients.inApp = (alert?.channels?.inApp || []).reduce((recipients, recipient) => ({
                    ...recipients,
                    [recipient]: true
                }), {})
                this.inputs.recipients.email = (alert?.channels?.email || []).reduce((recipients, recipient) => ({
                    ...recipients,
                    [recipient]: true
                }), {})
            }

            this.inputs.overview = overview

            useModal().show('management-alert-edit')
        },

        toggleModeration() {
            this.inputs.configuration.moderated = !this.inputs.configuration.moderated
        },

        toggleChannel(channel) {
            if (this.inputs.channels[channel]) {
                this.inputs.channels[channel] = false
                this.inputs.recipients[channel]['*'] = false
            } else {
                this.inputs.channels[channel] = true
                this.inputs.recipients[channel]['*'] = true
            }
        },

        toggleRecipient(channel, recipient) {
            this.inputs.recipients[channel][recipient] = !this.inputs.recipients[channel][recipient]

            if (recipient == '*') {
                this.workspace.users.forEach(user => this.inputs.recipients[channel][user.id] = true)
            }
        },

        delete() {
            return api.route('sentinel management alerts delete', {id: this.inputs.alert.id})
                .delete()
                .res(async res => {
                    await useManagementAlertsStore().reload()

                    if (this.inputs.overview) useMyPerspectivesStore().reload()

                    useModal().hide('management-alert-edit')
                })
        }
    }
})

export default useManagementModalsAlertEditFormStore
