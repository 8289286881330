<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-950 py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full space-y-8">
            <div>
                <img src="@/assets/gerulata.svg" alt="Gerulata" class="w-32 h-auto mx-auto">
                <h2 class="mt-8 text-center text-4xl font-bold text-gray-200">
                    Reset your password
                </h2>
                <p class="mt-4 text-center text-gray-200">
                    Please choose a new password for your account.
                </p>
            </div>
            <ui-form :store="authPasswordRecoveryResetFormStore" v-slot="{ inputs, submitting, errors }" class="mt-2 space-y-10">
                <div class="border border-red-700 bg-red-900 text-red-100 px-4 py-2 text-sm mb-2 rounded" v-if="errors && errors.other">
                    <p :key="error" v-for="error in errors.other">{{ error }}</p>
                </div>

                <div class="space-y-3">
                    <div>
                        <label for="email-address" class="sr-only">Email address</label>
                        <ui-input type="email" autocomplete="email" required placeholder="Email address" v-model="inputs.email" :errors="errors.email"></ui-input>
                    </div>
                    <div>
                        <label for="password" class="sr-only">Password</label>
                        <ui-input type="password" autocomplete="off" required placeholder="Password" v-model="inputs.password" :errors="errors.password"></ui-input>
                    </div>
                    <div>
                        <label for="password_confirmation" class="sr-only">Repeat the same password</label>
                        <ui-input type="password" autocomplete="off" required placeholder="Repeat the password" v-model="inputs.passwordConfirmation" :errors="errors.passwordConfirmation"></ui-input>
                    </div>
                </div>

                <div class="text-center">
                    <button type="submit" class="inline-flex items-center justify-center py-3 px-20 rounded-md bg-gerulata-green-100 hover:bg-gerulata-green-300 text-gray-800 hover:text-gray-950 shadow-sm" :disabled="submitting">
                        Reset My Password
                    </button>
                </div>
            </ui-form>
        </div>
    </div>
</template>

<script>
import useAuthPasswordRecoveryResetFormStore from '@/stores/auth/password-recovery-reset-form'

import { mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useAuthPasswordRecoveryResetFormStore)
    }
}
</script>
