import api from '@/api'

import {defineStore} from 'pinia'

export const useManagementAlertsStore = defineStore({
    id: 'managementAlerts',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize() {
            return this.load()
        },

        async load(force = false) {
            if (this.isInitialized && !force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('sentinel management alerts').get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        async all() {
            await this.load()
            return this.items
        },

        find(id) {
            return this.items.find(i => i.id == id)
        },

        async update(alert, data) {
            await api.route('sentinel management alerts update', alert.id)
                .formData({_method: 'put', ...data})
                .post()
                .res()

            return this.reload()
        },

        async delete(alert) {
            await api.route('sentinel management alerts delete', alert.id)
                .formData({_method: 'delete'})
                .post()
                .res()

            return this.reload()
        }
    }
})

export default useManagementAlertsStore
