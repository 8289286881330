<template>
    <ui-modal name="overview-perspective-publish" title="Publish a Perspective" width="100%" height="auto" :max-width="420" @closed="cancel">
        <div class="text-center space-y-4">
            <template v-if="! perspective.published">        
                <p>Publishing the perspective will make the perspective viewable by all users in your organization.</p>
                
                <p>Non-owner users will be able to view the perspective and make searches based on it, but won't be able to modify the perspective.</p>
                
                <p>A unique public link will also be generated, that can be used to share the perspective outside of the organization.</p>
                
                <p>You can unpublish this perspective at any point in the future.</p>
                
                <ui-button size="lg" color="green" :action="publish">Publish</ui-button>
            </template>
            <template v-else>
                <p>This perspective is viewable by all users in your organization and via the link below.</p>
                
                <p>Non-owner users will be able to view the perspective and make searches based on it, but won't be able to modify the perspective.</p>
                
                <div class="relative">
                    <ui-input v-model="publicLink" disabled class="pr-20"></ui-input>
                    <div class="flex items-center absolute right-1 inset-y-0">
                        <ui-dropdown>
                            <template v-slot:trigger>
                                <ui-button size="xs" color="green">
                                    Copy <ui-icon name="chevron-down"></ui-icon>
                                </ui-button>
                            </template>
                            <template v-slot:content>
                                <ui-dropdown-link @click="copyPublicLink">Copy perspective link</ui-dropdown-link>
                                <ui-dropdown-link @click="copyPublicLink(true)">Copy in presentation mode</ui-dropdown-link>
                            </template>
                        </ui-dropdown>
                    </div>
                </div>
                
                <ui-button size="lg" color="red" :action="unpublish">Unpublish</ui-button>
            </template>
        </div>
    </ui-modal>
</template>

<script>
import usePerspectivePublishModalStore from '@/stores/overview/modals/perspective-publish'

import { mapActions, mapState } from 'pinia'
import UiDropdown from "@/components/ui/dropdown.vue";

export default {
    components: {UiDropdown},
    computed: {
        ...mapState(usePerspectivePublishModalStore, [ 'perspective', 'publicLink' ])
    },

    methods: {
        ...mapActions(usePerspectivePublishModalStore, [ 'cancel', 'copyPublicLink', 'publish', 'unpublish' ])
    }
}
</script>
