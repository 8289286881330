<template>
    <ui-modal name="management-alert-edit" :title="`${perspective.name} - Alerts`" width="100%"
              height="auto" :max-width="480" :max-height="640" @opened="opened">
        <ui-form :store="managementModalsAlertEditFormStore" v-slot="{ inputs, submitting, errors }">
            <div>
                <div v-if="! inputs.overview">
                    <label for="name" class="mb-1 block text-sm font-medium text-gray-400">
                        Name
                    </label>

                    <div>
                        <ui-input type="text" name="name" id="name" placeholder="e.g. Paramilitary Groups"
                                  ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                    </div>
                </div>

                <div class="mt-3 mb-6" v-if="! inputs.overview">
                    <label for="perspective" class="mb-1 block text-sm font-medium text-gray-400">
                        Perspective
                    </label>

                    <div>
                        <ui-select v-if="! inputs.alert.id && ! inputs.forcedPerspective" id="perspective" :options="perspectives" label="name"
                                   v-model="inputs.perspective" :errors="errors['perspective']"
                                   ref="perspective"></ui-select>
                        <ui-input v-else disabled type="text" name="perspective" id="perspective"
                                  v-model="inputs.perspective.name"></ui-input>
                    </div>
                </div>

                <div class="rounded-md bg-gray-800 mb-3" v-if="inputs.perspective.id">
                    <div class="flex items-center px-4 py-2 space-x-3">
                        <ui-icon name="bell" class="text-2xl text-gray-300"></ui-icon>
                        <a href="#" @click.prevent="toggleChannel('inApp')" class="flex-1">
                            <div class="text-lg text-gray-200 font-medium">
                                In-App
                            </div>
                            <div class="text-gray-300">
                                Receive alerts in the Sentinel application.
                            </div>
                        </a>
                        <ui-switch @click="toggleChannel('inApp')" :value="inputs.channels.inApp" size="sm"></ui-switch>
                    </div>

                    <div class="py-3 px-6" v-if="inputs.channels.inApp">
                        <div class="mb-2">
                            <ui-switch @click="toggleRecipient('inApp', '*')"
                                       :value="inputs.recipients['inApp']['*']" size="sm" class="mr-3"></ui-switch>
                            <span class="text-sm text-gray-300">Notify all members of the workspace</span>
                        </div>

                        <div class="space-y-2" v-if="! inputs.recipients['inApp']['*']">
                            <div class="flex items-center" :key="user.id"
                                 v-for="user in workspace.users">
                                <div class="shrink-0 pr-3">
                                    <ui-switch @click="toggleRecipient('inApp', user.id)"
                                               :value="inputs.recipients['inApp'][user.id]" size="sm"></ui-switch>
                                </div>

                                <div class="shrink-0">
                                    <img class="h-4 w-4 rounded-full shadow" :src="user.avatarUrl">
                                </div>

                                <div class="min-w-0 flex-1 px-2 space-x-1">
                                    <span v-tooltip="user.name">{{ user.name }}</span>
                                    <span class="text-xs text-gray-400">{{ user.email }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="rounded-md bg-gray-800 mb-3" v-if="inputs.perspective.id">
                    <div class="flex items-center px-4 py-2 space-x-3">
                        <ui-icon name="email" class="text-2xl text-gray-300"></ui-icon>
                        <a href="#" @click.prevent="toggleChannel('email')" class="flex-1">
                            <div class="text-lg text-gray-200 font-medium">
                                Email
                            </div>
                            <div class="text-gray-300">
                                Receive alerts over email.
                            </div>
                        </a>
                        <ui-switch @click="toggleChannel('email')" :value="inputs.channels.email" size="sm"></ui-switch>
                    </div>

                    <div class="py-3 px-6" v-if="inputs.channels.email">
                        <div class="mb-2">
                            <ui-switch @click="toggleRecipient('email', '*')"
                                       :value="inputs.recipients['email']['*']" size="sm" class="mr-3"></ui-switch>
                            <span class="text-sm text-gray-300">Notify all members of the workspace</span>
                        </div>

                        <div class="space-y-2" v-if="! inputs.recipients['email']['*']">
                            <div class="flex items-center" :key="user.id"
                                 v-for="user in workspace.users">
                                <div class="shrink-0 pr-3">
                                    <ui-switch @click="toggleRecipient('email', user.id)"
                                               :value="inputs.recipients['email'][user.id]" size="sm"></ui-switch>
                                </div>

                                <div class="shrink-0">
                                    <img class="h-4 w-4 rounded-full shadow" :src="user.avatarUrl">
                                </div>

                                <div class="min-w-0 flex-1 px-2 space-x-1">
                                    <span v-tooltip="user.name">{{ user.name }}</span>
                                    <span class="text-xs text-gray-400">{{ user.email }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="flex items-center px-4 py-2 space-x-3">
                        <ui-icon name="shield" class="text-2xl text-gray-300"></ui-icon>
                        <a href="#" @click.prevent="toggleModeration" class="flex-1">
                            <div class="text-lg text-gray-200 font-medium">
                                Moderated
                            </div>
                            <div class="text-gray-300">
                                Triggered alerts will be sent after confirmation by a user with moderator permission.
                            </div>
                        </a>
                        <ui-switch @click="toggleModeration" :value="inputs.configuration.moderated" size="sm"></ui-switch>
                    </div>
                </div>
            </div>

            <div class="mt-8 flex justify-end space-x-4">
                <ui-button color="red" class="mr-auto" :action="deleteAlert" :disabled="submitting"
                           v-if="alert && alert.id">
                    Delete
                </ui-button>

                <ui-button type="submit" :color="alert && alert.id ? 'blue' : 'green'" :disabled="submitting">
                    {{ perspective && perspective.id ? 'Save' : 'Create' }}
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useManagementModalsAlertEditFormStore from '@/stores/management/modals/alert-edit'
import useManagementPerspectivesStore from '@/stores/management/perspectives'

import {mapActions, mapState, mapStores} from 'pinia'

export default {
    computed: {
        ...mapStores(useManagementModalsAlertEditFormStore),
        ...mapState(useManagementModalsAlertEditFormStore, ['workspace']),

        perspectives() {
            return useManagementPerspectivesStore().items
        },

        alert() {
            return this.managementModalsAlertEditFormStore.inputs.alert
        },

        perspective() {
            return this.managementModalsAlertEditFormStore.inputs.perspective
        }
    },

    methods: {
        opened() {
            if (this.$refs.nameInput) this.$refs.nameInput.focus()
        },

        ...mapActions(useManagementModalsAlertEditFormStore, {
            toggleModeration: 'toggleModeration',
            toggleChannel: 'toggleChannel',
            toggleRecipient: 'toggleRecipient',
            deleteAlert: 'delete'
        })
    }
}
</script>
