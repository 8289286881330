<template>
    <div class="mx-auto w-full max-w-4xl bg-gray-700 border border-gray-600 rounded-lg pb-5">
        <div class="px-6 py-5">
            <h1 class="font-semibold"><ui-icon name="users" class="mr-1"></ui-icon> Users & Permissions</h1>
            <p class="mt-2 text-sm text-gray-300">Manage users, workspaces and permissions in your organization.</p>
        </div>

        <div class="bg-white/5 border-t border-b border-gray-700">
            <ui-async :guards="! isLoadingOrganization" class="grid grid-cols-4 p-6">
                <div>
                    <div class="text-sm font-medium text-gray-300">
                        Users
                    </div>

                    <div class="mt-2 flex items-baseline gap-x-2">
                        <span class="text-4xl font-semibold tracking-tight">{{ organization.usersCount }}</span>
<!--                        <span v-if="organization.budgets.users?.limit">/ {{ organization.budgets.users.limit }}</span>-->
                    </div>
                </div>
                <div>
                    <div class="text-sm font-medium text-gray-300">
                        Workspaces
                    </div>
                    <div class="mt-2 flex items-baseline gap-x-2">
                        <span class="text-4xl font-semibold tracking-tight">{{ organization.workspacesCount }}</span>
                    </div>
                </div>
            </ui-async>
        </div>
        
        <div class="flex items-center px-6 mt-4">
            <div class="flex-1">
                <h2 class="font-semibold">Users</h2>
                <p class="mt-2 text-sm text-gray-300">List of all users in your organization.</p>
            </div>
            <div class="flex items-center space-x-5">
                <ui-button icon="plus" color="green" @click="newUser" :disabled="isLoadingOrganization || organization.usersCount == organization.budgets?.users.limit">
                    New User
                </ui-button>
            </div>
        </div>

        <ui-async :guards="! isLoadingUsers" class="divide-y divide-gray-600 px-6 mt-4">
            <div v-for="user in users" :key="user.id" class="p-3 flex items-center space-x-3">
                <ui-avatar :item="user" class="w-8 h-8"></ui-avatar>
                <div class="flex-1">
                    <div class="text-lg font-medium leading-tight">{{user.name}}</div>
                    <div class="text-sm text-gray-200">{{user.email}}</div>
                </div>
                <div class="flex space-x-2">
                    <div v-for="permission in userPermissions(user)" :key="permission.id" class="py-1 px-3 bg-gray-800 text-gray-200 rounded-full text-sm">
                        {{permission.name}}
                    </div>
                </div>
                <ui-contextual-menu plain>
                    <ui-dropdown-link icon="key" @click="openUserPermissionsModal(user)">Permissions</ui-dropdown-link>
                    <ui-dropdown-separator></ui-dropdown-separator>
                    <ui-dropdown-link icon="trash" @click="deleteUser(user)">Remove</ui-dropdown-link>
                </ui-contextual-menu>
            </div>
        </ui-async>
        
        <div class="flex items-center px-6 mt-8">
            <div class="flex-1">
                <h2 class="font-semibold">Workspaces</h2>
                <p class="mt-2 text-sm text-gray-300">List of all workspaces in your organization.</p>
            </div>
            <div class="flex items-center space-x-5">
                <ui-button icon="plus" color="green" @click="editWorkspace">
                    New Workspace
                </ui-button>
            </div>
        </div>

        <ui-async :guards="! isLoadingWorkspaces" class="divide-y divide-gray-600 px-6 mt-4">
            <div v-for="workspace in workspaces" :key="workspace.id" class="p-3 flex items-center space-x-3">
                <ui-avatar :item="workspace" class="w-8 h-8"></ui-avatar>
                <div class="flex-1">
                    <div class="text-lg font-medium leading-tight">{{workspace.name}}</div>
                    <div class="flex items-center space-x-2 mt-1">
                        <div class="flex -space-x-0.5 relative z-0 overflow-hidden">
                            <img class="relative inline-block h-5 w-5 rounded-full ring-2 ring-gray-600" :class="`z-${50 - index * 10}`" :src="user.avatarUrl" v-tooltip="user.name" :key="user.id" v-for="user, index in workspace.users.slice(0, 6)">
                        </div>
                        <div class="font-medium text-xs text-gray-300" v-if="workspace.users.length > 6">
                            +{{workspace.users.length - 6}} users
                        </div>
                    </div>
                </div>
                <ui-contextual-menu plain>
                    <ui-dropdown-link icon="users" @click="editWorkspaceMembers(workspace)">Members</ui-dropdown-link>
                    <ui-dropdown-link icon="edit" @click="editWorkspace(workspace)">Edit</ui-dropdown-link>
                    <ui-dropdown-separator></ui-dropdown-separator>
                    <ui-dropdown-link icon="trash" @click="deleteWorkspace(workspace)">Remove</ui-dropdown-link>
                </ui-contextual-menu>
            </div>
        </ui-async>        
    </div>
    
    <Teleport to="#root">
        <delete-user-modal></delete-user-modal>
        <delete-workspace-modal></delete-workspace-modal>
        <edit-workspace-modal></edit-workspace-modal>
        <new-user-modal></new-user-modal>
        <user-permissions-modal></user-permissions-modal>
        <workspace-members-modal></workspace-members-modal>
    </Teleport>
</template>

<script>
import DeleteUserModal from '@/components/settings/users/modals/delete-user'
import DeleteWorkspaceModal from '@/components/settings/users/modals/delete-workspace'
import EditWorkspaceModal from '@/components/settings/users/modals/edit-workspace'
import NewUserModal from '@/components/settings/users/modals/new-user'
import UserPermissionsModal from '@/components/settings/users/modals/user-permissions'
import WorkspaceMembersModal from '@/components/settings/users/modals/workspace-members'

import useDeleteUserModalStore from '@/stores/settings/users/modals/delete-user'
import useDeleteWorkspaceModalStore from '@/stores/settings/users/modals/delete-workspace'
import useEditWorkspaceStore from '@/stores/settings/users/modals/edit-workspace'
import useNewUserModalStore from '@/stores/settings/users/modals/new-user'
import useSettingsUsersStore from '@/stores/settings/users/users'
import useUserPermissionsModalStore from '@/stores/settings/users/modals/user-permissions'
import useWorkspaceMembersStore from '@/stores/settings/users/modals/workspace-members'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        DeleteUserModal, DeleteWorkspaceModal, EditWorkspaceModal, NewUserModal, UserPermissionsModal, WorkspaceMembersModal
    },
    
    computed: {
        ...mapState(useSettingsUsersStore, [ 'isLoadingOrganization', 'isLoadingUsers', 'isLoadingWorkspaces', 'organization', 'users', 'workspaces' ])
    },
    
    methods: {
        ...mapActions(useDeleteUserModalStore, { deleteUser: 'open' }),
        ...mapActions(useDeleteWorkspaceModalStore, { deleteWorkspace: 'open' }),
        ...mapActions(useEditWorkspaceStore, { editWorkspace: 'open' }),
        ...mapActions(useNewUserModalStore, { newUser: 'open' }),
        ...mapActions(useSettingsUsersStore, [ 'findPermission' ]),
        ...mapActions(useUserPermissionsModalStore, { openUserPermissionsModal: 'open' }),
        ...mapActions(useWorkspaceMembersStore, { editWorkspaceMembers: 'open' }),
        
        userPermissions(user) {
            return user.permissions.map(p => this.findPermission(p)).filter(Boolean)
        }
    }
}
</script>