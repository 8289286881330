import useSourceCredibilityStore from '@/stores/settings/credibility/source-credibility'

import { defineStore } from 'pinia'

export const useSettingsCredibilityPage = defineStore({
    id: 'settingsCredibilityPage',

    actions: {
        async afterEnter() {
            useSourceCredibilityStore().reload()
        }
    }
})

export default useSettingsCredibilityPage
