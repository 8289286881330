import useSettingsUsersStore from '@/stores/settings/users/users'

import { defineStore } from 'pinia'

export const useSettingsUsersPage = defineStore({
    id: 'settingsUsersPage',

    actions: {
        async beforeEnter() {
            useSettingsUsersStore().initialize()
        }
    }
})

export default useSettingsUsersPage
