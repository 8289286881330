import useMyStore from '@/stores/me/my'
import useSettingsUsersStore from '@/stores/management/users'

import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'

export const useDeleteWorkspaceModalStore = defineForm({
    id: 'settingsUsersModalsDeleteWorkspace',

    inputs: () => ({
        workspace: null
    }),

    submitRequest() {
        return api.route('admin accounts organizations workspaces delete', [ useMyStore().user.organization.id, this.inputs.workspace.id ])
            .delete()
    },

    async onResponse(res) {
        useSettingsUsersStore().reloadOrganization()
        await useSettingsUsersStore().reloadWorkspaces()

        useModal().hide('settings-delete-workspace')
    },

    actions: {
        open(workspace) {
            this.reset()

            this.inputs.workspace = workspace

            useModal().show('settings-delete-workspace')
        },

        cancel() {
            useModal().hide('settings-delete-workspace')
        }
    }
})

export default useDeleteWorkspaceModalStore
