<template>
    <ui-dropdown align="right" width="w-48" z-index="50" v-if="! inline">
        <template v-slot:trigger>
            <button :class="classes" aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">
                <ui-icon :name="icon || 'more-horizontal'"></ui-icon>
            </button>
        </template>

        <template v-slot:content>
            <slot></slot>
        </template>
    </ui-dropdown>

    <ui-dropdown-link :submenu="true" submenu-align="right" submenu-width="w-48" v-bind="$attrs" v-else>
        {{ name }}

        <template v-slot:submenu>
            <slot></slot>
        </template>
    </ui-dropdown-link>
</template>

<script>
export default {
    props: {
        inline: Boolean,
        name: {},
        plain: Boolean,
        size: {},
        icon: {}
    },

    computed: {
        classes() {
            let classes = 'inline-flex items-center justify-center text-gray-300 hover:text-gray-400 focus:outline-none focus:text-gray-400 rounded-full transition'

            classes += this.plain ? ' text-2xl' : ' text-xl border border-gray-300 hover:border-gray-400 focus:border-gray-400'
            classes += this.size == 'sm' ? ' w-5 h-5' : ' w-6 h-6'

            return classes
        }
    }
}
</script>