import { defineStore } from "pinia"
import { length } from "@turf/turf"


export const useMeasurementStore = defineStore({
    id: 'measurements',

    state: () => ({
        measurementMode: false,
        measurementPoints: { 'type': 'FeatureCollection', 'features': [] },
        measuredDistance: 0,
        linestring: { 'type': 'Feature', 'geometry': { 'type': 'LineString', 'coordinates': [] } }
    }),

    actions: {
        createMeasurementLayers(map) {
            !map.getSource('measurement') && map.addSource('measurement', { 'type': 'geojson', 'data': this.measurementPoints });

            map.addLayer({
                id: 'measure-points',
                type: 'circle',
                source: 'measurement',
                paint: { 'circle-radius': 8, 'circle-color': '#A9EE8A' },
                filter: ['in', '$type', 'Point']
            });

            map.addLayer({
                id: 'measure-lines',
                type: 'line',
                source: 'measurement',
                layout: { 'line-cap': 'round', 'line-join': 'round' },
                paint: { 'line-color': '#A9EE8A', 'line-width': 2.5 },
                filter: ['in', '$type', 'LineString']
            });

            map.setLayoutProperty('measure-points', 'visibility', 'none')
            map.setLayoutProperty('measure-lines', 'visibility', 'none')
        },

        toggleMeasurementMode(map) {
            this.measurementMode = !this.measurementMode

            if (this.measurementMode) {
                map.setLayoutProperty('measure-points', 'visibility', 'visible')
                map.setLayoutProperty('measure-lines', 'visibility', 'visible')
            } else {
                map.setLayoutProperty('measure-points', 'visibility', 'none')
                map.setLayoutProperty('measure-lines', 'visibility', 'none')

                this.measurementPoints = { 'type': 'FeatureCollection', 'features': [] }
                this.measuredDistance = 0
                map.getSource('measurement').setData(this.measurementPoints)
                map.getCanvas().style.cursor = ''
            }
        },

        measurementCursors(e, map) {
            if (!this.measurementMode) return
            const features = map.queryRenderedFeatures(e.point, { layers: ['measure-points'] });
            map.getCanvas().style.cursor = features.length ? 'pointer' : 'crosshair';
        },

        measure(e, map) {
            if (!this.measurementMode) return

            const features = map.queryRenderedFeatures(e.point, { layers: ['measure-points'] })

            if (this.measurementPoints.features.length > 1) {
                this.measurementPoints.features.pop()
            }

            if (features.length) {
                const id = features[0].properties.id;
                this.measurementPoints.features = this.measurementPoints.features.filter(point => point.properties.id !== id)
            } else {
                const point = {
                    'type': 'Feature',
                    'geometry': { 'type': 'Point', 'coordinates': [e.lngLat.lng, e.lngLat.lat] },
                    'properties': { 'id': String(new Date().getTime()) }
                }

                this.measurementPoints.features.push(point)
            }

            if (this.measurementPoints.features.length > 1) {
                this.linestring.geometry.coordinates = this.measurementPoints.features.map(point => point.geometry.coordinates)
                this.measurementPoints.features.push(this.linestring)

                const distance = length(this.linestring)
                this.measuredDistance = distance.toLocaleString()
            } else {
                this.measuredDistance = 0
            }

            map.getSource('measurement').setData(this.measurementPoints)
        },
    }
})


export default useMeasurementStore
