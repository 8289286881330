<template>
    <ui-modal name="two-factor-auth" title="Manage two-factor authentication" @closed="refresh" :max-width="480">
        <ui-form :store="twoFactorAuthFormStore" v-slot="{ inputs, submitting, errors }">
            <div class="border border-red-300 bg-red-50 text-red-900 w-full px-4 py-2 text-sm mb-6 rounded" v-if="Object.keys(errors).length">
                <p :key="error" v-for="error in errors">
                    {{ error[0] }}
                </p>
            </div>

            <template v-if="! hasTwoFactorAuth || isSuccessful">
                <ui-spinner v-if="isGenerating"></ui-spinner>

                <div v-else>
                    <div v-if="isSuccessful">
                        <div class="flex flex-col items-center">
                            <ui-icon name="check-circle" class="text-green-500 text-8xl"></ui-icon>

                            <p class="text-center w-72 mt-3">
                                Thank you for setting up two-factor authentication!
                            </p>
                        </div>

                        <div class="flex justify-end mt-8">
                            <ui-button @click="close">
                                Close
                            </ui-button>
                        </div>
                    </div>

                    <div v-else-if="! isScannedOrCopied">
                        <div class="text-center space-y-3">
                            <p class="font-medium">
                                Use the two-factor authentication app on your mobile device to scan the code below.
                            </p>

                            <p>
                                We recommend trying <a href="https://1password.com" target="_blank" class="text-gerulata-green-100">1Password</a>, <a href="https://authy.com/features/setup/" target="_blank" class="text-gerulata-green-100">Authy</a>, <a href="https://www.microsoft.com/en-us/security/mobile-authenticator-app" target="_blank" class="text-gerulata-green-100">Microsoft Authenticator</a> or <a href="https://googleauthenticator.net/" target="_blank" class="text-gerulata-green-100">Google Authenticator</a>.
                            </p>

                            <img class="h-36 mx-auto" :src="secretDataUri" alt="QR Code">

                            <p>Alternatively, enter the following code manually.</p>

                            <div class="flex justify-center">
                                <ui-input v-model="secret" disabled class="w-64"></ui-input>
                            </div>
                        </div>

                        <div class="flex justify-end space-x-4 mt-12">
                            <ui-button @click="close">
                                Cancel
                            </ui-button>

                            <ui-button color="green" :action="confirmScannedOrCopied">
                                Continue
                            </ui-button>
                        </div>
                    </div>

                    <div v-else-if="isScannedOrCopied && ! isRecoveryPhraseBackedUp">
                        <div class="text-center space-y-3">
                            <p class="font-medium">
                                Download, copy, or print the recovery phrase below before continuing two-factor authentication setup.
                            </p>

                            <p>
                                This phrase can be used to recover your account if you ever lose access to your two-factor auhtenticator.
                            </p>

                            <ui-input v-model="recoveryPhrase" disabled class="w-full"></ui-input>
                        </div>

                        <div class="flex justify-between mt-12">
                            <ui-button @click="cancelScannedOrCopied">
                                Back
                            </ui-button>

                            <div class="flex space-x-4">
                                <ui-button @click="close">
                                    Cancel
                                </ui-button>

                                <ui-button color="green" :action="confirmRecoveryPhraseBackedUp">
                                    Continue
                                </ui-button>
                            </div>
                        </div>
                    </div>

                    <div v-else-if="isScannedOrCopied && isRecoveryPhraseBackedUp">
                        <div class="flex flex-col items-center text-center space-y-6">
                            <p>
                                Just one more thing. Finish the setup by <span class="font-medium">entering the 6 digit code</span> from your authenticator app into the field below:
                            </p>

                            <ui-input v-model="inputs.code" ref="code"></ui-input>
                        </div>

                        <div class="flex justify-between mt-12">
                            <ui-button @click="cancelRecoveryPhraseBackedUp">
                                Back
                            </ui-button>

                            <div class="flex space-x-4">
                                <ui-button @click="close">
                                    Cancel
                                </ui-button>

                                <ui-button type="submit" color="green" :disabled="submitting">
                                    Confirm
                                </ui-button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-else>
                <label for="currentPassword" class="block text-sm text-gray-200 mt-3 mb-2">
                    Please enter your current password to confirm the changes:
                </label>

                <ui-input type="password" name="currentPassword" id="twoFactorAuthCurrentPassword" v-model="inputs.currentPassword"></ui-input>

                <div class="flex justify-end space-x-4 mt-8">
                    <ui-button @click="close">
                        Cancel
                    </ui-button>

                    <ui-button color="red" :action="remove">
                        Remove
                    </ui-button>
                </div>
            </template>
        </ui-form>
    </ui-modal>
</template>

<script>
import { mapActions, mapState, mapStores } from 'pinia'

import useTwoFactorAuthFormStore from "@/stores/settings/two-factor-auth-form"
import useAccountSettingsStore from '@/stores/settings/account'

export default {
    computed: {
        ...mapState(useAccountSettingsStore, [
            'hasTwoFactorAuth'
        ]),
        ...mapState(useTwoFactorAuthFormStore, [
            'isGenerating',
            'isScannedOrCopied',
            'isRecoveryPhraseBackedUp',
            'isSuccessful',

            'secret',
            'secretDataUri',
            'recoveryPhrase'
        ]),
        ...mapStores(useTwoFactorAuthFormStore)
    },

    methods: {
        ...mapActions(useTwoFactorAuthFormStore, [
            'confirmScannedOrCopied',
            'cancelScannedOrCopied',
            'confirmRecoveryPhraseBackedUp',
            'cancelRecoveryPhraseBackedUp',

            'remove',
            'refresh',
            'close'
        ])
    },

    watch: {
        isRecoveryPhraseBackedUp(newVal) {
            if (newVal === true) { this.$nextTick(() => { this.$refs.code.focus() }) }
        }
    }
}
</script>
