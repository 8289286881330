import useMyPerspectivesStore from '@/stores/me/perspectives'

import api from '@/api'
import { useModal, useRouter } from '@/helpers'

import { defineStore } from 'pinia'

export const usePerspectivePublishModalStore = defineStore({
    id: 'overviewModalsPerspectivePublish',
    
    state: () => ({
        perspective: {}
    }),
    
    getters: {
        publicLink(store) {
            return window.location.origin + useRouter().resolve({ name: 'overview.published', params: { token: store.perspective.published } }).href
        }
    },

    actions: {
        open(perspective = {}) {
            this.perspective = perspective

            useModal().show('overview-perspective-publish')
        },

        cancel() {
            useModal().hide('overview-perspective-publish')
        },
        
        copyPublicLink(presentationMode = false) {
            navigator.clipboard.writeText(this.publicLink + (presentationMode ? '?presentation=1' : ''))
        },
        
        publish() {
            api.route('me perspectives publish', [ this.perspective.id ]).post()
                .json(res => {
                    this.perspective.published = res.published
                    useMyPerspectivesStore().reload()
                })
        },
        
        unpublish() {
            api.route('me perspectives publish', [ this.perspective.id ]).delete()
                .res(res => {
                    this.perspective.published = false
                    useMyPerspectivesStore().reload()
                })
        }
    }
})

export default usePerspectivePublishModalStore
