import useMyWorkspacesStore from '@/stores/me/workspaces'
import useMyNotificationsStore from '@/stores/me/notifications'
import useSupportChatStore from '@/stores/support/chat'
import useSupportHelpStore from '@/stores/support/help'
import useMyBroadcasterStore from '@/stores/me/broadcaster'
import useMyPushStore from '@/stores/me/push'

import api from '@/api'
import { isAnalyticsReady } from '@/analytics'
import { useModal, useRouter } from '@/helpers'

import { isBefore } from 'date-fns'
import { defineStore } from 'pinia'

export const useMyStore = defineStore({
    id: 'my',

    state: () => ({
        isAuthenticated: false,
        isInMaintenance: false,

        isInitialized: false,
        loadingPromise: false,

        user: {},

        preferences: {},
        permissions: [],

        intendedPath: null,

        accessibleFeatures: [],

        currentWorkspace: null,

        isGuest: false,
        authToken: null
    }),

    getters: {
        isImpersonating() {
            return !! localStorage.getItem('impersonation-token')
        },

        preferredLanguage() {
            return this.preferences.preferredContentLanguage || 'en'
        },

        timezone() {
            return this.preferences.timezone || 'UTC'
        }
    },

    actions: {
        async initialize() {
            if (this.isInitialized) return
            if (this.loadingPromise) return this.loadingPromise

            this.handleImpersonation()

            this.authToken = this.authToken || localStorage.getItem('sentinelAuthToken')

            return this.loadingPromise = api.route('me').get()
                .error(401, () => {
                    this.isAuthenticated = false
                    this.isInitialized = true
                })
                .json(res => {
                    this.isAuthenticated = true
                    this.isInitialized = true

                    this.user = res.data

                    this.preferences = res.data.preferences
                    this.permissions = res.data.permissions

                    this.accessibleFeatures = res.data.features

                    this.currentWorkspace = res.data.workspace

                    useMyPushStore().initialize()
                    useMyBroadcasterStore().initialize()
                    useMyNotificationsStore().initialize()
                    useMyWorkspacesStore().initialize()
                    useSupportChatStore().initialize()

                    this.setAnalyticsUser()
//                    this.notifyOnNewRelease()
                })
        },

        async reinitialize() {
            this.isInitialized = false
            this.loadingPromise = null

            return this.initialize()
        },

        initializeAsGuest(token) {
            this.isGuest = true
            this.authToken = token
        },

        async loggedIn(token) {
            this.authToken = token

            localStorage.setItem('sentinelAuthToken', token)

            await this.reinitialize()

            useRouter().push(this.intendedPath || { name: 'overview' })

            this.intendedPath = null
        },

        async logOut() {
            await api.route('auth logout').post().res()

            localStorage.removeItem('sentinelAuthToken')

            window.location.reload()
        },

        sessionLost() {
            useModal().show('auth-session-lost')
        },

        inMaintenance() {
            this.isInMaintenance = true
        },

        handleImpersonation() {
            const impersonationToken = window.location.search.match(/impersonation-token=(.+?)(&|$)/)?.[1]

            if (impersonationToken) {
                localStorage.setItem('impersonation-token', impersonationToken)
            }
        },

        stopImpersonating() {
            localStorage.removeItem('impersonation-token')

            window.location.replace('/')
        },

        updatePreferences(preferences) {
            return api.route('me preferences update')
                .formData({ _method: 'put', preferences: JSON.stringify(preferences) }, true)
                .post()
                .json(res => this.preferences = res.data)
        },

        can(...permissions) {
            return permissions.every(p => this.permissions.includes(p))
        },

        canAny(...permissions) {
            return permissions.some(p => this.permissions.includes(p))
        },

        hasFeatureTo(...features) {
            return features.every(f => this.accessibleFeatures.includes(f))
        },

        async switchWorkspace(workspace) {
            this.currentWorkspace = workspace

            await this.updatePreferences({ lastWorkspace: workspace.id })

            window.location.reload()
        },

        setAnalyticsUser() {
            isAnalyticsReady().then(analytics => {
                analytics.setUserId(`${this.user.email} (${this.user.id})`)
            })
        },

        notifyOnNewRelease() {
            let myNotifications = useMyNotificationsStore()
            let lastSeenReleaseTimestamp = this.preferences.lastSeenReleaseTimestamp

            // already seen release notes for this release
            if (lastSeenReleaseTimestamp && ! isBefore(parseInt(lastSeenReleaseTimestamp), parseInt(process.env.VUE_APP_RELEASE_TIMESTAMP))) return

            setTimeout(() => {
                myNotifications.pushToast({
                    type: 'info',
                    title: 'Platform just got updated!',
                    text: 'Take a quick look at the release notes to learn about new features.',
                    action: 'Show',
                    onAction: toast => {
                        this.markReleaseAsSeen()
                        useSupportHelpStore().showOverlay()
                        useSupportHelpStore().loadReleaseNotes(process.env.VUE_APP_RELEASE_NOTES)
                    },
                    onDismiss: () => {
                        this.markReleaseAsSeen()
                    }
                })
            }, 1000)
        },

        markReleaseAsSeen() {
            this.updatePreferences({ lastSeenReleaseTimestamp: process.env.VUE_APP_RELEASE_TIMESTAMP })
        }
    }
})

export default useMyStore
