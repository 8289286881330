<template>
    <div class="w-full h-full" ref="content"></div>
</template>

<script>

import { extent } from 'd3-array'
import { scaleLinear, scaleTime } from 'd3-scale'
import { select } from 'd3-selection'
import { curveBasis, line } from 'd3-shape'
import { parseISO } from 'date-fns'

export default {
    data: () => ({
        contentWidth: null,
        contentHeight: null,

        margin: null
    }),

    methods: {
        render() {
            // Throw away old rendered analysis
            this.$refs.content.innerHTML = ''

            if (! this.store.series[0].values.length) return

            this.contentWidth = this.$refs.content.offsetWidth
            this.contentHeight = this.$refs.content.offsetHeight

            this.margin = { top: 5, right: 5, bottom: 5, left: 5 }
            let width = this.contentWidth - this.margin.left - this.margin.right
            let height = this.contentHeight - this.margin.top - this.margin.bottom

            let series = this.store.series.map(series => {
                // Parse dates
                let values = series.values.map(d => ({ x: parseISO(d.x), y: d.y }))

                return { ...series, values }
            })

            let hasConstantValue = [ ...new Set(series[0].values.map(d => d.y)) ].length == 1

            let xScale = scaleTime().domain(extent(series.flatMap(s => extent(s.values, d => d.x)))).range([ 0, width ])

            let yScale = scaleLinear()
                .domain(extent([
                    ...extent(series[0].values, d => d.y),
                    series[0].values[0].y * 0.99,
                    series[0].values[0].y * 1.01
                ]))
                .range([ height, 0 ])

            // Render the analysis
            let svg = select(this.$refs.content)
                .append('svg')
                .attr('viewBox', `0 0 ${width + this.margin.left + this.margin.right} ${height + this.margin.top + this.margin.bottom}`)
                .append('g')
                .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)

            let defs = svg.append('defs')

            let uid = +Date.now()

            let gradient = defs.append('linearGradient')
                .attr('id', `sparkAnalysisGradient-${uid}`)

            gradient.append('stop')
                .attr('offset', '0%')
                .attr('class', s => `text-${this.store.color}`)
                .attr('stop-color', 'currentColor')
                .attr('stop-opacity', 0.1)

            gradient.append('stop')
                .attr('offset', '50%')
                .attr('class', s => `text-${this.store.color}`)
                .attr('stop-color', 'currentColor')
                .attr('stop-opacity', 0.75)

            gradient.append('stop')
                .attr('offset', '100%')
                .attr('class', s => `text-${this.store.color}`)
                .attr('stop-color', 'currentColor')
                .attr('stop-opacity', 1)

            // Render series
            svg.selectAll('.series-path')
                .data(series)
                .enter().append('path')
                .attr('class', s => `text-${this.store.color}`)
                .attr('fill', 'none')
                .attr('stroke', hasConstantValue ? 'currentColor' : `url(#sparkAnalysisGradient-${uid})`)
                .attr('stroke-linecap', 'round')
                .attr('stroke-width', 2.5)
                .attr('d', s => line().curve(curveBasis).x(d => xScale(d.x)).y(d => yScale(d.y))(s.values))
        }
    },

    watch: {
        'store.series'() { this.render() }
    }
}
</script>
