<template>
    <div class="absolute inset-0 z-50 ml-16 py-6" :class="{ 'bg-gray-900/50 backdrop-blur-sm': sidebarOptions.backdrop, 'pointer-events-none': ! sidebarOptions.backdrop }"  @click.self="hideSidebar" v-if="sidebar">
        <div class="w-1/2 h-full pl-6 pointer-events-auto" :class="{ 'max-w-xl': ! sidebarOptions.compact, 'max-w-md': sidebarOptions.compact }">
            <component :is="sidebarComponent" @close="hideSidebar"></component>
        </div>
    </div>
</template>

<script>
import useAppStore from '@/stores/app'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useAppStore, [ 'sidebar', 'sidebarOptions', 'sidebarComponent' ])
    },
    
    methods: {
        ...mapActions(useAppStore, [ 'hideSidebar' ])
    }
}
</script>
