import useMyStore from '@/stores/me/my'

import api from '@/api'

import { defineStore } from 'pinia'

export const useSettingsUsersStore = defineStore({
    id: 'settingsUsers',

    state: () => ({
        organization: null,
        users: [],
        workspaces: [],

        isInitialized: false,
        isLoadingOrganization: false,
        isLoadingUsers: false,
        isLoadingWorkspaces: false,

        loadingOrganizationPromise: null,
        loadingUsersPromise: null,
        loadingWorkspacesPromise: null,

        permissions: [
            { id: 'sentinel:manage-geo-features', name: 'Map Editor', description: 'Can create geographies.' },
            { id: 'sentinel:create-reports', name: 'Reporter', description: 'Can create event reports for the organization.' },
            { id: 'sentinel:verify-events', name: 'Verifier', description: 'Can mark events as verified for the organization. Can override source credibility scores.' },
            { id: 'sentinel:moderate-alerts', name: 'Moderator', description: 'Can approve moderated alerts for the organization.' },
            { id: 'manage-organization', name: 'Administrator', description: 'Can create users and manage user permissions. Can create API keys for third party integrations.' }
        ]
    }),

    actions: {
        async initialize() {
            await Promise.all([
                this.loadOrganization(),
                this.loadUsers(),
                this.loadWorkspaces()
            ])
            
            this.isInitialized = true
        },

        async loadOrganization(force = false) {
            if (this.isInitialized && !force) return Promise.resolve()
            if (this.loadingOrganizationPromise) return this.loadingOrganizationPromise

            this.isLoadingOrganization = true

            return this.loadingOrganizationPromise = api.route('me organization').get().json(res => {
                this.organization = res.data

                this.isLoadingOrganization = false
                this.loadingOrganizationPromise = null
            })
        },
        
        async loadUsers(force = false) {
            if (this.isInitialized && !force) return Promise.resolve()
            if (this.loadingUsersPromise) return this.loadingUsersPromise

            this.isLoadingUsers = true

            return this.loadingUsersPromise = api.route('admin accounts organizations members', [ useMyStore().user.organization.id ]).get().json(res => {
                this.users = res.data

                this.isLoadingUsers = false
                this.loadingUsersPromise = null
            })
        },

        async loadWorkspaces(force = false) {
            if (this.isInitialized && !force) return Promise.resolve()
            if (this.loadingWorkspacesPromise) return this.loadingWorkspacesPromise

            this.isLoadingWorkspaces = true

            return this.loadingWorkspacesPromise = api.route('admin accounts organizations workspaces', [ useMyStore().user.organization.id ]).get().json(res => {
                this.workspaces = res.data

                this.isLoadingWorkspaces = false
                this.loadingWorkspacesPromise = null
            })
        },
        
        async reloadOrganization() {
            return this.loadOrganization(true)
        },

        async reloadUsers() {
            return this.loadUsers(true)
        },

        async reloadWorkspaces() {
            return this.loadWorkspaces(true)
        },

        findPermission(id) {
            return this.permissions.find(p => p.id == id)
        }
    }
})

export default useSettingsUsersStore
