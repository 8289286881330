<template>
    <ui-dropdown :align="align || 'left'" z-index="50" width="w-auto" :disabled="readOnly" :keep-open="keepOpen" @opened="$emit('opened')" @closed="$emit('closed')">
        <template v-slot:trigger>
            <a href="#" class="block" :class="{ 'cursor-default': readOnly }">
                <div class="inline-flex items-center px-3 h-7 text-sm font-medium leading-none bg-gerulata-green-700 text-gerulata-green-100 rounded max-w-full" v-if="applied">
                    <slot name="applied"></slot>
                </div>

                <div class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-600 rounded max-w-full" :class="{ 'text-gray-300': ! readOnly, 'text-gray-500': readOnly }" v-else>
                    {{name}}
                </div>
            </a>
        </template>

        <template v-slot:content="{ close }">
            <div @click.stop>
                <div class="flex items-center justify-between px-4 py-3 pb-2 font-medium text-gray-300">
                    <p>{{name}}</p>
                    <a href="#" @click.prevent="close">
                        <ui-icon name="x"></ui-icon>
                    </a>
                </div>

                <slot name="options"></slot>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <div class="pt-1">
                    <slot name="controls" :close="close"></slot>
                </div>
            </div>
        </template>
    </ui-dropdown>
</template>

<script>
export default {
    props: {
        'align': {},
        'filters': {},
        'ids': { default: [] },
        'keepOpen': Boolean,
        'name': {}
    },

    computed: {
        applied() {
            return this.ids.find(f => this.filters.value(f))
        },

        readOnly() {
            return this.ids.find(f => this.filters.isReadOnly(f))
        }
    }
}
</script>
