<template>
    <div class="py-4 px-2.5 space-y-2">
        <ui-async :guards="images">
            <div v-for="(image, index) in images" :key="index" class="inline-block ml-4 cursor-pointer mx-auto">
                <img :src="image" class="block w-28 h-28 rounded-md bg-gray-400 object-cover" @click="open(image)" />
            </div>
        </ui-async>
    </div>
</template>

<script>

import { mapActions, mapState } from 'pinia'
import useDetailsStore from "@/stores/overview/details"
import useImagePreviewModalStore from "@/stores/overview/modals/image-preview";

export default {

    computed: {
        ...mapState(useDetailsStore, [ 'images' ])
    },

    methods: {
        ...mapActions(useDetailsStore, [ 'showImageModal' ]),
        ...mapActions(useImagePreviewModalStore, [ 'open' ])
    }
}
</script>
