<template>
    <a :href="$router.resolve(to)" class="block px-6 py-3 hover:bg-gray-100 hover:ring-1 ring-gray-300 ring-inset" :class="{ 'bg-gray-100 ring-1': focused }" @click.prevent>
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <ui-icon :name="result.icon" class="text-xl"></ui-icon>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="flex items-center leading-5 text-gray-200">
                    <div class="truncate">Go to <span class="font-medium">{{result.title}}</span></div>
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-400"></ui-icon>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: [ 'focused', 'result' ],

    computed: {
        to() {
            return { name: this.result.route }
        }
    },

    methods: {
        open() {
            this.$router.push(this.to)
        }
    }
}
</script>
