<template>
    <ui-modal name="moderated-events-event-alert-edit" :title="'Edit an Event Alert'" width="100%" height="auto"
              :max-width="600" :max-height="960" :scrollable="false">
        <div v-if="moderatedEvent">
            <div class="font-medium text-gray-100">{{ moderatedEvent.data.event.title }}</div>

            <div class="text-sm text-gray-300 mt-3">{{ moderatedEvent.data.event.description }}</div>

            <div v-if="moderatedEvent.status === 'pending'" class="mt-6 space-y-3">
                <div class="flex items-center justify-between">
                    <div class="flex items-center leading-0">
                        <ui-icon name="warning" class="mr-1 text-sm text-gray-400"></ui-icon>
                        <a href="#" @click.prevent="toggleCritical" class="block text-sm font-medium text-gray-400">Mark as Critical</a>
                    </div>
                    <ui-switch @click="toggleCritical" :value="critical" size="sm"></ui-switch>
                </div>

                <div class="space-y-1.5">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center leading-0">
                            <ui-icon name="team" class="mr-1 text-sm text-gray-400"></ui-icon>
                            <a href="#" @click.prevent="toggleCustomWorkspaces" class="block text-sm font-medium text-gray-400">Send to custom Workspaces</a>
                        </div>
                        <ui-switch @click="toggleCustomWorkspaces" :value="customWorkspacesEnabled" size="sm"></ui-switch>
                    </div>

                    <ui-select v-if="customWorkspacesEnabled" multiple id="customWorkspaces" :options="workspaces" label="name" v-model="customWorkspaces" ref="customWorkspaces"></ui-select>
                </div>

                <div class="space-y-1.5">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center leading-0">
                            <ui-icon name="users" class="mr-1 text-sm text-gray-400"></ui-icon>
                            <a href="#" @click.prevent="toggleCustomUsers" class="block text-sm font-medium text-gray-400">Send to custom Users</a>
                        </div>
                        <ui-switch @click="toggleCustomUsers" :value="customUsersEnabled" size="sm"></ui-switch>
                    </div>

                    <ui-select v-if="customUsersEnabled" multiple id="customUsers" :options="users" label="name" v-model="customUsers" ref="customUsers"></ui-select>
                </div>
            </div>

            <div class="flex items-center space-x-1.5 mt-16">
                <a href="#" v-if="moderatedEvent.status === 'pending'" @click.prevent="process(moderatedEvent, 'approved')"
                   class="rounded border text-sm font-medium border-gray-500 text-gerulata-green-100 px-1.5 py-0.5"
                   v-tooltip="'Approve'">
                    Approve
                </a>

                <a href="#" v-if="moderatedEvent.status === 'pending'" @click.prevent="process(moderatedEvent, 'ignored')"
                   class="rounded border text-sm font-medium text-red-300 border-gray-500 px-1.5 py-0.5"
                   v-tooltip="'Ignore'">
                    Ignore
                </a>

                <div v-if="moderatedEvent.status === 'processing'"
                     class="flex items-center rounded border text-sm font-medium text-gray-100 border-gray-500 px-1.5 py-0.5">
                    <ui-spinner type="clip" class="mr-1.5"></ui-spinner>
                    <span>Processing</span>
                </div>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import useModeratedEventsModalsEventAlertEditFormStore from '@/stores/management/moderated-events/modals/event-alert-edit'
import useManagementModeratedEventsStore from '@/stores/management/moderated-events/moderated-events'
import useManagementWorkspacesStore from '@/stores/management/workspaces'
import useManagementUsersStore from '@/stores/management/users'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    computed: {
        ...mapState(useManagementWorkspacesStore, { workspaces: 'items' }),
        ...mapState(useManagementUsersStore, [ 'users' ]),
        ...mapState(useModeratedEventsModalsEventAlertEditFormStore, [ 'moderatedEvent' ]),
        ...mapState(useManagementModeratedEventsStore, [ 'critical', 'customWorkspacesEnabled', 'customUsersEnabled' ]),
        ...mapWritableState(useManagementModeratedEventsStore, [ 'customWorkspaces', 'customUsers' ])
    },

    methods: {
        ...mapActions(useManagementModeratedEventsStore, [ 'process', 'toggleCritical', 'toggleCustomWorkspaces', 'toggleCustomUsers' ])
    }
}
</script>
