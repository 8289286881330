<template>
    <div class="mx-auto w-full max-w-4xl bg-gray-700 border border-gray-600 rounded-lg">
        <div class="px-6 py-5">
            <h1 class="font-semibold"><ui-icon name="file-shield" class="mr-1"></ui-icon> Audit Log</h1>
            <p class="mt-2 text-sm text-gray-300">Audit log captures all security-related actions for all users in your organization.</p>
        </div>
        
        <div class="px-6 flex gap-x-4">
            <action-select></action-select>
            <ui-date-input v-model="dateRange" @input="setDateRange" placeholder="Filter by date..." class="w-64"></ui-date-input>
        </div>

        <ui-async :guards="! records.pending || !! records.data?.length">
            <div class="divide-y divide-gray-600 px-6 mt-4">
                <activity-record v-for="record, i in records.data" :key="i" :record="record"></activity-record> 
            </div>

            <ui-infinite-loading @infinite="loadMore($event)" class="pt-6" v-if="records.data?.length"></ui-infinite-loading>

            <div class="flex flex-col py-32 items-center justify-center text-gray-300" v-if="! records.pending && ! records.data?.length">
                <ui-icon name="file-shield" class="text-3xl"></ui-icon>
                <p class="mt-2">You will find your audit log here.</p>
            </div>
        </ui-async>
    </div>
</template>

<script>
import ActionSelect from './partials/action-select'
import ActivityRecord from './partials/record'

import useAuditLogStore from '@/stores/settings/audit-log/audit-log'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        ActionSelect, ActivityRecord
    },
    
    computed: {
        ...mapState(useAuditLogStore, [ 'dateRange', 'records' ])
    },
    
    methods: {
        ...mapActions(useAuditLogStore, [ 'loadMore', 'setDateRange' ])
    }
}
</script>
