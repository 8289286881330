<template>
    <ui-async :guards="sources?.data">
        <div v-if="sources?.data.length" class="w-full mb-4 py-4 px-5 space-y-8">
            <sources-bar :sources="sources.data" caption="Platforms" obj_key="type"/>
            <sources-bar :sources="reports.data" caption="Languages" obj_key="language"/>
        </div>

        <div class="py-4 px-5 space-y-4 border-t border-gray-500 w-full">
            <div class="pb-2">
                <div class="float-right">
                    Order by:
                    <span class="text-gerulata-green-100 cursor-pointer">
                        Alphabetically
                        <ui-icon name="chevron-down"></ui-icon>
                    </span>
                </div>
                <div class="font-semibold text-gray-100">Sources</div>
            </div>

            <ui-async :guards="sources?.data">
                <sources-list :sources="sortedSources()"></sources-list>
            </ui-async>
        </div>
    </ui-async>
</template>


<script>
import SourcesBar from '../partials/sources-bar'
import SourcesList from '../partials/sources-list'

import useDetailsStore from "@/stores/overview/details"

import { mapState } from 'pinia'

export default {
    components: {
        SourcesBar, SourcesList
    },

    computed: {
        ...mapState(useDetailsStore, [ 'sources', 'reports' ])
    },

    methods: {
        sortedSources() {
            if (this.sources.data) {
                return this.sources.data.sort((a, b) => a.title.localeCompare(b.title))
            }

            return null
        }
    }
}
</script>
