<template>
    <div class="group relative">
        <ui-help-button page="content-search" :background="'dark'"></ui-help-button>

        <div class="hidden group-hover:block absolute top-full right-0 pt-3">
            <div class="rounded p-4 drop-shadow-lg bg-gray-700 bg-opacity-90 backdrop-blur ring-1 ring-gray-600 ring-opacity-80 text-left w-72 text-sm space-y-4">
                <div class="font-medium text-gray-300">Quick Reference</div>

                <div>
                    <div class="text-gray-300">Find all of the keywords</div>
                    <div class="font-mono text-amber-400">covid vaccination</div>
                </div>

                <div>
                    <div class="text-gray-300">Find any of the keywords</div>
                    <div class="font-mono text-amber-400">covid OR corona OR coronavirus</div>
                </div>

                <div>
                    <div class="text-gray-300">Find phrase</div>
                    <div class="font-mono text-amber-400">"President Biden"</div>
                </div>

                <div>
                    <div class="text-gray-300">Exclude keyword</div>
                    <div class="font-mono text-amber-400">president -biden</div>
                </div>

                <div>
                    <div class="text-gray-300">Group keywords with multiple operators</div>
                    <div class="font-mono text-amber-400">(covid OR coronavirus) AND NOT vaccination</div>
                </div>

                <div>
                    <a @click.prevent="showHelp('content-search')" href="#" class="text-gerulata-green-100 hover:text-gerulata-green-300">Show full documentation</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useSupportHelpStore from '@/stores/support/help'

import { mapActions } from 'pinia'

export default {
    methods: {
        ...mapActions(useSupportHelpStore, { showHelp: 'show' }),
    }
}
</script>
