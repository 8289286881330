<template>
    <ui-modal name="data-providers-edit-custom-source-modal" title="Custom Source" max-width="1024px">
        <div class="space-y-3">
            <div class="flex items-center space-x-3">
                <div class="w-1/2">
                    <label for="name" class="block text-sm text-gray-300 font-medium mb-1">
                        Name
                    </label>

                    <ui-input id="name" placeholder="Custom Source Name" type="text" autocomplete="off"></ui-input>
                </div>

                <div class="w-1/2">
                    <label for="link" class="block text-sm text-gray-300 font-medium mb-1">
                        Link
                    </label>

                    <ui-input id="link" placeholder="Custom Source Link" type="text" autocomplete="off"></ui-input>
                </div>
            </div>

            <div>
                <label for="media" class="block text-sm text-gray-300 font-medium mb-1">
                    Media
                </label>

                <div class="h-36 rounded-lg border-2 border-dashed border-gray-300 flex items-center justify-center">
                    <div class="flex flex-col items-center text-gray-300">
                        <ui-icon class="text-6xl" name="image"></ui-icon>
                        <p class="mt-3 font-medium">Upload Images</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-6 flex justify-end">
            <ui-button color="green">Submit</ui-button>
        </div>
    </ui-modal>
</template>

<script>
export default {
    //
}
</script>
