<template>
    <div class="flex shrink-0 h-full shadow transition-all bg-gradient-to-b from-gray-600/80 to-gray-900/80 backdrop-blur text-gray-300 rounded-r-md">
        <div class="flex flex-col w-16 px-3 py-6 border-t border-r border-b border-gray-500/70 rounded">
            <div class="flex flex-col divide-y divide-gray-500">
                <nav class="space-y-2 pb-4 pt-1">
                    <div class="mb-4">
                        <workspace-icon></workspace-icon>
                    </div>

                    <router-link :to="{ name: 'overview' }" class="flex items-center justify-center w-full h-9 mx-auto text-2xl transition ease-in-out duration-150 hover:text-white" active-class="text-gerulata-green-100" v-tooltip.right="'Overview'">
                        <ui-icon name="navigation.overview"></ui-icon>
                    </router-link>
                </nav>

                <router-view name="navigation"></router-view>
            </div>

            <div class="flex flex-col divide-y divide-gray-500 space-y-4 mt-auto">
                <nav class="space-y-2">
                    <a @click.prevent="showQuickSearch({ hasFilterableWorkspaces: false, perspectiveStarters: true, tips: true })" href="#" class="flex items-center justify-center w-full h-9 mx-auto text-2xl transition ease-in-out duration-150 hover:text-white" v-tooltip.right="'Quick-Search'">
                        <ui-icon name="navigation.quick-search"></ui-icon>
                    </a>

                    <a @click.prevent="toggleSidebar('support-chat')" href="#" class="flex items-center justify-center w-full h-9 mx-auto text-2xl transition ease-in-out duration-150 hover:text-white relative" :class="{ 'text-gerulata-green-100': sidebar == 'support-chat' }" v-tooltip.right="'Support Chat'">
                        <ui-icon name="message-circle"></ui-icon>
                        <span class="absolute -right-1.5 block w-2 h-2 rounded-full bg-red-500" aria-hidden="true" v-if="unreadNotificationsCount"></span>
                    </a>

                    <router-link :to="{ name: 'settings.account' }" class="flex items-center justify-center w-full h-9 mx-auto text-2xl transition ease-in-out duration-150 hover:text-white" active-class="text-gerulata-green-100" v-tooltip.right="'Settings'">
                        <ui-icon name="navigation.settings"></ui-icon>
                    </router-link>
                </nav>

                <div class="flex items-center justify-center pt-6">
                    <ui-icon name="g" class="text-gray-300 text-4xl"></ui-icon>
                </div>
            </div>
        </div>

        <router-view name="sidebar"></router-view>
    </div>
</template>

<script>
import WorkspaceIcon from '@/components/me/workspaces/icon'

import useAppStore from '@/stores/app'
import useMyNotificationsStore from '@/stores/me/notifications'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        WorkspaceIcon
    },

    computed: {
        ...mapState(useAppStore, [ 'sidebar' ]),
        ...mapState(useMyNotificationsStore, [ 'unreadNotificationsCount' ])
    },

    methods: {
        ...mapActions(useAppStore, [ 'toggleSidebar' ])
    }
}
</script>
