<template>
    <radial-progress
        v-if="type === 'radial'"
        :total-steps="totalSteps"
        :completed-steps="completedSteps"
        innerStrokeColor="#d6dde0"
        start-color="#3f84f2"
        stop-color="#3f84f2"
    >
        <slot></slot>
    </radial-progress>
</template>
<script>
import RadialProgress from "vue3-radial-progress"

export default {
    components: { RadialProgress },

    props: { 'type': { default: 'radial' }, totalSteps: { default: 100 }, completedSteps: { default: 0 } }
}
</script>
