import api from '@/api'

import { defineStore } from 'pinia'
import { useModal } from '@/helpers'

export const useSourcesModalsHistoryDownloadStore = defineStore({
    id: 'sourcesModalsHistoryDownload',

    state: () => ({
        source: null
    }),

    getters: {
        isDownloaded(store) {
            return false
        },

        isSupported(store) {
            let supportedTypes = [
                'facebook-page', 'instagram-profile', 'telegram-channel', 'telegram-group', 'tiktok-user',
                'twitter-user', 'vkontakte-community', 'vkontakte-user', 'youtube-channel'
            ]

            return store.source?.family == 'sources' && supportedTypes.includes(store.source?.type)
        }
    },

    actions: {
        open(source) {
            this.$reset()

            this.source = source

            useModal().show('targets-history-download')
        },

        cancel() {
            useModal().hide('targets-history-download')
        },

        request() {
            api.route('monitor targets download-history', { family: this.source.family, id: this.source.id }).post()

            useModal().hide('targets-history-download')
        }
    }
})

export default useSourcesModalsHistoryDownloadStore
