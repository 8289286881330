<template>
    <div class="py-4 px-5 space-y-4">
        <ui-async :guards="events.data">
            <events-list :events="events.data"></events-list>

            <ui-infinite-loading @infinite="loadMoreEvents($event)"></ui-infinite-loading>
        </ui-async>
    </div>
</template>

<script>
import EventsList from '../partials/events-list'

import useDetailsStore from '@/stores/overview/details'

import { mapState } from 'pinia'

export default {
    components: {
        EventsList
    },

    computed: {
        ...mapState(useDetailsStore, [ 'events', 'loadMoreEvents' ])
    }
}
</script>