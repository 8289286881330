import { defineStore } from 'pinia'

export const useManagementSharingPage = defineStore({
    id: 'eventsSharingPage',

    actions: {
        async beforeEnter() {
            //
        }
    }
})

export default useManagementSharingPage
