<template>
    <div class="py-4 px-5 space-y-4">
        <timeline-list />
    </div>
</template>

<script>
import TimelineList from '@/components/overview/details/partials/timeline-list'

export default {
    components: {
        TimelineList
    },
}
</script>
