<template>
    <div class="pl-20 bg-gradient-to-b from-gray-600/80 to-gray-800/80 backdrop-blur shadow-sm mb-8 bg- sticky top-0 z-40">
        <div class="px-8 py-3.5 mx-auto" v-if="title">
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <router-link :to="back" class="text-gray-800 hover:text-gray-900" v-if="back">
                        <ui-icon name="back" class="text-xl mr-4"></ui-icon>
                    </router-link>

                    <div class="relative w-8 h-8 shrink-0 mr-4" v-if="avatar || avatarType">
                        <img :src="avatar" class="rounded-full w-full h-full shadow-sm" v-if="avatar">
                        <ui-avatar :type="avatarType" :name="title" class="drop-shadow-sm" v-else></ui-avatar>

                        <ui-icon :name="badge" class="absolute -right-px -bottom-px text-lg drop-shadow" :class="{ 'grayscale': badgeGrayscale }" style="--tw-drop-shadow: drop-shadow(0 -1px 0 white) drop-shadow(0 1px 0 white) drop-shadow(-1px 0 0 white) drop-shadow(1px 0 0 white);" v-if="badge"></ui-icon>
                    </div>

                    <ui-icon :name="icon" class="shrink-0 mr-2 text-3xl" v-else-if="icon"></ui-icon>

                    <div>
                        <h1 class="text-xl font-semibold leading-none">{{ title }}</h1>
                        <div class="text-gray-500 text-xs leading-none mt-1 flex space-x-1" v-if="subtitle || $slots.subtitle">
                            <slot name="subtitle">{{ subtitle }}</slot>
                        </div>
                    </div>
                </div>

                <div class="flex items-center space-x-4">
                    <slot name="actions"></slot>
                </div>
            </div>
        </div>
        <div class="border-t border-gray-200" v-if="$slots.navigation">
            <div class="px-8 pt-2 mx-auto space-x-8 flex">
                <slot name="navigation"></slot>
            </div>
        </div>
        <div class="border-t border-gray-200" v-if="$slots.subsection">
            <slot name="subsection"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'avatar', 'avatarType', 'back', 'badge', 'badgeGrayscale', 'icon', 'subtitle', 'title' ]
}
</script>
