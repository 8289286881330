<template>

    <div class="mx-auto w-full max-w-4xl bg-gray-600/80 backdrop-blur rounded-lg">
        <div class="border-b border-gray-700 px-4 py-5 sm:px-6">

            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-100">Manual Data Import</h1>
                    <p class="mt-2 text-sm text-gray-300">You can have Gerulata Sentinel ingest a batch of data by uploading a dataset. To learn more about the required dataset format, <a class="text-gerulata-green-100" href="#">read the documentation.</a></p>
                </div>
                <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button type="button" class="block rounded-md bg-gerulata-green-100 px-3 py-2 text-center text-sm font-semibold text-gray-800 shadow-sm hover:bg-gerulata-green-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Upload a dataset...
                    </button>
                </div>
            </div>

            <button type="button" class="mt-12 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6" />
                </svg>
                <span class="mt-2 block text-sm font-semibold text-gray-100">Upload a dataset file...</span>
            </button>
        </div>

    </div>
</template>

<script>

// import UIIcon from "@/components/ui/icon.vue";

export default {
    // components: {UIIcon},
    data() {
        return {
            people: [
                {
                    name: 'Test Third-party Application',
                    token: 'x34_3sfkjf4i34ifuh4iu33sf9o0',
                    status: 'Active',
                    lastContent: 'Never',
                }
                // More people...
            ]
        }
    }
}
</script>