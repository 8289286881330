import api from '@/api'
import { arrayWrap } from '@/helpers'

import useMyStore from './my'

import { defineStore } from 'pinia'

export const useTaggingStore = defineStore({
    id: 'tagging',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,
        isLocked: false,

        loadingPromise: null,

        selectedGroup: null
    }),

    getters: {
        tagGroups(store) {
            return store.items
        },

        tags(store) {
            return store.selectedGroup ? store.selectedGroup.tags : store.flattenedTags
        },

        flattenedTags(store) {
            return store.items.flatMap(g => g.tags)
        }
    },

    actions: {
        async initialize() {
            await this.load()

            this.selectedGroup = this.tagGroups.find(g => g.id == useMyStore().preferences['tagging.selectedGroup'])
        },

        initializeWith(items) {
            this.items = items
            this.isInitialized = true
            this.isLocked = true
        },

        async load(force = false) {
            if (this.isLocked) return
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me tags').get().json(res => {
                this.items = res.data.map(g => ({ ...g, tags: this.buildTagsTree(g.tags) }))

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        find(id) {
            return this.flattenedTags.find(t => t.id == id)
        },

        findGroup(id) {
            return this.items.find(t => t.id == id)
        },

        appliedTo(item) {
            return arrayWrap(item).reduce((applied, item) => {
                return [ ...applied, ...(item.tags || []).map(t => this.find(t.id)).filter(t => ! applied.includes(t)) ]
            }, [])
        },

        isAppliedTo(item, tag) {
            return this.appliedTo(item).find(t => t.id == tag.id)
        },

        clearTagsOn(item) {
            return arrayWrap(item).some(item => {
                api.route(this.resolveTagsRouteForItem(item), [ item.id ]).delete().res(() => {
                    item.tags = []
                })
            })
        },

        toggleTagOn(item, tag) {
            let method = this.isAppliedTo(item, tag) ? 'delete' : 'post'

            return arrayWrap(item).some(item => {
                api.route(this.resolveTagsRouteForItem(item), [ item.id, tag.id ])[method]().res(() => {
                    if (method == 'delete') {
                        item.tags = item.tags.filter(t => t.id != tag.id)
                    } else {
                        item.tags = [ ...item.tags, { ...tag } ]
                    }
                })
            })
        },

        selectGroup(group) {
            this.selectedGroup = group

            useMyStore().updatePreferences({ 'tagging.selectedGroup': group })
        },

        buildTagsTree(tags) {
            return tags
                .filter(tag => ! tag.parentId)
                .map(tag => ({ ...tag, children: tags.filter(c => c.parentId == tag.id) }))
        },

        resolveTagsRouteForItem(item) {
            return {
                events: 'sentinel events tags delete'
            }[item.family]
        }
    }
})

export default useTaggingStore
