<template>
    <router-view></router-view>

    <alert-edit></alert-edit>
</template>

<script>
import AlertEdit from '@/components/management/modals/alert-edit.vue'

export default {
    components: {
        AlertEdit
    }
}
</script>