import useMyStore from '@/stores/me/my'
import useSettingsUsersStore from '@/stores/management/users'

import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'

export const useNewUserModalStore = defineForm({
    id: 'settingsUsersModalsNewUser',

    inputs: () => ({
        name: null,
        email: null
    }),

    submitRequest() {
        return api.route('admin accounts organizations members store', [ useMyStore().user.organization.id ])
            .formData({ name: this.inputs.name, email: this.inputs.email })
            .post()
    },

    async onResponse(res) {
        useSettingsUsersStore().reloadOrganization()
        await useSettingsUsersStore().reloadUsers()

        this.isInvited = true
    },
    
    state: {
        isInvited: false
    },

    actions: {
        open() {
            this.reset()

            useModal().show('settings-new-user')
        },

        cancel() {
            useModal().hide('settings-new-user')
        }
    }
})

export default useNewUserModalStore
