import { defineStore } from 'pinia'

export const useManagementIndexPage = defineStore({
    id: 'eventsIndexPage',

    actions: {
        async beforeEnter() {
            //
        }
    }
})

export default useManagementIndexPage
