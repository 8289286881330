<template>
    <ui-modal name="overview-geofeature-import" title="Geography Group Import" width="100%" height="auto" :max-width="600" :max-height="480" @opened="opened" @closed="cancel">
        <ui-form :store="overviewModalsGeofeatureImportStore" v-slot="{ inputs, submitting, errors }">
            <div class="mb-4">
                <label for="name" class="block text-sm text-gray-400 font-medium mb-1">
                    Name
                </label>

                <div>
                    <ui-input type="text" name="name" id="name" placeholder="e.g. Paramilitary Groups" ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                </div>
            </div>

            <div>
                <label for="geojson" class="block text-sm text-gray-400 font-medium mb-1">
                    GeoJSON
                </label>

                <div>
                    <input type="file" name="geojson" id="geojson" @change="event => inputs.geojson = event.target.files[0]">
                </div>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button type="submit" color="green" :disabled="submitting">
                    Import
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useGeofeatureImportModalStore from '@/stores/overview/modals/geofeature-import'

import { mapActions, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useGeofeatureImportModalStore)
    },

    methods: {
        ...mapActions(useGeofeatureImportModalStore, {
            'cancel': 'cancel', 'submit': 'submit'
        }),

        opened() { this.$refs.nameInput.focus() }
    }
}
</script>
