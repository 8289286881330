import api, { asyncResource } from '@/api'

import useAccountSettingsFormStore from "@/stores/settings/account-form";
import useMyPushStore from '@/stores/me/push'

import {defineStore} from 'pinia'

export const useAccountSettingsStore = defineStore({
    id: 'accountSettings',

    state: () => ({
        loading: false,
        loadingSubscriptions: false,

        currentProfilePhotoUrl: null,
        hasTwoFactorAuth: false,
        hasFacebookLogin: false,
        nativeNotificationsNewlyEnabled: false,

        timezones: [],
        languages: [],

        sessions: asyncResource({
            request: (api, store, payload) => api.route('me sessions index')
        })
    }),

    getters: {
        hasNativeNotificationsEnabled(state) {
            return state.nativeNotificationsNewlyEnabled || Notification.permission === 'granted'
        }
    },

    actions: {
        load() {
            this.loading = true
            this.loadingSubscriptions = true

            Promise.all([
                api.route('settings account').get().json(),
                api.route('settings timezones').get().json(),
                api.route('settings languages').get().json()
            ]).then(values => {
                useAccountSettingsFormStore().inputs.name = values[0].name
                useAccountSettingsFormStore().inputs.email = values[0].email

                this.currentProfilePhotoUrl = values[0].current_profile_photo_url
                this.hasTwoFactorAuth = values[0].has_two_factor_auth
                this.hasFacebookLogin = values[0].has_facebook_login

                this.timezones = values[1].data
                this.languages = values[2].data

                this.loading = false
            })

            api.route('settings subscriptions').get()
                .json(res => {
                    useAccountSettingsFormStore().inputs.newsletter_subscription = res.data.newsletter
                    useAccountSettingsFormStore().inputs.platform_updates_subscription = res.data.platform_updates

                    this.loadingSubscriptions = false
                })

            this.sessions.fetch(this)
        },

        enableNativeNotifications() {
            return Notification.requestPermission().then(status => {
                this.nativeNotificationsNewlyEnabled = true
                useMyPushStore().setup()

                return status === 'granted'
            })
        },

        async closeSession(tokenId) {
            await api.route('me sessions delete', [ tokenId ]).delete()
            this.sessions.fetch(this)
        }
    },
})

export default useAccountSettingsStore
