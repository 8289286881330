import { defineStore } from 'pinia'
import { markRaw } from 'vue'

import GeoFeaturesSidebar from '@/components/overview/sidebars/geo-features'
import ModerationSidebar from '@/components/management/moderated-events/overlay/sidebar'
import NotificationsSidebar from '@/components/notifications/sidebar'
import PerspectivesSidebar from '@/components/overview/sidebars/perspectives'
import SupportChatSidebar from '@/components/support/chat/overlay'

import useManagementModeratedEventsOverlayStore from '@/stores/management/moderated-events/overlay'
import useMyNotificationsStore from '@/stores/me/notifications'
import useSupportChatStore from '@/stores/support/chat'

export const useAppStore = defineStore({
    id: 'app',

    state: () => ({
        sidebar: null,
        sidebarOptions: { compact: false, backdrop: true },
        
        sidebarComponents: markRaw({
            'geo-features': GeoFeaturesSidebar,
            'moderation': ModerationSidebar,
            'notifications': NotificationsSidebar,
            'perspectives': PerspectivesSidebar,
            'support-chat': SupportChatSidebar
        }),
        sidebarStores: markRaw({
            'moderation': useManagementModeratedEventsOverlayStore,
            'notifications': useMyNotificationsStore,
            'support-chat': useSupportChatStore
        })
    }),
    
    getters: {
        sidebarComponent: store => store.sidebarComponents[store.sidebar],
        sidebarStore: store => store.sidebarStores[store.sidebar]
    },

    actions: {
        toggleSidebar(sidebar) {
            this.sidebar == sidebar ? this.hideSidebar() : this.showSidebar(sidebar)
        },

        showSidebar(sidebar) {
            if (this.sidebar != sidebar) this.hideSidebar()

            this.sidebar = sidebar
            this.sidebarStore?.().open?.()
        },

        hideSidebar() {
            this.sidebarStore?.().close?.()
            this.sidebar = null
        },

        setSidebarOptions(options) {
            this.sidebarOptions = { ...this.sidebarOptions, ...options }
        }
    }
})

export default useAppStore
