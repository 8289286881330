import useMyStore from '@/stores/me/my'
import { defineForm } from '@/stores/reusable/form'

import api from '@/api'

export const useAuthLoginFormStore = defineForm({
    id: 'authLoginForm',

    state: {
        isShowingTwoFactorResetHelp: false,
        isTwoFactorAuthCodeRequired: false
    },

    inputs: () => ({
        email: null,
        password: null,
        twoFactorAuthCode: null,

        name: null,
        passwordConfirmation: null,
        activationToken: null
    }),

    async submitRequest() {
        return api.route('auth login')
            .formData({
                email: this.inputs.email,
                password: this.inputs.password,
                twoFactorAuthCode: this.inputs.twoFactorAuthCode,

                name: this.inputs.name,
                passwordConfirmation: this.inputs.passwordConfirmation,
                activationToken: this.inputs.activationToken,

                product: 'sentinel'
            })
            .post()
            .error(403, err => {
                if (err.json.code === 'missing_two_factor_auth_code') { this.startRequiringTwoFactorAuthCode() }

                this.errors = { other: err.json.message }
                this.submitting = false
            })
    },

    async onResponse(res) {
        useMyStore().loggedIn(res.token)
    },

    actions: {
        toggleIsShowingTwoFactorResetHelp() {
            this.isShowingTwoFactorResetHelp = ! this.isShowingTwoFactorResetHelp
        },

        startRequiringTwoFactorAuthCode() {
            this.isTwoFactorAuthCodeRequired = true
        },

        stopRequiringTwoFactorAuthCode() {
            this.isTwoFactorAuthCodeRequired = false
        },

        setActivationToken(token) {
            this.inputs.activationToken = token
        },

        setUser(user) {
            this.inputs.email = user.email
            this.inputs.name = user.name
        }
    }
})

export default useAuthLoginFormStore
