import useMyGeoFeaturesStore from '@/stores/me/geo-features'

import api from '@/api'

import { defineForm } from '@/stores/reusable/form'

import { useModal } from '@/helpers'

export const useGeofeatureImportModalStore = defineForm({
    id: 'overviewModalsGeofeatureImport',

    inputs: () => ({
        name: null,
        geojson: null
    }),

    submitRequest() {
        return api.route('sentinel geo-features import')
            .formData({
                name: this.inputs.name,
                geojson: this.inputs.geojson
            })
            .post()
    },

    async onResponse(res) {
        useMyGeoFeaturesStore().reloadLayers()
        
        useModal().hide('overview-geofeature-import')

        this.fulfillPromise(res.data)
    },

    actions: {
        open() {
            this.reset()

            useModal().show('overview-geofeature-import')

            return this.makePromise()
        },

        cancel() {
            useModal().hide('overview-geofeature-import')

            this.fulfillPromise()
        }
    }
})

export default useGeofeatureImportModalStore
