<template>
    <div class="bg-gray-950 min-h-screen relative">
        <div class="mx-auto max-w-7xl w-full pt-4 pb-4 text-center lg:text-left max-h-screen overflow-auto">
            <div class="px-4 sm:px-8 my-3">
                <img src="@/assets/gerulata.svg" alt="Gerulata" class="w-32 h-auto mb-4">
            </div>
            <div class="px-4 lg:w-1/2 sm:px-8 lg:py-32 xl:pr-32">
                <h1 class="text-3xl tracking-tight font-bold text-gray-200 sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl">
                    <span class="inline">Activate</span><br>
                    <span class="inline">your account</span>
                </h1>

                <div class="mt-3 max-w-md mx-auto text-lg md:mt-5 md:max-w-3xl">
                    <div class="border border-red-700 bg-red-900 text-red-100 px-4 py-2 text-sm mb-2 rounded" v-if="$page.invalidToken">
                        <p>Your activation link is not valid, please make sure you follow the link from your invitation email or <a href="mailto:support@gerulata.com">contact us</a> for support.</p>
                    </div>

                    <div class="border border-red-700 bg-red-900 text-red-100 px-4 py-2 text-sm mb-2 rounded" v-if="socialLoginError">
                        <p>{{ socialLoginError }}</p>
                    </div>
                </div>

                <div class="mt-3 max-w-md mx-auto text-md md:mt-8 md:max-w-3xl">
                    <ui-form :store="authLoginFormStore" v-slot="{ inputs, submitting, errors }" class="space-y-6">
                        <div class="border border-red-700 bg-red-900 text-red-100 px-4 py-2 text-sm mb-2 rounded" v-if="errors && errors.other">
                            <p :key="error" v-for="error in errors.other">{{ error }}</p>
                        </div>

                        <div>
                            <label for="name" class="block text-sm text-gray-200 font-medium mb-1.5">
                                Name
                            </label>

                            <div>
                                <ui-input type="text" autocomplete="name" required v-model="inputs.name" :errors="errors.name"></ui-input>
                            </div>
                        </div>

                        <div>
                            <label for="email" class="block text-sm text-gray-200 font-medium mb-1.5">
                                Email address
                            </label>

                            <div>
                                <ui-input type="email" autocomplete="email" required v-model="inputs.email" :errors="errors.email"></ui-input>
                            </div>
                        </div>

                        <div>
                            <label for="email" class="block text-sm text-gray-200 font-medium mb-1.5">
                                Password
                            </label>

                            <div>
                                <ui-input type="password" required v-model="inputs.password" :errors="errors.password"></ui-input>
                            </div>
                        </div>

                        <div>
                            <label for="email" class="block text-sm text-gray-200 font-medium mb-1.5">
                                Confirm Password
                            </label>

                            <div>
                                <ui-input type="password" required v-model="inputs.passwordConfirmation" :errors="errors.passwordConfirmation"></ui-input>
                            </div>
                        </div>

                        <div>
                            <button type="submit" class="inline-flex items-center justify-center py-3 px-20 rounded-md bg-gerulata-green-100 hover:bg-gerulata-green-300 text-gray-800 hover:text-gray-950 shadow-sm font-medium text-lg" :disabled="submitting">
                                <template v-if="submitting">
                                    <ui-spinner type="clip" color="#A9EE8A" class="mr-2"></ui-spinner>
                                    Activating...
                                </template>
                                <template v-else>
                                    Activate
                                </template>
                            </button>
                        </div>
                    </ui-form>
                </div>
            </div>
        </div>
        <div class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
            <img class="absolute inset-0 w-full h-full object-cover" src="@/assets/auth-cover.jpg" alt="">
        </div>
    </div>
</template>

<script>
import useAuthLoginFormStore from '@/stores/auth/login-form'
import useAuthSocialLoginStore from '@/stores/auth/social-login'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useAuthLoginFormStore),
        ...mapState(useAuthSocialLoginStore, { socialLoginError: 'error' })
    },

    methods: {
        ...mapActions(useAuthSocialLoginStore, { initiateSocialLogin: 'initiate' })
    }
}
</script>

