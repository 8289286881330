import useManagementWorkspacesStore from '@/stores/management/workspaces'
import useManagementAlertsStore from '@/stores/management/alerts/alerts'
import useManagementPerspectivesStore from '@/stores/management/perspectives'

import { defineStore } from 'pinia'

export const useManagementAlertsPage = defineStore({
    id: 'managementAlertsPage',

    actions: {
        async afterEnter() {
            useManagementPerspectivesStore().initialize()
            useManagementWorkspacesStore().initialize()
            useManagementAlertsStore().initialize()
        },

        async beforeLeave() {
            //
        }
    }
})

export default useManagementAlertsPage
