<template>
    <div class="py-4 px-5 space-y-2">
        {{ event.description }}
    </div>

    <div class="py-4 px-5 space-y-2">
        <div v-if="event.location.address">
            <div class="text-sm text-gray-300 w-28 inline-block">Location</div>
            {{ event.location.address }}
        </div>

        <div v-if="event.location.country">
            <div class="text-sm text-gray-300 w-28 inline-block">Country</div>
            {{ event.location.country }}
        </div>

        <div v-if="event.category">
            <div class="text-sm text-gray-300 w-28 inline-block">Category</div>
            <div class="inline-flex items-center bg-gray-600 font-medium inline-block px-2 rounded">
                <div class="w-3 h-3 rounded-full inline-block mr-2" :style="{ backgroundColor: category.color }"></div>
                <span>{{ category.name }}</span>
            </div>
        </div>

        <div v-if="event.casualties">
            <div class="text-sm text-gray-300 w-28 inline-block">Casualties</div>
            {{ event.casualties }}
        </div>

        <div v-if="event.startedAt">
            <div class="text-sm text-gray-300 w-28 inline-block">Event Date</div>
            {{ $dateTime(event.startedAt) }}
        </div>

        <div v-if="event.createdAt">
            <div class="text-sm text-gray-300 w-28 inline-block">First reported At</div>
            {{ $dateTime(event.createdAt) }}
        </div>

        <div v-if="event.endedAt">
            <div class="text-sm text-gray-300 w-28 inline-block">
                Finishes At
            </div>
            {{ $dateTime(event.endedAt) }}
        </div>

        <div v-if="event.tags?.length" class="flex items-top">
            <div class="text-sm text-gray-300 w-28 inline-block">Tags</div>
            <div class="flex flex-wrap space-x-3">
                <div v-for="tag in event.tags" :key="tag.id" class="inline-flex items-center bg-gray-600 font-medium inline-block px-2 rounded">
                    <div class="w-3 h-3 rounded-full inline-block mr-2" :class="`bg-${tag.color}`"></div>
                    <span>{{tag.name}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="py-4 px-5 space-y-4 border-t border-gray-500 w-full">
        <h3 class="font-semibold">Timeline</h3>
        <timeline-list limit="3" />
        <a @click.prevent="shownTab = 'timeline'" class="text-gerulata-green-100 cursor-pointer block">
            Show all <ui-icon name="chevron-right"></ui-icon>
        </a>
    </div>

    <div class="py-4 px-5 space-y-4 border-t border-gray-500 w-full">
        <h3 class="font-semibold">Sources</h3>
        <sources-list :sources="sources?.data"></sources-list>
        <a @click.prevent="shownTab = 'sources'" class="text-gerulata-green-100 mt-2.5 cursor-pointer block" v-if="sources?.data">
            Show all <ui-icon name="chevron-right"></ui-icon>
        </a>
    </div>
</template>

<script>
import TimelineList from '@/components/overview/details/partials/timeline-list'
import SourcesList from '@/components/overview/details/partials/sources-list'

import useDetailsStore from '@/stores/overview/details'
import useOverviewStore from '@/stores/overview/overview'
import useEventCategoriesStore from "@/stores/me/event-categories"

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {

    components: {
        TimelineList, SourcesList
    },

    computed: {
        ...mapState(useDetailsStore, [ 'event', 'sources' ]),
        ...mapWritableState(useDetailsStore, [ 'shownTab' ]),

        category() { return useEventCategoriesStore().find(this.event.category) }
    },

    methods: {
        ...mapActions(useOverviewStore, [ 'hideSidebar' ])
    }
}
</script>