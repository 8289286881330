import api from '@/api'

import { defineStore } from 'pinia'

export const useMyGeoFeaturesStore = defineStore({
    id: 'my-geo-features',

    state: () => ({
        layers: [],
        features: {},

        isInitialized: false,
        isLoading: false,
        isLocked: false,
        loadingPromise: null,
        
        isLoadingLayer: {},
        layerLoadingPromise: {},

        openLayers: [],
        loadedLayers: [],

        colors: [
            { id: 'orange', name: 'Orange' },
            { id: 'yellow', name: 'Yellow' },
            { id: 'lime', name: 'Lime' },
            { id: 'green', name: 'Green' },
            { id: 'blue', name: 'Blue' },
            { id: 'purple', name: 'Purple' },
            { id: 'violet', name: 'Violet' },
            { id: 'gray', name: 'Gray' }
        ]
        // Force-generate tailwind classes - bg-marker-orange bg-marker-yellow bg-marker-lime bg-marker-green bg-marker-blue bg-marker-purple bg-marker-violet bg-marker-gray
        // text-marker-orange text-marker-yellow text-marker-lime text-marker-green text-marker-blue text-marker-purple text-marker-violet text-marker-gray
    }),
    
    getters: {
        shapes() {
            return this.items
        },
        
        points() {
            return this.items
        }
    },

    actions: {
        async initialize() {
            return this.loadLayers()
        },

        initializeWith(layers) {
            this.layers = layers
            this.loadedLayers = this.layers.map(l => l.id)
            this.isInitialized = true
            this.isLocked = true
        },

        async loadLayers(force = false) {
            if (this.isLocked) return
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('sentinel geo-features layers').get().json(res => {
                this.layers = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async loadMoreFeatures(layer, infiniteScroll) {
            if (this.isLocked) return

            let layerFeatures = this.layerFeatures(layer)

            this.isLoadingLayer[layer.id] = true

            return api.route('sentinel geo-features features', [ layer.id ]).query({
                skip: layerFeatures.length,
                limit: 100
            }).get().json(res => {
                if (res.data.length) {
                    this.features[layer.id] = [ ...layerFeatures,  ...(res.data || []) ]
                    infiniteScroll.loaded()
                } else {
                    this.loadedLayers.push(layer.id)
                    infiniteScroll.complete()
                }

                this.isLoadingLayer[layer.id] = false
            })
        },

        async reloadLayers() {
            if (this.isLocked) return

            this.features = []
            this.loadedLayers = []

            return this.loadLayers(true)
        },

        findLayer(id) {
            return this.layers.find(l => l.id == id)
        },

        layerFeatures(layer) {
            return this.features[layer?.id] || []
        },

        async findFeature(layer, id) {
            return this.layerFeatures(layer).find(f => f.id == id)
                || await api.route('sentinel geo-features features details', [ layer.id, id ]).get().json(res => res.data)
        },

        async updateFeature(feature, data) {
            await api.route('sentinel geo-features features update', [ feature.layerId, feature.id ])
                .formData({ _method: 'put', ...data, geometry: feature.geometry ? JSON.stringify(feature.geometry) : '' })
                .post()
                .res()

            return this.reloadLayers()
        },

        async deleteFeature(feature, force = false) {
            await api.route('sentinel geo-features features delete', [ feature.layerId, feature.id ])
                .formData({ _method: 'delete', force: force ? 1 : 0 })
                .post()
                .res()

            return this.reloadLayers()
        },

        async updateLayer(layer, data) {
            await api.route('sentinel geo-features layers update', layer.id)
                .formData({ _method: 'put', ...data })
                .post()
                .res()

            return this.reloadLayers()
        },

        async deleteLayer(layer, force = false) {
            await api.route('sentinel geo-features layers delete', layer.id)
                .formData({ _method: 'delete', force: force ? 1 : 0 })
                .post()
                .res()

            return this.reloadLayers()
        },

        isLayerOpen(layer) {
            return this.openLayers.includes(layer.id)
        },

        toggleLayer(layer) {
            return this.isLayerOpen(layer) ? this.openLayers = this.openLayers.filter(id => id != layer.id) : this.openLayers.push(layer.id)
        }
    }
})

export default useMyGeoFeaturesStore
