import useEventCategoriesStore from '@/stores/me/event-categories'
import useOverviewStore from '@/stores/overview/overview'

import { default as api, asyncResource } from '@/api'
import { sortByDate } from "@/helpers"

import { defineStore } from 'pinia'

export const useDetailsStore = defineStore({
    id: 'details',

    state: () => ({
        isShown: null,
        isLoading: false,
        options: { compact: false, backdrop: true },

        shownTab: 'overview',

        event: null,
        cluster: null,
        filters: null,

        events: asyncResource({
            method: 'post',
            request: (api, store, payload) => api.route('sentinel events').json({
                filters: store.filters.toJson(),
                ids: store.cluster,
                sorting: store.filters.value('semantic') ? 'relevance' : 'date-desc',
                limit: 50,
                publishedPerspective: store.publishedToken,
                ...payload
            }),
            paginated: true
        }),

        activity: asyncResource({
            request: (api, store) => api.route('sentinel events activity', [ store.event.id ])
        }),

        metrics: asyncResource({
            request: (api, store) => api.route('sentinel events metrics', {
                ids: store.event.id,
                publishedPerspective: store.publishedToken
            })
        }),

        reports: asyncResource({
            method: 'post',
            request: (api, store, payload) => api.route('sentinel events reports').json({
                ids: store.cluster || store.event?.id,
                filters: store.event ? null : store.filters.toJson(),
                sorting: store.filters.value('semantic') ? 'relevance' : 'date-desc',
                limit: 50,
                publishedPerspective: store.publishedToken,
                ...payload
            })
        }),

        sources: asyncResource({
            request: (api, store) => api.route('sentinel sources', {
                ids: store.event.id,
                publishedPerspective: store.publishedToken
            })
        }),

        trends: [],

        publishedToken: null,

        platforms: {
            "facebook-page": { "label": "Facebook",  "color": "#4A66AD" },
            "instagram-profile": { "label": "Instagram", "color": "#B24182" },
            "telegram-channel": { "label": "Telegram", "color": "#5C96B8" },
            "telegram-group": { "label": "Telegram", "color": "#5C96B8" },
            "telegram-user": { "label": "Telegram", "color": "#5C96B8" },
            "tiktok-user": { "label": "Tik Tok", "color": "#EA3355" },
            "twitter-user": { "label": "Twitter", "color": "#59C2D0" },
            "vkontakte-user": { "label": "VKontakte", "color": "#BC271A" },
            "vkontakte-community": { "label": "VKontakte", "color": "#BC271A" },
            "web-feed": { "label": "Web", "color": "#E8A245" },
            "youtube-channel": { "label": "Youtube", "color": "#DF5343" }
        },
    }),

    getters: {
        timeline(store) {
            if (! store.reports.data || ! store.activity.data) return null

            return sortByDate([
                ...store.reports.data.map(report => ({
                    id: report.id,
                    type: 'reported',
                    icon: 'topic',
                    causer: { ...report.content?.source || {}, name: report.content?.source.title || "" },
                    report,
                    createdAt: report.content?.publishedAt || report.createdAt
                })),
                ...store.activity.data.map(activity => ({
                    ...activity,
                    icon: {
                        'sentinel.events.tag-added': 'tag',
                        'sentinel.events.tag-removed': 'tag'
                    }[activity.type]
                }))
            ]).reverse()
        },

        images(store) {
            return store.reports.data ? Array.from(new Set(store.reports.data.flatMap(report => report.images))) : []
        }
    },

    actions: {
        async open(options = { event: null, cluster: null }) {
            this.shownTab = 'overview'
            this.isLoading = true
            this.isShown = true

            if (options.event) {
                await this.showEvent(options.event)
            } else {
                await this.showResults(options.cluster)
            }

            this.isLoading = false
        },

        close() {
            this.events.reset()

            this.isShown = false
        },

        setOptions(options) {
            this.options = { ...this.options, ...options }
        },

        initializeAsGuest(token) {
            this.publishedToken = token
        },

        async showResults(cluster) {
            this.trends = []

            this.cluster = cluster
            this.event = null

            await Promise.all([
                this.events.fetchFirst(this),
                this.reports.fetchFirst(this)
            ])

            this.loadTrends()

            this.shownTab = 'overview'
        },

        async showEvent(event) {
            this.event = null
            this.cluster = null

            this.activity.reset()
            this.metrics.reset()
            this.reports.reset()
            this.sources.reset()

            this.event = event instanceof Object ? event : await api.route('sentinel events detail', [ event ]).get().json(res => res.data)

            if (event) {
                this.activity.fetch(this)
                this.metrics.fetch(this)
                this.reports.fetch(this)
                this.sources.fetch(this)
            }

            this.shownTab = 'overview'
        },

        hideEvent() {
            this.event = null

            this.shownTab = 'overview'
        },

        setFilters(filters) {
            this.filters = filters

            this.events.reset()
        },

        loadTrends() {
            let filteredLayers = useOverviewStore().mapLayers.filter(l => l.filter === true || l.filter?.include?.length || l.filter?.exclude?.length)

            if (! filteredLayers.length) {
                return this.trends = []
            }

            api.route('sentinel geo-features trends')
                .post({
                    geoLayers: filteredLayers.map(l => ({ id: l.id, include: l.filter?.include, exclude: l.filter?.exclude }))
                })
                .error(403)
                .json(res => this.trends = res.filter(r => useEventCategoriesStore().find(r.category)))
        },

        async loadMoreEvents(infiniteScroll) {
            let items = await this.events.fetchNext(this)

            items.length ? infiniteScroll.loaded() : infiniteScroll.complete()
        },

        async loadMoreReports(infiniteScroll) {
            let items = await this.reports.fetchNext(this)

            items.length ? infiniteScroll.loaded() : infiniteScroll.complete()
        }
    }
})

export default useDetailsStore
