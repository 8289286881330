import useMyStore from '@/stores/me/my'

import { asyncResource } from '@/api'

import { defineStore } from 'pinia'

export const useAuditLogStore = defineStore({
    id: 'settingsAuditLog',

    state: () => ({
        records: asyncResource({
            method: 'post',
            request: (api, store, payload) => api
                .route('admin accounts organizations activity-log', [ useMyStore().user.organization.id ])
                .json({
                    type: store.types.length ? store.types : [
                        'auth.activated',
                        'auth.logged-in',
                        'auth.logged-out',
                        'auth.password-reset',
                        'management.users.created',
                        'management.users.deleted',
                        'management.users.updated',
                        'me.workspaces.created',
                        'me.workspaces.deleted',
                        'me.workspaces.updated',
                        'me.workspaces.member-added',
                        'me.workspaces.member-removed',
                        'monitoring-requests.created',
                        'sentinel.events.tag-added',
                        'sentinel.events.tag-removed',
                    ],
                    dateRange: store.dateRange,
                    ...payload
                }),
            paginated: true
        }),
        
        dateRange: null,
        types: []
    }),

    actions: {
        reload() {
            this.records.reset()
            this.records.fetchFirst(this)
        },
        
        async loadMore(infiniteScroll) {
            let items = await this.records.fetchNext(this)
            
            if (items.length) {
                infiniteScroll.loaded()
            } else {
                infiniteScroll.complete()
            }
        },
        
        setDateRange(range) {
            this.dateRange = range
            this.reload()
        },
        
        clearType() {
            this.types = []
            this.reload()
        },
        
        toggleType(type) {
            this.types.includes(type) ? this.types = this.types.filter(t => t != type) : this.types.push(type)
            this.reload()
        }
    }
})

export default useAuditLogStore
