import useSourceCredibilityStore from '@/stores/settings/credibility/source-credibility'

import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'

export const useNewOverrideModalStore = defineForm({
    id: 'settingsCredibilityNewOverrideEdit',

    inputs: () => ({
        source: null,
        credibility: null
    }),

    submitRequest() {
        return api.route('monitor targets credibility', { id: this.inputs.source.id })
            .json({
                _method: 'put',
                credibility: this.inputs.credibility
            })
            .post()
    },

    async onResponse(res) {
        useSourceCredibilityStore().reload()

        useModal().hide('settings-credibility-new-override')
    },

    actions: {
        open(source) {
            this.reset()
            
            this.inputs.source = source

            useModal().show('settings-credibility-new-override')
        },

        cancel() {
            useModal().hide('settings-credibility-new-override')
        }
    }
})

export default useNewOverrideModalStore
