<template>
    <ui-modal name="image-preview" title="Image" width="100%" height="auto" @opened="opened" @closed="close">
        <img :src="src" class="mx-auto" />
    </ui-modal>
</template>

<script>


import { mapActions, mapState } from "pinia";
import useImagePreviewModalStore from "@/stores/overview/modals/image-preview";

export default {
    computed: {
        ...mapState(useImagePreviewModalStore, [ 'src' ]),
    },

    methods: {
        ...mapActions(useImagePreviewModalStore, [ 'close' ]),
    }
}
</script>
