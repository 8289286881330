<template>
    <search-filter name="Language" :ids="['language']" :filters="filters" align="right" @opened="reset">
        <template v-slot:applied>
            <ui-icon :name="value[0].icon" class="mr-1" :class="value[0].iconClass" v-if="value.length === 1 && value[0].icon"></ui-icon>

            <span class="font-medium" v-if="value.length > 1">
                {{value.length}} languages
            </span>
            <span class="font-medium" v-else>
                {{value.length ? value[0].name : value.name}}
            </span>
        </template>

        <template v-slot:options>
            <div class="w-64 max-h-80 overflow-y-auto">
                <div class="px-4">
                    <div class="relative w-full h-8">
                        <div class="absolute inset-y-0 left-2 text-gray-500 flex items-center pointer-events-none">
                            <ui-icon name="search" class="text-sm"></ui-icon>
                        </div>
                        <input placeholder="Search..." type="search" autocomplete="off" class="block border-0 h-full w-full pl-7 rounded-sm bg-gray-800 text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-0 focus:placeholder-gray-400 text-sm" ref="searchInput" v-model="search">
                    </div>
                </div>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <template v-for="option, index in options">
                    <ui-dropdown-link :icon="option.icon" checkbox :selected="localItems.includes(option.id)" @click="toggleLocalItem(option)" :key="`link-${index}`" v-if="option.id">
                        {{option.name}}
                    </ui-dropdown-link>

                    <ui-dropdown-separator class="my-2" :key="`separator-${index}`" v-else></ui-dropdown-separator>
                </template>

                <div class="text-gray-700 text-sm text-center" v-if='! options.length'>
                    No results
                </div>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3 text-gray-300">
                <a href="#" class="flex items-center" @click="filters.remove('language'); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="green" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import languageFilterOptions from '@/stores/me/languages'

import { textSearch } from '@/helpers'

export default {
    components: {
        SearchFilter
    },

    props: [ 'filters' ],

    data: () => ({
        localItems: [],
        search: ''
    }),

    computed: {
        options() {
            return textSearch(this.search, languageFilterOptions, o => o.name)
        },

        value() {
            return (this.filters.value('language') || []).map(v => languageFilterOptions.find(o => o.id == v))
        }
    },

    methods: {
        toggleLocalItem(item) {
            this.localItems.includes(item.id) ? this.localItems.splice(this.localItems.findIndex(v => v === item.id), 1) : this.localItems.push(item.id)
        },

        reset() {
            this.localItems = [ ...(this.filters.value('language') || []) ]
            this.search = ''
        },

        apply() {
            this.localItems.length ? this.filters.set('language', this.localItems) : this.filters.remove('language')
        }
    }
}
</script>
