<template>
    <ui-modal name="settings-edit-workspace" :title="workspace?.id ? 'Edit Workspace' : 'New Workspace'" width="100%" height="auto" :max-width="480" @closed="cancel">
        <ui-form :store="settingsUsersModalsEditWorkspaceStore" v-slot="{ inputs, submitting, errors }">
            <div class="flex space-x-6">
                <div class="flex-1">
                    <div>
                        <label for="name" class="block text-sm text-gray-200 font-medium mb-1.5">
                            Name
                        </label>

                        <div>
                            <ui-input type="text" name="name" id="name" ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                        </div>
                        
                        <div class="mt-2 text-sm text-gray-300">
                            Name of the workspace for display purposes.
                        </div>
                    </div>

                    <div class="mt-6">
                        <label for="description" class="block text-sm text-gray-200 font-medium mb-1.5">
                            Description
                        </label>

                        <div>
                            <ui-input type="text" name="description" id="description" ref="descriptionInput" v-model="inputs.description" :errors="errors.description"></ui-input>
                        </div>
                        
                        <div class="mt-2 text-sm text-gray-300">
                            Optionally describe the purpose of the workspace.
                        </div>
                    </div>
                </div>

                <div class="w-26">
                    <div class="mt-6">
                        <ui-avatar-upload :src="inputs.workspace.avatarUrl" v-model="inputs.avatar"></ui-avatar-upload>
                    </div>
                </div>
            </div>

            <div class="text-center mt-6">
                <ui-button size="lg" color="green" type="submit" :disabled="submitting">{{ inputs.workspace?.id ? 'Update' : 'Create' }}</ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useEditWorkspaceModalStore from '@/stores/settings/users/modals/edit-workspace'

import { mapActions, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useEditWorkspaceModalStore),
        
        workspace() { return this.settingsUsersModalsEditWorkspaceStore.inputs.workspace }
    },

    methods: {
        ...mapActions(useEditWorkspaceModalStore, [ 'cancel', 'reset', 'submit' ])
    }
}
</script>
