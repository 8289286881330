import api from '@/api'
import { useModal } from '@/helpers'
import { targets } from '@/helpers/api-three-tables'

import { defineStore } from 'pinia'

export const useMyMonitoredSourcesStore = defineStore({
    id: 'my-monitored-sources',

    state: () => ({
        items: [],

        search: '',
        filter: 'all-targets',

        isInitialized: false,
        isLoading: false,

        loadingPromise: null,
        lastReloadTime: +new Date(),

        filterOptions: [
            { id: 'all-targets', name: 'All Targets', family: '', monitoringOptions: [] },
            { id: 'only-sources', name: 'Only Sources', family: 'sources', monitoringOptions: [] },
            { id: 'only-groups', name: 'Only Groups', family: 'groups', monitoringOptions: [] }
        ]
    }),

    getters: {
        selectedFilter() {
            return this.filterOptions.find(o => o?.id == this.filter)
        }
    },

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.targetsQuery()
                .get()
                .then(targets => {
                    this.items = targets

                    this.isLoading = false
                    this.isInitialized = true

                    this.loadingPromise = null
                })
        },

        async reload() {
            this.lastReloadTime = +new Date()
            return this.load(true)
        },

        async loadMore(infiniteScroll) {
            let lastItem = this.items[this.items.length - 1]

            if (! lastItem) return

            this.targetsQuery()
                .after({ id: lastItem.id, family: lastItem.family })
                .get()
                .then(targets => {
                    if (targets.length) {
                        this.items = [ ...this.items, ...targets ]
                        infiniteScroll.loaded()
                    } else {
                        infiniteScroll.complete()
                    }
                })
        },

        async all() {
            await this.load()
            return this.items
        },

        setFilter(filter) {
            this.filter = filter
            this.reload()
        },

        targetsQuery() {
            return targets()
                .family(this.selectedFilter.family)
                .filters('list', 'my-targets')
                .filters('title', { query: this.search })
                .filters('monitoring-options', this.selectedFilter.monitoringOptions)
                .include(['monitoring-options'])
                .sorting('name-asc')
        },

        async addToMyTargets(target) {
            let route = target.family == 'groups' ? 'me lists groups store' : 'me lists sources store'

            await api.route(route, { id: 'my-targets', targetId: target.id })
                .post()
                .error(400, () => useModal().show('targets-my-targets-resource-unavailable'))
                .res(() => {
                    return api.route(`monitor targets details`, { family: target.family, id: target.id }).get().json(res => {
                        target.lists = res.data.lists
                    })
                })

            return this.reload()
        },

        async removeFromMyTargets(target) {
            let route = target.family == 'groups' ? 'me lists groups delete' : 'me lists sources delete'

            await api.route(route, { id: 'my-targets', targetId: target.id })
                .formData({ _method: 'delete' })
                .post()
                .res(() => {
                    return api.route(`monitor targets details`, { family: target.family, id: target.id }).get().json(res => {
                        target.lists = res.data.lists
                    })
                })

            return this.reload()
        },

        isMonitored(target) {
            return target.lists?.find(l => l.type == 'my-targets')
        }
    }
})

export default useMyMonitoredSourcesStore
