<template>
    <div class="mx-auto w-full max-w-4xl bg-gray-600/80 backdrop-blur rounded-lg py-4">
        <h1 class="text-gray-300 font-medium flex items-center space-x-2 px-6">
            <ui-icon name="navigation.moderated-events"></ui-icon>
            <span>Moderation</span>
        </h1>

        <div class="flex items-center justify-between mx-4 my-6">
            <div class="flex items-center space-x-3">
                <div class="w-72">
                    <ui-input name="search" placeholder="Search by name..."></ui-input>
                </div>
                <div class="w-72">
                    <ui-select placeholder="All workspaces..."></ui-select>
                </div>
            </div>
        </div>

        <ui-async :guards="! isLoading && ! isWorkspacesLoading && ! isUsersLoading">
            <div v-for="item, i in items" :key="i"
                 class="px-6 py-3 flex items-center space-x-3 even:bg-white/5">
                <event-alert v-if="item.type === 'event-alert'" :item="item"></event-alert>
            </div>

            <ui-infinite-loading @infinite="scroll" v-if="items.length" class="my-6"></ui-infinite-loading>
        </ui-async>
    </div>

    <Teleport to="#root">
        <event-alert-edit></event-alert-edit>
    </Teleport>
</template>

<script>
import useManagementModeratedEventsStore from '@/stores/management/moderated-events/moderated-events'
import useManagementWorkspacesStore from '@/stores/management/workspaces'
import useManagementUsersStore from '@/stores/management/users'

import EventAlert from '@/components/management/moderated-events/event-alert'
import EventAlertEdit from '@/components/management/moderated-events/modals/event-alert-edit.vue'

import {mapState} from 'pinia'

export default {
    components: {EventAlert, EventAlertEdit},

    computed: {
        ...mapState(useManagementModeratedEventsStore, ['items', 'isLoading', 'scroll']),
        ...mapState(useManagementWorkspacesStore, {isWorkspacesLoading: 'isLoading'}),
        ...mapState(useManagementUsersStore, {isUsersLoading: 'isLoading'})
    }
}
</script>
