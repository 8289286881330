<template>
    <div>
        <ui-header :title="'Help - ' + title"></ui-header>

        <div class="p-8 mx-auto mb-8 max-w-5xl bg-white shadow hover:shadow-lg">
            <help-content v-if="content"></help-content>
            <ui-spinner v-else></ui-spinner>
        </div>
    </div>
</template>

<script>
import useSupportHelpStore from '@/stores/support/help'

import Content from '@/components/support/help/content'

import { mapState } from 'pinia'

export default {
    components: {
        'help-content': Content
    },

    computed: {
        ...mapState(useSupportHelpStore, [ 'title', 'content' ])
    }
}
</script>
