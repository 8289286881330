<template>
    <div class="mx-auto w-full max-w-4xl bg-gray-700 border border-gray-600 rounded-lg">
        <div class="px-6 py-5">
            <h1 class="font-semibold"><ui-icon name="user" class="mr-1"></ui-icon> Account Settings</h1>
            <p class="mt-2 text-sm text-gray-300">Set-up your personal profile, password and application settings.</p>
        </div>

        <div v-if="loading" class="h-72 flex flex-col items-center justify-center text-gray-300">
            <ui-spinner class="mb-2"></ui-spinner>
            <p>Loading your settings...</p>
        </div>

        <template v-else>
            <div v-if="accountSettingsFormStore.submitted" class="border border-green-700 bg-green-900 text-green-100 px-4 py-2 text-sm mx-4 mb-2 rounded">
                Profile successfully updated.
            </div>

            <div v-if="accountSettingsFormStore.errors.current_password" class="border border-red-700 bg-red-900 text-red-100 px-4 py-2 text-sm mx-4 mb-2 rounded">
                The current confirmation password is not correct.
            </div>

            <div v-if="socialLoginError" class="border border-red-700 bg-red-900 text-red-100 px-4 py-2 text-sm mx-4 mb-2 rounded">
                {{socialLoginError}}
            </div>

            <ui-form :store="accountSettingsFormStore" v-slot="{ inputs, submitting, errors }">
                <div class="px-6 py-5 space-y-8 divide-y divide-gray-600">
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                        <div class="md:col-span-1">
                            <h3 class="text-lg font-medium leading-6 text-gray-200">Profile</h3>
                            <p class="mt-1 text-sm text-gray-300">
                                Change your public information like a name, email or set a profile photo.
                            </p>
                        </div>
                        <div class="flex mt-5 md:mt-0 md:col-span-2 space-x-8">
                            <div class="flex-1 space-y-8">
                                <div>
                                    <label for="name" class="block text-sm font-medium text-gray-200">
                                        Name
                                    </label>
                                    <div class="mt-1">
                                        <ui-input type="text" name="name" id="name" v-model="inputs.name" :errors="errors.name"></ui-input>
                                    </div>
                                </div>

                                <div>
                                    <label for="email" class="block text-sm font-medium text-gray-200">
                                        E-mail
                                    </label>
                                    <div class="mt-1">
                                        <ui-input type="email" name="email" id="email" v-model="inputs.email" :errors="errors.email" @change="requirePasswordConfirmation"></ui-input>
                                    </div>
                                </div>
                            </div>

                            <div class="w-32">
                                <label class="block text-sm font-medium text-gray-200 text-center">
                                    Photo
                                </label>
                                <div class="mt-3">
                                    <ui-avatar-upload name="profile_photo" :src="currentProfilePhotoUrl" v-model="inputs.profile_photo"></ui-avatar-upload>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="md:grid md:grid-cols-3 md:gap-6 pt-8">
                            <div class="md:col-span-1">
                                <h3 class="text-lg font-medium leading-6 text-gray-200">Password</h3>
                                <p class="mt-1 text-sm text-gray-300">
                                    Change your password, or not.
                                </p>
                            </div>

                            <div class="mt-5 md:mt-0 md:col-span-2 space-y-8">
                                <div>
                                    <label for="password" class="block text-sm font-medium text-gray-200">New Password</label>
                                    <div class="mt-1">
                                        <ui-input type="password" name="password" id="password" autocomplete="new-password" v-model="inputs.password" :errors="errors.password" @change="requirePasswordConfirmation"></ui-input>
                                    </div>
                                </div>

                                <div v-if="inputs.password">
                                    <label for="password_confirmation" class="block text-sm font-medium text-gray-200">Confirm New Password</label>
                                    <div class="mt-1">
                                        <ui-input type="password" name="password_confirmation" id="password_confirmation" v-model="inputs.password_confirmation" :errors="errors.password_confirmation"></ui-input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="md:grid md:grid-cols-3 md:gap-6 pt-8">
                            <div class="md:col-span-1">
                                <h3 class="text-lg font-medium leading-6 text-gray-200">Two-factor authentication</h3>
                                <p class="mt-1 text-sm text-gray-300">
                                    Additional security using an authenticator app on your phone.
                                </p>
                            </div>

                            <div class="mt-5 md:mt-0 md:col-span-2">
                                <ui-button v-if="! hasTwoFactorAuth" color="green" size="lg" @click="openTwoFactorAuthModal">Add two-factor authentication</ui-button>

                                <ui-button v-else color="red" size="lg" @click="openTwoFactorAuthModal">Remove two-factor authentication</ui-button>
                            </div>
                        </div>
                    </div>

                    <div class="md:grid md:grid-cols-3 md:gap-6 pt-8">
                        <div class="md:col-span-1">
                            <h3 class="text-lg font-medium leading-6 text-gray-200">Platform</h3>
                            <p class="mt-1 text-sm text-gray-300">
                                Change your platform settings.
                            </p>
                        </div>

                        <div class="mt-5 md:mt-0 md:col-span-2 space-y-8">
                            <div>
                                <label for="timezone" class="block text-sm font-medium text-gray-200">Timezone</label>
                                <ui-select class="mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" :options="timezones" v-model="inputs.timezone" :reduce="timezone => timezone.key"></ui-select>
                            </div>

                            <div>
                                <label for="preferred_content_language" class="block text-sm font-medium text-gray-200">Preferred content language</label>
                                <ui-select class="mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" :options="languages" v-model="inputs.preferred_content_language" :reduce="language => language.key"></ui-select>
                            </div>

                            <div>
                                <ui-button color="green" size="lg" :disabled="hasNativeNotificationsEnabled" @click="enableNativeNotifications">
                                    {{ hasNativeNotificationsEnabled ? 'Desktop notifications allowed' : 'Allow desktop notifications' }}
                                </ui-button>
                            </div>
                        </div>
                    </div>

                    <div class="md:grid md:grid-cols-3 md:gap-6 pt-8">
                        <div class="md:col-span-1">
                            <h3 class="text-lg font-medium leading-6 text-gray-200">Sessions</h3>
                            <p class="mt-1 text-sm text-gray-300">
                                These are your currently active Sentinel sessions.
                            </p>
                            <p class="mt-1 text-sm text-gray-300">
                                Old sessions expire on their own or can be closed manually here.
                            </p>
                        </div>

                        <ui-async :guards="sessions.data" class="mt-5 md:mt-0 md:col-span-2 space-y-4">
                            <div v-for="token in sessions.data" :key="token.id" class="flex items-center gap-x-3">
                                <div class="shrink-0">
                                    <ui-icon :name="token.mobile ? 'smartphone' : 'monitor'" class="text-xl"></ui-icon>
                                </div>
                                <div class="flex-1">
                                    <div>{{token.name}}</div>
                                    <div class="text-xs text-gray-300">
                                        <span v-tooltip="`Logged in at ${$dateTime(token.createdAt)}.`">
                                            <span v-if="token.current" class="font-semibold">Active session.</span>
                                            <span v-else-if="token.lastUsedAt">Last used at {{$dateTime(token.lastUsedAt)}}.</span>
                                            <span v-else>Not used yet.</span>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <a href="#" v-if="! token.current" @click.prevent="closeSession(token.id)">
                                        <ui-icon name="remove"></ui-icon>
                                    </a>
                                </div>
                            </div>
                        </ui-async>
                    </div>
                </div>

                <div class="flex mx-6 py-8 mt-3 border-t border-gray-600 flex justify-end">
                    <ui-button type="submit" size="lg" color="green" :action="save" :disabled="submitting">
                        Save changes
                    </ui-button>
                </div>

                <Teleport to="#root">
                    <password-confirmation-modal></password-confirmation-modal>
                </Teleport>
            </ui-form>
        </template>

        <Teleport to="#root">
            <two-factor-auth-modal></two-factor-auth-modal>
        </Teleport>
    </div>
</template>

<script>
import { mapStores, mapState, mapActions } from 'pinia'

import PasswordConfirmationModal from './modals/password-confirmation'
import TwoFactorAuthModal from '@/components/settings/modals/two-factor-auth'

import useAccountSettingsStore from '@/stores/settings/account'
import useAccountSettingsFormStore from '@/stores/settings/account-form'
import useAccountSettingsSocialLoginStore from '@/stores/settings/account-social-login'
import useTwoFactorAuthFormStore from '@/stores/settings/two-factor-auth-form'

export default {
    components: { PasswordConfirmationModal, TwoFactorAuthModal },

    methods: {
        ...mapActions(useAccountSettingsStore, [ 'closeSession', 'enableNativeNotifications' ]),
        ...mapActions(useAccountSettingsFormStore, [ 'requirePasswordConfirmation', 'save' ]),
        ...mapActions(useAccountSettingsSocialLoginStore, {
            connectSocial: 'connect',
            disconnectSocial: 'disconnect'
        }),
        ...mapActions(useTwoFactorAuthFormStore, {
            openTwoFactorAuthModal: 'open'
        })
    },

    computed: {
        ...mapStores(useAccountSettingsFormStore),
        ...mapState(useAccountSettingsStore, [
            'loading',
            'loadingSubscriptions',
            'currentProfilePhotoUrl',
            'hasTwoFactorAuth',
            'hasFacebookLogin',
            'hasNativeNotificationsEnabled',
            'timezones',
            'languages',
            'sessions'
        ]),
        ...mapState(useAccountSettingsSocialLoginStore, {
            socialLoginError: 'error'
        })
    }
}
</script>
