export default (items, field = 'name') => {
    items = [ ...items ].sort((a, b) => a[field].localeCompare(b[field]))

    let favorites = items.filter(item => item.favorite)
    let other = items.filter(item => ! item.favorite)

    let recent = other.filter(item => item.recent)
    other = other.filter(item => ! item.recent)

    return [ ...favorites, ...recent, ...other ]
}
