import api from '@/api'

import { defineStore } from 'pinia'

export const useMyWorkspacesStore = defineStore({
    id: 'my-workspaces',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),


    getters: {
        globalWorkspace: store => store.items.find(i => i.type == 'global'),

        personalWorkspace: store => store.items.find(i => i.type == 'personal'),

        regularWorkspaces: store => store.items.filter(i => i.type == 'default')
    },
    
    actions: {
        async initialize() {
            return this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me workspaces').get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        find(id) {
            return this.items.find(i => i.id == id)
        }
    }
})

export default useMyWorkspacesStore
