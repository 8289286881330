<template>
    <vue-slider v-model="model" :process-style="processStyle" :rail-style="railStyle">
        <template v-slot:dot="{ value, focus }">
            <div class="flex items-center justify-center h-full w-full">
                <div class="rounded-2xl h-1 w-full bg-gray-500"></div>
            </div>
        </template>
    </vue-slider>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
    props: { modelValue: { type: Number, default: 50 } },

    components: { VueSlider },

    computed: {
        model: {
            get() { return this.modelValue },
            set(val) { this.$emit('update:modelValue', val) }
        },

        processStyle() {
            return { backgroundColor: '#5E6E88' }
        },

        railStyle() {
            return { backgroundColor: '#5E6E88', width: '1px', margin: '0 auto 0 auto' }
        }
    }
}
</script>
