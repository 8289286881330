<template>
    <div class="relative flex-1" v-click-away="blur">
        <div>
            <div class="absolute top-0 left-3 h-9 flex items-center pointer-events-none text-xl z-40">
                <ui-icon name="search" :class="{ 'text-gray-500': ! isFocused, 'text-gerulata-green-300': isFocused }"></ui-icon>
            </div>

            <search-input
                v-model="store.search.query"
                :language="store.search.language"
                @focus="focus"
                @transaction="transaction"
                @submit="applySearchQuery"
                :allow-multi-line="true"
                :allow-highlighting="true"
            ></search-input>

            <div class="absolute top-1.5 right-3 flex items-center space-x-2 z-40" @click="blur" v-show="isFocused">
                <a href="#" class="flex items-center justify-center focus:outline-none transition w-6 h-6 text-base rounded-full bg-gray-600 hover:bg-gray-500 text-gray-300 hover:text-gray-200" @click.prevent="clearSearch" v-tooltip="'Clear Search Text'">
                    <ui-icon name="x"></ui-icon>
                </a>

                <search-settings :store="store"></search-settings>

                <search-help></search-help>
            </div>
        </div>
    </div>
</template>

<script>
import SearchHelp from './search/help'
import SearchInput from './search/input'
import SearchSettings from './search/settings'

import useStreamStore from '@/stores/overview/stream'
import useSupportHelpStore from '@/stores/support/help'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        SearchHelp,
        SearchInput,
        SearchSettings
    },

    data: () => ({
        isFocused: false
    }),

    computed: {
        ...mapState(useStreamStore, [ 'search' ])
    },

    methods: {
        ...mapActions(useStreamStore, [ 'applySearchQuery', 'clearSearch' ]),
        ...mapActions(useSupportHelpStore, { showHelp: 'show' }),

        async focus() {
            this.isFocused = true

            await this.$nextTick()
        },

        blur() {
            this.isFocused = false
        },

        transaction({ editor, transaction }) {
            if (transaction.docChanged) {
                this.$nextTick(() => {
                    //
                })
            }
        }
    }
}
</script>
