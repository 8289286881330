<template>
    <div class="h-full flex flex-col justify-between rounded-md py-3">
        <h2 class="text-2xl py-3 px-5">{{ item.data.event.title }}</h2>

        <div class="flex justify-between px-5">
            <div>
                <span class="text-gray-300">Location:</span> {{item.data.event.location.address}}
            </div>
            <div>
                <span class="text-gray-300">Time:</span> {{$dateTime(item.data.event.startedAt)}}
            </div>
        </div>

        <div class="mt-6 pt-4 px-5 border-t border-gray-500 space-y-2">
            <h3 class="text-gray-200 font-medium pb-2">Alert Details</h3>

            <div class="flex items-center">
                <div class="text-sm text-gray-300 w-28">Triggered by</div>
                <span>{{ item.data.perspective.name }}</span>
            </div>

            <div class="flex items-center">
                <div class="text-sm text-gray-300 w-28">Workspace</div>
                <span>{{ item.workspace.name }}</span>
            </div>
        </div>

        <div class="mt-6 pt-4 px-5 border-t border-gray-500 space-y-3"  v-if="item.status === 'pending'">
            <h3 class="text-gray-200 font-medium pb-2">Resolution</h3>

            <div class="rounded-md bg-gray-800">
                <label class="flex items-center px-4 py-2 space-x-3 cursor-pointer">
                    <ui-icon name="check" class="text-2xl text-gray-300"></ui-icon>
                    <div class="flex-1">
                        <div class="text-lg text-gray-200 font-medium">
                            Approve
                        </div>
                        <div class="text-gray-300">
                            Send alert to subscribed users.
                        </div>
                    </div>
                    <input type="radio" name="resolution" v-model="resolution" value="approved" class="h-4 w-4 text-gerulata-green-100 border-gray-600 bg-gray-200">
                </label>

                <div class="py-3 px-6 space-y-2" v-if="resolution == 'approved'">
                    <div class="flex items-center">
                        <ui-switch @click="toggleCritical" :value="critical" size="sm" class="mr-3"></ui-switch>
                        <a href="#" @click.prevent="toggleCritical" class="flex items-center text-sm text-gray-300">Mark as critical</a>
                    </div>

                    <div class="space-y-2">
                        <div class="flex items-center">
                            <ui-switch @click="toggleCustomWorkspaces" :value="customWorkspacesEnabled" size="sm" class="mr-3"></ui-switch>
                            <a href="#" @click.prevent="toggleCustomWorkspaces" class="flex items-center text-sm text-gray-300">Send to a different workspace</a>
                        </div>

                        <ui-select v-if="customWorkspacesEnabled" multiple id="customWorkspaces" :options="workspaces" label="name" v-model="customWorkspaces" ref="customWorkspaces"></ui-select>
                    </div>

                    <div class="space-y-2">
                        <div class="flex items-center">
                            <ui-switch @click="toggleCustomUsers" :value="customUsersEnabled" size="sm" class="mr-3"></ui-switch>
                            <a href="#" @click.prevent="toggleCustomUsers" class="flex items-center text-sm text-gray-300">Send to specific users</a>
                        </div>

                        <ui-select v-if="customUsersEnabled" multiple id="customUsers" :options="users" label="name" v-model="customUsers" ref="customUsers"></ui-select>
                    </div>
                </div>
            </div>

            <div class="rounded-md bg-gray-800">
                <label class="flex items-center px-4 py-2 space-x-3 cursor-pointer">
                    <ui-icon name="x" class="text-2xl text-gray-300"></ui-icon>
                    <div class="flex-1">
                        <div class="text-lg text-gray-200 font-medium">
                            Discard
                        </div>
                        <div class="text-gray-300">
                            Don't send this alert.
                        </div>
                    </div>
                    <input type="radio" name="resolution" v-model="resolution" value="ignored" class="h-4 w-4 text-gerulata-green-100 border-gray-600 bg-gray-200">
                </label>
            </div>
        </div>

        <div class="flex items-center justify-end space-x-3 py-6 px-5 mt-auto">
            <a href="#" @click.prevent="showList()" class="block px-6 py-3">
                Back
            </a>
        
            <ui-button color="green" @click="process(item)" v-if="item.status === 'pending'">
                Submit
            </ui-button>

            <ui-button color="green" disabled v-if="item.status === 'processing'">
                <ui-spinner type="clip" class="mr-1.5" color="rgb(29, 38, 50)"></ui-spinner>
                <span>Processing</span>
            </ui-button>
        </div>
    </div>
</template>

<script>
import useModeratedEventsModalsEventAlertEditFormStore from '@/stores/management/moderated-events/modals/event-alert-edit'
import useManagementModeratedEventsStore from '@/stores/management/moderated-events/moderated-events'
import useManagementModeratedEventsOverlayStore from '@/stores/management/moderated-events/overlay'
import useManagementWorkspacesStore from '@/stores/management/workspaces'
import useManagementUsersStore from '@/stores/management/users'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    computed: {
        ...mapState(useManagementModeratedEventsOverlayStore, { item: 'expandedModeratedEvent' }),
        ...mapState(useManagementWorkspacesStore, { workspaces: 'items' }),
        ...mapState(useManagementUsersStore, [ 'users' ]),
        ...mapState(useManagementModeratedEventsStore, [ 'critical', 'customWorkspacesEnabled', 'customUsersEnabled' ]),
        ...mapWritableState(useManagementModeratedEventsStore, [ 'customWorkspaces', 'customUsers', 'resolution' ])
    },

    methods: {
        ...mapActions(useManagementModeratedEventsOverlayStore, [ 'showList' ]),
        ...mapActions(useModeratedEventsModalsEventAlertEditFormStore, { openEditEventAlertModal: 'open'} ),
        ...mapActions(useManagementModeratedEventsStore, [ 'process', 'toggleCritical', 'toggleCustomWorkspaces', 'toggleCustomUsers' ])
    }
}
</script>
