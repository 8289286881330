<template>
    <a :href="$router.resolve(to)" class="block px-6 py-3 hover:bg-gray-600" :class="{ 'bg-gray-600': focused }" @click.prevent>
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <ui-icon name="search" class="text-xl"></ui-icon>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="flex items-center leading-5 text-gray-200">
                    <div class="truncate">Search <span class="font-medium">"{{query}}"</span> in all events</div>
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-400"></ui-icon>
            </div>
        </div>
    </a>
</template>

<script>
import useOverviewStore from '@/stores/overview/overview'

export default {
    props: [ 'focused', 'query' ],

    computed: {
        to() {
            return { name: 'overview', query: { 'filter[text]': `${this.query}|${this.$my.preferredLanguage}|` } }
        }
    },

    methods: {
        open() {
            useOverviewStore().newPerspective({ query: { 'filter[text]': `${this.query}|${this.$my.preferredLanguage}|` } })
        }
    }
}
</script>
