import { asyncResource } from '@/api'

import { defineStore } from 'pinia'

export const useEventCategoriesStore = defineStore({
    id: 'eventCategories',

    state: () => ({
        resource: asyncResource({ request: (api) => api.route('sentinel events categories') }),

        isInitialized: false,
        initializationPromise: null
    }),

    getters: {
        categories(store) {
            return store.resource.data.map(parent => ({
                ...parent,
                subcategories: parent.subcategories.map(category => ({
                    ...category, parent,
                    fullName: `${parent.name} • ${category.name}`,
                    color: category.color || parent.color,
                    icon: category.icon || parent.icon
                }))
            }))
        },

        availableCategories(store) {
            return store.categories.filter(c => c.available)
                .map(c => ({ ...c, subcategories: c.subcategories.filter(sc => sc.available) }))
        },

        flatCategories(store) {
            return store.categories.reduce((results, category) => [ ...results, category, ...category.subcategories ], []) || []
        }
    },

    actions: {
        async initialize() {
            if (this.isInitialized) return
            if (this.initializationPromise) return this.initializationPromise

            this.initializationPromise = this.resource.fetch(this)
            await this.initializationPromise

            this.isInitialized = true
        },

        find(id) {
            return this.flatCategories.find(c => c.id == id)
        }
    }
})

export default useEventCategoriesStore
