<template>
    <div class="p-3 flex items-center gap-x-4">
        <div class="flex-1">
            <template v-if="record.type == 'auth.activated'">
                User <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span> activated their account.
            </template>
            <template v-else-if="record.type == 'auth.logged-in'">
                User <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span> logged in.    
            </template>
            <template v-else-if="record.type == 'auth.logged-out'">
                User <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span> logged out.    
            </template>
            <template v-else-if="record.type == 'auth.password-reset'">
                User <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span> requested password reset.
            </template>
            <template v-else-if="record.type == 'management.users.created'">
                A new user <span class="font-semibold text-gray-200" v-tooltip="record.subject?.email">{{record.subject?.name || 'Unknown User'}}</span> was created in the organization by <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span>.  
            </template>
            <template v-else-if="record.type == 'management.users.deleted'">
                The user <span class="font-semibold text-gray-200" v-tooltip="record.subject?.email">{{record.subject?.name || 'Unknown User'}}</span> was removed from the organization by <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span>.  
            </template>
            <template v-else-if="record.type == 'management.users.updated'">
                The user <span class="font-semibold text-gray-200" v-tooltip="record.subject?.email">{{record.subject?.name || 'Unknown User'}}</span> was updated in the organization by <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span>.  
            </template>
            <template v-else-if="record.type == 'me.workspaces.created'">
                A new workspace <span class="font-semibold text-gray-200" v-tooltip="record.subject?.description">{{record.subject?.name || 'Unknown Workspace'}}</span> was created in the organization by <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span>.  
            </template>
            <template v-else-if="record.type == 'me.workspaces.deleted'">
                The workspace <span class="font-semibold text-gray-200" v-tooltip="record.subject?.description">{{record.subject?.name || 'Unknown Workspace'}}</span> was removed from the organization by <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span>.    
            </template>
            <template v-else-if="record.type == 'me.workspaces.updated'">
                The workspace <span class="font-semibold text-gray-200" v-tooltip="record.subject?.description">{{record.subject?.name || 'Unknown Workspace'}}</span> was updated in the organization by <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span>.
            </template>
            <template v-else-if="record.type == 'me.workspaces.member-added'">
                The user <span class="font-semibold text-gray-200" v-tooltip="record.meta.memberEmail">{{record.meta.memberName}}</span> has been added to the workspace <span class="font-semibold text-gray-200" v-tooltip="record.subject?.description">{{record.subject?.name || 'Unknown Workspace'}}</span> by <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span>.    
            </template>
            <template v-else-if="record.type == 'me.workspaces.member-removed'">
                The user <span class="font-semibold text-gray-200" v-tooltip="record.meta.memberEmail">{{record.meta.memberName}}</span> has been removed from the workspace <span class="font-semibold text-gray-200" v-tooltip="record.subject?.description">{{record.subject?.name || 'Unknown Workspace'}}</span> by <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span>.    
            </template>
            <template v-else-if="record.type == 'monitoring-requests.created'">
                A monitoring request has been created for <span class="font-semibold text-gray-200">{{record.subject?.url || 'Unknown URL'}}</span> by <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span>.    
            </template>
            <template v-else-if="record.type == 'sentinel.events.tag-added'">
                The tag <span class="font-semibold text-gray-200">{{record.meta.tagName}}</span> has been added to the event <span class="font-semibold text-gray-200">{{record.subject?.name || 'Unknown Event'}}</span> by <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span>.    
            </template>
            <template v-else-if="record.type == 'sentinel.events.tag-removed'">
                The tag <span class="font-semibold text-gray-200">{{record.meta.tagName}}</span> has been removed from the event <span class="font-semibold text-gray-200">{{record.subject?.name || 'Unknown Event'}}</span> by <span class="font-semibold text-gray-200" v-tooltip="record.causer?.email">{{record.causer?.name || 'Unknown User'}}</span>.
            </template>
        </div>
        <div class="flex items-center text-gray-200">
            {{$dateTime(record.createdAt)}}
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'record' ]
}
</script>
