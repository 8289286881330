<template>
    <div class="px-5 py-5 space-y-5">
        <trend-chart :series="series" v-for="series in trends" :key="series.category"></trend-chart>
    </div>
</template>

<script>
import TrendChart from '../partials/trend-chart'

import useDetailsStore from '@/stores/overview/details'

import { mapState } from 'pinia'

export default {
    components: {
        TrendChart
    },

    computed: {
        ...mapState(useDetailsStore, [ 'trends' ])
    }
}
</script>
