<template>
    <div class="px-5 space-y-5">
        <ui-async :guards="metrics.data">
            <div class="mx-auto max-w-2xl lg:max-w-none" v-if="checkData()">
                <dl class="grid max-w-xl gap-8 mt-10 grid-cols-3">
                    <div v-for="(metric, index) in Object.keys(metrics.data)" :key="index" class="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6">
                        <dt class="text-sm leading-6 text-gray-100 capitalize">{{ metric }}</dt>
                        <dd class="order-first text-3xl font-semibold tracking-tight text-gray-100">{{ metrics.data[metric] }}</dd>
                    </div>
                </dl>
            </div>

            <div class="text-2xl text-center mt-16" v-else>
                Currently, we do not track metrics for this event.
            </div>
        </ui-async>
    </div>
</template>

<script>

import useDetailsStore from "@/stores/overview/details";
import { mapState } from 'pinia'


export default {
    computed: {
        ...mapState(useDetailsStore, [ 'metrics' ])
    },

    methods: {
        checkData() {
            if (this.metrics.data) {
                let sum = 0
                Object.keys(this.metrics.data).forEach(key => { sum += this.metrics.data[key] })
                return sum > 0
            }

            return false
        }
    }
}
</script>
