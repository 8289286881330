import useMyStore from '@/stores/me/my'
import useSettingsUsersStore from '@/stores/management/users'

import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'

export const useDeleteUserModalStore = defineForm({
    id: 'settingsUsersModalsDeleteUser',

    inputs: () => ({
        user: null
    }),

    submitRequest() {
        return api.route('admin accounts organizations members delete', [ useMyStore().user.organization.id, this.inputs.user.id ])
            .delete()
    },

    async onResponse(res) {
        useSettingsUsersStore().reloadOrganization()
        await useSettingsUsersStore().reloadUsers()
        
        useModal().hide('settings-delete-user')
    },

    actions: {
        open(user) {
            this.reset()
            
            this.inputs.user = user

            useModal().show('settings-delete-user')
        },

        cancel() {
            useModal().hide('settings-delete-user')
        }
    }
})

export default useDeleteUserModalStore
