// Helper for building filtering rule-sets used in geo-layers
// Takes an input in the format - true, false or { include: [ ... ], exclude: [ ... ] }
// and a change in the format - { include: val } or { exclude: val }
// and returns an updated ruleset.
export default function filterRuleset(ruleset, change = {}) {
    if (change.include && ruleset !== true) {
        if (ruleset === false) {
            ruleset = { include: [ change.include ], exclude: [] }
        } else if (ruleset.exclude.includes(change.include)) {
            ruleset.exclude = ruleset.exclude.filter(id => id != change.include)
        } else {
            ruleset.include = Array.from(new Set([ ...ruleset.include, change.include ]))
        }

        if (! ruleset.include.length && ! ruleset.exclude.length) {
            ruleset = true
        }
    }

    if (change.exclude && ruleset !== false) {
        if (ruleset === true) {
            ruleset = { include: [], exclude: [ change.exclude ] }
        } else if (ruleset.include.includes(change.exclude)) {
            ruleset.include = ruleset.include.filter(id => id != change.exclude)
        } else {
            ruleset.exclude = Array.from(new Set([ ...ruleset.exclude, change.exclude ]))
        }

        if (! ruleset.include.length && ! ruleset.exclude.length) {
            ruleset = false
        }
    }
    
    return ruleset
}

export function filterRulesetIncludes(ruleset, item) {
    return ruleset === true
        || (ruleset?.include?.length && ruleset?.include?.includes(item))
        || (ruleset?.exclude?.length && ! ruleset?.exclude?.includes(item))
}
