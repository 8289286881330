<template>
    <ui-dropdown width="w-80">
        <template v-slot:trigger>
            <div class="inline-flex items-center px-3 pr-2 h-9 text-sm bg-gray-800 border border-gray-500 text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-0 focus:placeholder-gray-400 focus:ring-gerulata-green-100 cursor-pointer rounded-md shadow-sm w-full">
                <span class="flex-1">
                    {{internalValue ? `${$date(internalValue[0])} - ${$date(internalValue[1])}` : placeholder}}
                </span>
                <ui-icon name="x" class="ml-2" @click.stop="clear"></ui-icon>
                <ui-icon name="calendar" class="ml-2"></ui-icon>
            </div>
        </template>
        <template v-slot:content>
            <date-picker v-model="internalValue" range @click.stop></date-picker>
        </template>
    </ui-dropdown> 
</template>

<script>
import DatePicker from './date-picker'

export default {
    components: { DatePicker },

    props: [ 'modelValue', 'placeholder' ],

    computed: {
        internalValue: {
            get() { return this.modelValue },
            set(val) { this.$emit('update:modelValue', val); this.$emit('input', val) }
        }
    },
    
    methods: {
        clear() { this.internalValue = null }
    }
}
</script>