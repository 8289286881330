<template>
    <div>
        <h2 class="font-medium mb-1">{{category.fullName || category.name}}</h2>
        <highcharts-chart :options="chartOptions" class="h-24"></highcharts-chart>
    </div>
</template>

<script>
import useEventCategoriesStore from '@/stores/me/event-categories'

import { utcTimestamp } from '@/helpers/datetime'

import { group } from 'd3-array'
import { utcMondays } from 'd3-time'
import { startOfWeek, add, sub, parseISO } from 'date-fns'
import { Chart as HighchartsChart } from 'highcharts-vue'

export default {
    components: {
        HighchartsChart
    },

    props: [ 'series' ],
    
    computed: {
        category() { return useEventCategoriesStore().find(this.series.category) },

        chartOptions() {
            return {
                chart: {
                    backgroundColor: false,
                    spacing: [0, 0, 0, 0]
                },
                boost: {
                    useGPUTranslations: true
                },
                title: {
                    text: '',
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        y: -5,
                        style: { color: '#93A3B3', fontSize: '11px' },
                        zIndex: 1
                    },
                    tickLength: 0,
                    min: utcTimestamp(sub(startOfWeek(new Date, {weekStartsOn: 1}), { months: 6 })),
                    max: utcTimestamp(startOfWeek(new Date))
                },
                yAxis: [
                    {
                        title: { text: '' },
                        labels: { enabled: false, },
                        gridLineColor: 'transparent',
                        min: 0
                    }
                ],
                tooltip: { enabled: false },
                legend: { enabled: false },
                series: [
                    {
                        type: 'areaspline',
                        name: 'Count',
                        color: this.category.color,
                        lineWidth: 2,
                        fillOpacity: 0.5,
                        data: this.chartValues,
                        yAxis: 0,
                        marker: { enabled: false }
                    }
                ],
                credits: { enabled: false }
            }
        },
        
        chartValues() {
            let data = this.series.values.map(point => ({
                x: parseISO(point.date),
                y: Math.round(parseFloat(point.count) * 10000) / 10000
            }))

            let m = group(data, ({x, y}) => x)
            let timeRange = utcMondays(
                sub(startOfWeek(new Date, {weekStartsOn: 1}), { months: 6 }),
                add(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 1 }),
                1
            )
            
            return timeRange.map(date => m.get(date)?.[0] || { x: date, y: 0 })
        }
    }
}
</script>