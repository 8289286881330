import useMyGeoFeaturesStore from '@/stores/me/geo-features'

import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'

export const useGeoLayerEditModalStore = defineForm({
    id: 'overviewModalsGeoLayerEdit',

    inputs: () => ({
        name: null,
        layer: {}
    }),

    async submitRequest() {
        let route = this.inputs.layer.id
            ? [ 'sentinel geo-features layers update', { id: this.inputs.layer.id } ]
            : [ 'sentinel geo-features layers store' ]

        return api.route(...route)
            .formData({
                _method: this.inputs.layer.id ? 'put' : 'post',
                name: this.inputs.name
            })
            .post()
    },

    async onResponse(res) {
        useMyGeoFeaturesStore().reloadLayers()
        
        useModal().hide('overview-geo-layer-edit')

        this.fulfillPromise(res.data)
    },

    actions: {
        open(layer = {}) {
            this.reset()

            this.inputs.name = layer.name
            this.inputs.layer = layer

            useModal().show('overview-geo-layer-edit')

            return this.makePromise()
        },

        cancel() {
            useModal().hide('overview-geo-layer-edit')

            this.fulfillPromise()
        },

        async delete() {
            try {
                await api.route('sentinel geo-features layers delete', { id: this.inputs.layer.id }).delete().res()
            } catch (e) {
                return
            }
            
            useMyGeoFeaturesStore().reloadLayers()

            useModal().hide('overview-geo-layer-edit')

            this.fulfillPromise()
        }
    }
})

export default useGeoLayerEditModalStore
