<template>
    <div class="mx-auto w-full max-w-4xl bg-gray-700 border border-gray-600 rounded-lg">
        <div class="px-6 py-5">
            <h1 class="font-semibold"><ui-icon name="web-connect" class="mr-1"></ui-icon> Gerulata Monitor</h1>
            <p class="mt-2 text-sm text-gray-300">Gerulata Monitor is a near-real time, massive scale web & social media monitoring system.</p>
        </div>

        <div class="bg-white/5 border-t border-b border-gray-700">
            <ui-async :guards="! isLoadingSourceStats" class="grid grid-cols-4 p-6">
                <div>
                    <div class="text-sm font-medium text-gray-300">
                        Status
                    </div>
                    <div class="mt-2 flex items-baseline gap-x-2">
                        <span class="text-4xl rounded-md bg-gerulata-green-100 w-2 h-100 inline-block">&nbsp;</span>
                        <span class="text-4xl font-semibold tracking-tight">Healthy</span>
                    </div>
                </div>
                <div>
                    <div class="text-sm font-medium text-gray-300">
                        Base Targets
                    </div>
                    <div class="mt-2 flex items-baseline gap-x-2">
                        <span class="text-4xl font-semibold tracking-tight">7,919,314</span>
                    </div>
                </div>
                <div>
                    <div class="text-sm font-medium text-gray-300">
                        Custom Targets
                    </div>
                    <div class="mt-2 flex items-baseline gap-x-2">
                        <span class="text-4xl font-semibold tracking-tight">{{ sourceStats.current.monitoredTargets }}</span>
                        <span>/ {{ sourceStats.limits.monitoredTargets }}</span>
                    </div>
                </div>
            </ui-async>
        </div>

        <div class="mt-8">
            <div class="flex items-center px-6">
                <div class="flex-1">
                    <h2 class="font-semibold">Monitoring Requests</h2>
                    <p class="mt-2 text-sm text-gray-300">List of custom monitoring requests in your organization.</p>
                </div>
                <div class="flex items-center space-x-5">
                    <a href="#" class="text-gerulata-green-100 hover:text-gerulata-green-300" v-if="monitoringRequests.length > 4" @click.prevent="showAllMonitoringRequests">Show more</a>
                    <ui-button icon="plus" color="green" @click="showAddSource">
                        New Request
                    </ui-button>
                </div>
            </div>

            <ui-async :guards="! isMonitoringRequestsLoading" class="divide-y divide-gray-600 px-6 mt-4">
                <monitoring-requests :requests="monitoringRequests.slice(0, 5)"></monitoring-requests>
            </ui-async>
        </div>

        <div class="mt-6">
            <div class="px-6">
                <h2 class="font-semibold">Custom Targets</h2>
                <p class="mt-2 text-sm text-gray-300">List of custom targets monitored by your organization.</p>
            </div>

            <ui-async :guards="! isMonitoredSourcesLoading" class="divide-y divide-gray-600 px-6 mt-4">
                <div v-for="item, i in monitoredSources" :key="i" class="p-3 flex items-center space-x-3">
                    <div class="flex flex-grow items-center justify-between">
                        <div class="flex items-center">
                            <div class="mr-4 shrink-0 relative">
                                <img class="rounded-full w-8 h-8 shadow" :src="item.avatarUrl" alt="">
                                <ui-icon :name="`badges.target-${item.type}`" v-tooltip="targetTypeToHuman(item.type)" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-gray-700 rounded-full" :class="{ 'grayscale': ! item.monitored }"></ui-icon>
                            </div>
                            <div>
                                <div class="flex items-center">
                                    <p class="font-medium">{{ item.title }}</p>
                                    <div v-if="item.verified" class="h-4 w-4 rounded-full bg-blue-600 text-white flex items-center justify-center text-xs inline-flex ml-2 -mb-1">
                                        <ui-icon name="check" class="text-2xs"></ui-icon>
                                    </div>
                                </div>
                                <p class="text-gray-300">{{ targetTypeToHuman(item.type) }}</p>
                            </div>
                        </div>

                        <div class="flex items-center space-x-8">
                            <div>
                                <div class="text-xs leading-tight text-gray-300">
                                    {{ item.family === 'sources' ? 'Followers' : 'Members' }}
                                </div>
                                <div class="font-medium">
                                    <template v-if="item.family === 'sources'">
                                        {{item.metrics.followers !== null && item.metrics.followers !== undefined ? $number(item.metrics.followers) : '-'}}
                                    </template>
                                    <template v-else>
                                        {{item.metrics.members !== null && item.metrics.members !== undefined ? $number(item.metrics.members) : '-'}}
                                    </template>
                                </div>
                            </div>

                            <div>
                                <div class="text-xs leading-tight text-gray-300">
                                    Activity ⌀
                                </div>
                                <div class="font-medium">
                                    {{item.metrics.publishedPerWeek !== null && item.metrics.publishedPerWeek !== undefined ? $number(Math.round(item.metrics.publishedPerWeek)) : '-'}}
                                </div>
                            </div>

                            <div>
                                <div class="text-xs leading-tight text-gray-300">
                                    Inters. ⌀
                                </div>
                                <div class="font-medium">
                                    {{item.metrics.interactionsPerWeek !== null && item.metrics.interactionsPerWeek !== undefined ? $number(Math.round(item.metrics.interactionsPerWeek)) : '-'}}
                                </div>
                            </div>

                            <div class="flex items-center justify-end flex-1 space-x-2 text-gray-500">
                                <contextual-menu :source="item" plain></contextual-menu>
                            </div>
                        </div>
                    </div>
                </div>
            </ui-async>

            <div class="flex items-center justify-center h-16 rounded-lg" v-if="! isMonitoredSourcesLoading && ! monitoredSources.length">
                <span class="text-gray-600">No matching sources.</span>
            </div>

            <div class="py-4" v-if="monitoredSources.length">
                <ui-infinite-loading @infinite="loadMoreMonitoredSources" :identifier="lastMonitoredSourcesReloadTime"></ui-infinite-loading>
            </div>
        </div>
    </div>

    <Teleport to="#root">
        <data-providers-monitoring-requests-modal></data-providers-monitoring-requests-modal>
        <data-providers-add-source-modal></data-providers-add-source-modal>
    </Teleport>
</template>

<script>
import DataProvidersMonitoringRequestsModal from '@/components/settings/data-providers/modals/monitoring-requests'
import DataProvidersAddSourceModal from '@/components/settings/data-providers/modals/add-source'
import ContextualMenu from '@/components/settings/data-providers/contextual-menus/source'
import MonitoringRequests from '@/components/settings/data-providers/partials/monitoring-requests'

import useMyMonitoringRequestsStore from '@/stores/me/monitoring-requests'
import useMyMonitoredSourcesStore from '@/stores/me/monitored-sources'
import useSourcesSourceTypesStore from '@/stores/sources/source-types'
import useDataProvidersModalsMonitoringRequests from '@/stores/management/data-providers/modals/monitoring-requests'
import useDataProvidersModalsAddSource from '@/stores/management/data-providers/modals/add-source'

import { mapActions, mapState } from 'pinia'
import useSourceStatsStore from '@/stores/sources/source-stats'

export default {
    data: () => ({
        platforms: [
            { name: 'Facebook Page', id: 'facebook', icon: 'badges.target-facebook-page' },
            { name: 'Facebook Group', id: 'facebook-group', icon: 'badges.target-facebook-group' },
            { name: 'Instagram Profile', id: 'instagram-profile', icon: 'badges.target-instagram-profile' },
            { name: 'Telegram Channel or Group', id: 'telegram', icon: 'badges.target-telegram-channel' },
            { name: 'Twitter User', id: 'twitter', icon: 'badges.target-twitter-user' },
            { name: 'VKontakte User, Page or Community', id: 'vkontakte', icon: 'badges.target-vkontakte-community' },
            { name: 'Web Feed', id: 'web-feeds', icon: 'badges.target-web-feed' },
            { name: 'Youtube Channel', id: 'youtube', icon: 'badges.target-youtube-channel' }
        ]
    }),

    components: { DataProvidersMonitoringRequestsModal, DataProvidersAddSourceModal, ContextualMenu, MonitoringRequests },

    computed: {
        ...mapState(useSourceStatsStore, { isLoadingSourceStats: 'isLoading', sourceStats: 'items' }),
        ...mapState(useMyMonitoringRequestsStore, { isMonitoringRequestsLoading: 'isLoading', activeMonitoringRequest: 'activeItem', monitoringRequests: 'items' }),
        ...mapState(useMyMonitoredSourcesStore, { isMonitoredSourcesLoading: 'isLoading', monitoredSources: 'items', lastMonitoredSourcesReloadTime: 'lastReloadTime' })
    },

    methods: {
        getPlatformInfo(platform) {
            return this.platforms.find(p => p.id === platform) || {}
        },

        ...mapActions(useSourcesSourceTypesStore, { 'targetTypeToHuman': 'typeToHuman' }),
        ...mapActions(useMyMonitoredSourcesStore, { loadMoreMonitoredSources: 'loadMore' }),
        ...mapActions(useDataProvidersModalsAddSource, { showAddSource: 'open' }),
        ...mapActions(useMyMonitoringRequestsStore, { toggleActiveMonitoringRequest: 'toggleActiveItem' }),
        ...mapActions(useDataProvidersModalsMonitoringRequests, { showAllMonitoringRequests: 'open' })
    }
}
</script>