<template>
    <div class="mx-auto w-full max-w-4xl bg-gray-700 border border-gray-600 rounded-lg pb-6">
        <div class="flex items-center gap-4 px-6 py-5">
            <div class="flex-1">
                <h1 class="font-semibold"><ui-icon name="shield" class="mr-1"></ui-icon> Source Credibility</h1>
                <p class="mt-2 text-sm text-gray-300">Gerulata Sentinel has internal credibility system. Event veracity is based on the credibility of sources as well as on other factors. You can override the credibility of sources manually for your organization.</p>
            </div>
            <ui-button color="green" icon="plus" @click="newOverride">
                New Override
            </ui-button>
        </div>
        
        <ui-async :guards="! overrides.pending || !! overrides.data?.length">
            <div class="divide-y divide-gray-600 px-6 mt-4">
                <div v-for="item, i in overrides.data" :key="i" class="p-3 flex items-center space-x-3">
                    <div class="flex flex-grow items-center gap-x-4">
                        <div class="flex-1 flex items-center">
                            <div class="mr-4 shrink-0 relative">
                                <ui-avatar :item="item" class="rounded-full w-8 h-8 shadow"></ui-avatar>
                                <ui-icon :name="`badges.target-${item.type}`" v-tooltip="targetTypeToHuman(item.type)" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-gray-700 rounded-full" :class="{ 'grayscale': ! item.monitored }"></ui-icon>
                            </div>
                            <div>
                                <div class="flex items-center">
                                    <p class="font-medium">{{ item.title }}</p>
                                    <div v-if="item.verified" class="h-4 w-4 rounded-full bg-blue-600 text-white flex items-center justify-center text-xs inline-flex ml-2 -mb-1">
                                        <ui-icon name="check" class="text-2xs"></ui-icon>
                                    </div>
                                </div>
                                <p class="text-gray-300">{{ targetTypeToHuman(item.type) }}</p>
                            </div>
                        </div>
    
                        <div class="flex items-center">
                            <div v-if="item.credibility >= 80" class="rounded border text-sm font-medium border-gray-500 px-1.5 py-0.5 flex gap-x-2 items-center">
                                <div class="text-green-400 bg-green-400/10 flex-none rounded-full p-1">
                                    <div class="h-1.5 w-1.5 rounded-full bg-current"></div>
                                </div>
                                High Credibility ({{item.credibility}})
                            </div>
                            <div v-else-if="item.credibility >= 20" class="rounded border text-sm font-medium border-gray-500 px-1.5 py-0.5 flex gap-x-2 items-center">
                                <div class="text-blue-400 bg-blue-400/10 flex-none rounded-full p-1">
                                    <div class="h-1.5 w-1.5 rounded-full bg-current"></div>
                                </div>
                                Medium Credibility ({{item.credibility}})
                            </div>
                            <div v-else class="rounded border text-sm font-medium border-gray-500 px-1.5 py-0.5 flex gap-x-2 items-center">
                                <div class="text-red-400 bg-red-400/10 flex-none rounded-full p-1">
                                    <div class="h-1.5 w-1.5 rounded-full bg-current"></div>
                                </div>
                                Low Credibility ({{item.credibility}})
                            </div>                        
                        </div>
                        
                        <div>
                            <a @click.prevent="deleteOverride(item.id)" href="#" class="text-gray-300 hover:text-red-300">
                                <ui-icon name="trash"></ui-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
            <ui-infinite-loading @infinite="loadMore($event)" class="pt-6" v-if="overrides.data?.length"></ui-infinite-loading>
            
            <div class="flex flex-col py-32 items-center justify-center text-gray-300" v-if="! overrides.pending && ! overrides.data?.length">
                <ui-icon name="shield" class="text-3xl"></ui-icon>
                <p class="mt-2">You will find your overrides here.</p>
            </div>
        </ui-async>
    </div>

    <Teleport to="#root">
        <new-override-modal></new-override-modal>
    </Teleport>
</template>

<script>
import NewOverrideModal from '@/components/settings/credibility/modals/new-override-modal'

import useSourceCredibilityStore from '@/stores/settings/credibility/source-credibility'
import useSourcesSourceTypesStore from '@/stores/sources/source-types'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        NewOverrideModal
    },

    computed: {
        ...mapState(useSourceCredibilityStore, [ 'overrides' ])
    },
    
    methods: {
        ...mapActions(useSourceCredibilityStore, [ 'deleteOverride', 'loadMore', 'newOverride' ]),
        ...mapActions(useSourcesSourceTypesStore, { 'targetTypeToHuman': 'typeToHuman' })
    }
}
</script>