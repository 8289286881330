import { format as d3Format } from 'd3-format'
import sanitizeHtml from 'sanitize-html'
import ziggyRoute from 'ziggy-js'
import { Ziggy } from '@/ziggy/sentinel'
import { $vfm } from 'vue-final-modal'

export function arrayWrap(input) {
    if (! input) return []

    return input instanceof Array ? input : [ input ]
}

export function isMac() {
    return navigator.platform.indexOf('Mac') > -1
}

export function mapLocalState(keysOrMapper) {
    return Array.isArray(keysOrMapper)
        ? keysOrMapper.reduce((reduced, key) => {
            reduced[key] = function () { return this.store[key] }
            return reduced
        }, {})
        : Object.keys(keysOrMapper).reduce((reduced, key) => {
            reduced[key] = function () {
                const store = this.store
                const storeKey = keysOrMapper[key]

                return typeof storeKey === 'function'
                    ? (storeKey).call(this, store)
                    : store[storeKey]
            }
            return reduced
        }, {})
}

export function number(value, format) {
    return d3Format(format || (value >= 9999 ? '.4~s' : '.5~r'))(value)
}

export function route(name, params, absolute = false, config = Ziggy) {
    return ziggyRoute(name, params, absolute, config)
}

export function textSearch(needle, haystack, retrieveValue) {
    if (! needle) return haystack

    needle = needle.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
    retrieveValue = retrieveValue || (v => v)

    return haystack.filter(h => {
        let val = retrieveValue(h)
        return (typeof val == 'string') && val.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(needle)
    })
}

export function useModal() {
    return $vfm
}

export function value(input, context) {
    return input instanceof Function ? input.apply(context) : input
}

export function sanitizeHTML(html) {
    return sanitizeHtml(html, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'ul', 'ol', 'li', 'a' ]),
        allowedAttributes: {
            'h1': ['style'],
            'h2': ['style'],
            'h3': ['style'],
            'p': ['style'],
            'span': ['style'],
            'ul': ['style'],
            'ol': ['style'],
            'a': ['href', 'target']
        },
        allowedStyles: {
            '*': {
                'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
                'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/]
            }
        },
        transformTags: {
            'a': sanitizeHtml.simpleTransform('a', { target: '_blank' })
        }
    })
}

export function removeHTML(html) {
    return sanitizeHtml(html, {
        allowedTags: []
    })
}

export { default as arrayUnique } from '@/helpers/array-unique'
export { default as breakpoint } from '@/helpers/breakpoint'
export { date as date } from '@/helpers/datetime'
export { dateTime as dateTime } from '@/helpers/datetime'
export { downloadBlob, downloadUrl } from '@/helpers/download'
export { default as colorHash } from '@/helpers/color-hash'
export { default as filterRuleset, filterRulesetIncludes } from '@/helpers/filter-ruleset'
export { default as languageName } from '@/helpers/language-name'
export { hasFeatureTo as hasFeatureTo } from '@/helpers/licenses'
export { default as scroll } from '@/helpers/scroll'
export { searchFilters as searchFilters } from '@/helpers/search-filters'
export { default as strLimit } from '@/helpers/str-limit'
export { default as sortAlphabetically } from '@/helpers/sort-alphabetically'
export { default as sortByDate } from '@/helpers/sort-by-date'
export { default as sortNaturally } from '@/helpers/sort-naturally'
export { isLanguageTranslatable, default as translate } from '@/helpers/translations'
export { useRoute as useRoute } from '@/router'
export { default as useRouter } from '@/router'

export default function registerHelpers(app) {
    app.mixin({
        computed: {
            isMac() { return isMac() }
        },
        methods: {
            $number: number
        }
    })
}
