<template>
    <ui-modal name="settings-user-permissions" title="User Permissions" width="100%" height="auto" :max-width="480" @closed="cancel">
        <ui-form :store="settingsUsersModalsPermissionsStore" v-slot="{ inputs, submitting, errors }">
            <div class="space-y-2">
                <label v-for="permission in permissions" :key="permission.id" class="rounded-md bg-gray-800 flex items-center px-4 py-2 space-x-3" @click.prevent="togglePermission(permission.id)">
                    <input type="checkbox" class="h-4 w-4 text-gerulata-green-700 border-gray-600 bg-gray-800 pointer-events-none" :checked="inputs.permissions.includes(permission.id)">
                    <div class="flex-1">
                        <div class="text-lg text-gray-200 font-medium">
                            {{permission.name}}
                        </div>
                        <div class="text-gray-300">
                            {{permission.description}}
                        </div>
                    </div>
                </label>        
            </div>
            
            <div class="text-center mt-6">
                <ui-button size="lg" color="green" type="submit" :disabled="submitting">Save</ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useSettingsUsersStore from '@/stores/settings/users/users'
import useUserPermissionsModalStore from '@/stores/settings/users/modals/user-permissions'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapState(useSettingsUsersStore, [ 'permissions' ]),
        ...mapStores(useUserPermissionsModalStore)
    },

    methods: {
        ...mapActions(useUserPermissionsModalStore, [ 'cancel', 'submit', 'togglePermission' ])
    }
}
</script>
